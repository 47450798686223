import { useEffect, useState } from "react";

const TripRequested = () => {

    const [tripDate, setTripDate] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setTripDate(formattedDate);

    }, []);
    
    
    const handleTripChange = (event) => {
        setTripDate(event.target.value);
    };

    return(
        <div className="trip-content p-2.5">
            <div className="custom_trips_table_outer block">
                <div className="flex flex-col sm:flex-row items-center justify-around gap-[20px]">
                    <button className="order_today">Today Trips</button>
                    <button className="order_today">Yesterday Trips</button>
                    <button className="order_today">This Week Trips</button>
                    <button className="order_today">Last Week Trips</button>
                </div>
                <div className="hidden sm:flex items-center justify-center gap-[20px] mt-30px">
                    <div>
                        <input type="date" name="fs_trip_date" id="fs_trip_date" className="trip-date" value={tripDate} onChange={handleTripChange}/>
                    </div>
                    <button className="order_today green">Select Date</button>
                    <div className="flex items-center gap-2.5">
                        <select name="cc_expiration_month" className="trip-select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} required>
                            <option value="">Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select name="cc_expiration_month" className="trip-select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} required>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                        </select>
                    </div>
                    <button className="order_today green">Select Month</button>
                </div>
            </div>
        </div>
    )
}

export default TripRequested