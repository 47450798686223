import { REGISTER_TYPE_FAIL, REGISTER_TYPE_REQUEST, REGISTER_TYPE_SUCCESS } from "../types/registerTypes";

export const registerTypeReducer = (state = { types: {} }, { type, payload }) => {
    switch (type) {
        case REGISTER_TYPE_REQUEST:
            return {
                loading: true,
            };
        case REGISTER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                types: payload,
            };
        case REGISTER_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                types: null,
                error: payload,
            };
        default:
            return state;
    }
};