import { useSelector } from "react-redux";
import './Chat.css';
import sendMsgIcon from '../../assests/images/business/send-msg-icon.png';
import { useEffect, useState } from "react";

const ChatSection = () => {

    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);
    const {chatAdmin} = useSelector((state) => state.chatAdmin)

    const [userName, setUserName] = useState('');
    const [adminName, setAdminName] = useState('');
    const [adminImage, setAdminImage] = useState('');

    useEffect(() => {
        if(businessUserDetail && businessUserDetail.status === 1 ){
            setUserName(businessUserDetail.businessdata.restaurant_name.substring(0, 23))
        }

        if(chatAdmin && chatAdmin.length >= 0){
            setAdminName(chatAdmin[0].name);
            setAdminImage(chatAdmin[0].image);
        }

    }, [businessUserDetail, chatAdmin])

    return(
        <div className="chat-section">
            <h4 className="text-[28px] text-center font-bold font-asap mb-20px">{userName}</h4>
            <div className="chat-block">
                <div className="welcome-msg-btn">Support Chat</div>
                <div className="chat-body">
                    <div className="message-parent">
                        <div className="message">
                            <div className="flex gap-2.5 items-center">
                                <div className="w-1/4">
                                    { adminImage ? 
                                        <img src={`${process.env.REACT_APP_API_URL}/images/${adminImage}`} alt="Profile-img" className="rounded-full" />
                                        : 
                                        null
                                    }
                                    
                                </div>
                                <div className="w-3/4 content">
                                    <p>Hello {userName}, please let us know if we can assist you in any way. <br/>
                                    {adminName}
									</p>
                                </div>
                            </div>
                            <div className="inner_msg_box">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-typing">
                    <div className="typing-group relative">
                        <input type="text" className="chat_msg_send" placeholder="Type message" name="chat_msg" id="chat_msg" autoComplete="off" />
                        <img src={sendMsgIcon} className="send-msg-icon" alt="Send Msg" />
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ChatSection