import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const HowItWorks = () => {
    return(
        <section className="how-it-works-home bg-primary-opacityWhite" id="howitworks">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-70px flex flex-col gap-[30px]">
                <div className="w-full p-2.5">
                    <h2 className="text-4xl font-semibold text-center">How it works?</h2>
                </div>
                <div className="w-full p-2.5 flex flex-col md:flex-row gap-[20px]">

                    <div className="w-full p-2.5 flex flex-col items-center gap-2.5 lg:gap-[20px]">
                        <h2 className="number-block bg-primary-newBlue text-white p-20px rounded-full w-90 h-90 flex items-center mx-auto justify-center text-50 font-roboto font-semibold">1</h2>
                        <h4 className="uppercase text-primary-newBlue font-semibold mb-0">Registration</h4>
                        <p className="mb-2.5 text-center">Create an account, log in and add your payment method.</p>
                        <Link reloadDocument={true} to="/register" className="uppercase text-white text-base bg-primary-red mx-auto px-30px py-15px no-underline rounded-50 leading-none hover:bg-black">Get Started</Link>
                        <div className="divider md:hidden flex flex-row items-center w-full">
                            <span className="w-full h-0.5 bg-primary-newBlue"></span>
                            <span><FontAwesomeIcon icon={faArrowAltCircleDown} className="text-xl text-primary-red mx-2.5"/></span>
                            <span className="w-full h-0.5 bg-primary-newBlue"></span>
                        </div>
                    </div>

                    <div className="w-full p-2.5 flex flex-col items-center gap-2.5 lg:gap-[20px]">
                        <h2 className="number-block bg-primary-newBlue text-white p-20px rounded-full w-90 h-90 flex items-center mx-auto justify-center text-50 font-roboto font-semibold">2</h2>
                        <h4 className="uppercase text-primary-newBlue font-semibold mb-0">Training</h4>
                        <p className="mb-2.5 text-center">We will provide you with training so you understand how our easy to use delivery solution works.</p>
                        <div className="divider md:hidden flex flex-row items-center w-full">
                            <span className="w-full h-0.5 bg-primary-newBlue"></span>
                            <span><FontAwesomeIcon icon={faArrowAltCircleDown} className="text-xl text-primary-red mx-2.5"/></span>
                            <span className="w-full h-0.5 bg-primary-newBlue"></span>
                        </div>
                    </div>

                    <div className="w-full p-2.5 flex flex-col items-center gap-2.5 lg:gap-[20px]">
                        <h2 className="number-block bg-primary-newBlue text-white p-20px rounded-full w-90 h-90 flex items-center mx-auto justify-center text-50 font-roboto font-semibold">3</h2>
                        <h4 className="uppercase text-primary-newBlue font-semibold mb-0">Live</h4>
                        <p className="mb-2.5 text-center">Platform is live and you can now request on demand drivers.</p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HowItWorks