import { Tab, Tabs } from "react-bootstrap"
import ProfileForm from "./ProfileForm"
import ChangePassword from "./ChangePassword"

const MyProfileTab = () => {
    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto my_profile_tabs">
           <Tabs
                defaultActiveKey="profile"
                id="my_profile_tabs"
            >
                {/* Profile Tab */}
                <Tab eventKey="profile" title="Profile">
                    <h4 className="py-6 title_font text-center">My Details</h4>
                    <ProfileForm />
                </Tab>

                {/* Change Password */}
                <Tab eventKey="password" title="Change Password">
                    <h4 className="py-6 title_font text-center">Change Password</h4>
                    <ChangePassword />
                </Tab>
            </Tabs>
        </div>
    )
}

export default MyProfileTab