const SalesDemoRequest = () => {
    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <h4 className="pb-6 title_font text-center">Sales Request Demo</h4>

            <iframe 
                src="https://calendly.com/buyingcom/on-demand-delivery-solution-demo-clone?embed_domain=oddsdrive.com&embed_type=Inline" 
                width="100%" 
                height="900px" 
                frameBorder="0" 
                title="Sales Training With Dali"
            />

        </div>
    )
}

export default SalesDemoRequest