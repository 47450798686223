import axios from "axios";
import { CLEAR_ERRORS, SALES_USER_DETAIL_FAIL, SALES_USER_DETAIL_REQUEST, SALES_USER_DETAIL_SUCCESS, UPDATE_SALES_FAIL, UPDATE_SALES_PASSWORD_FAIL, UPDATE_SALES_PASSWORD_REQUEST, UPDATE_SALES_PASSWORD_SUCCESS, UPDATE_SALES_REQUEST, UPDATE_SALES_SUCCESS } from "../../types/salesTypes/salesUsers";

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}

// Sales User Details
export const detailSalesUser = (userData) => async (dispatch) => {
    try {

        dispatch({ type: SALES_USER_DETAIL_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/salepersondetails`,
            userData,
            config
        );

        dispatch({
            type: SALES_USER_DETAIL_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: SALES_USER_DETAIL_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Sales User Details
export const updateSalesProfile = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_SALES_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatesalepersonprofile`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_SALES_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_SALES_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Update Sales User Password
export const updateSalesPassword = (userData) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_SALES_PASSWORD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/updatesalepersonpassword`,
            userData,
            config
        );

        dispatch({
            type: UPDATE_SALES_PASSWORD_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: UPDATE_SALES_PASSWORD_FAIL,
            payload: error.response.data.message,
        });
    }
};