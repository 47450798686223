import { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { getDeliveredOrders, gteOrderInvoice } from "../../../../store/actions/businessActions/businessOrders";
import moment from "moment";
import './DeliveredOrders.css';
import axios from "axios";
import OrderInvoice from "../StatSection/OrderInvoice";

const DeliveredOrders = () => {

    const dispatch = useDispatch();
    const allOrdersRefs = {}

    const { deliveredOrder } = useSelector((state) => state.deliveredOrders);

    const { userDetail } = useSelector((state) => state.userDetails);
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_ordermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_ordermsg").innerHTML = '<td colSpan="9"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_ordermsg").innerHTML = '';
            });
        }
    }

    // handle delivered time show
    const handleDeliveredTime = (id) => {
        if(document.getElementById(id+"_delTime").style.display === "block"){
            document.getElementById(id+"_delTime").style.display = "none";
        } else{
            document.getElementById(id+"_delTime").style.display = "block";
        }
    }

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    useEffect(() => {

        if(userDetail && userDetail.userId){
            dispatch(getDeliveredOrders(userDetail.userId));
        }
        
    }, [dispatch, userDetail]);

    return(
        <div className="delivered-order hidden sm:block mt-15px">
            <Table responsive className="delivered-orders-table">
                <thead>
                    <tr>
                        <th className="w-[20%]">Order ID</th>
                        <th className="w-[12%]">Customer Info</th>
                        <th className="w-[20%]">Contact Messages</th>
                        <th className="w-[10%]">Instant Message</th>
                        <th className="w-[10%]">Instant Message</th>
                        <th className="w-[12%]">Instant Message</th>
                        <th className="w-[2%]">Platform</th>
                        <th className="w-[5%]">Status</th>
                        <th className="w-[2%]">Image</th>
                    </tr>
                </thead>
                <tbody>
                    {deliveredOrder && deliveredOrder.updatedResults && deliveredOrder.updatedResults.length >= 1 && (
                        deliveredOrder.updatedResults.map((item, i) => (
                            <Fragment key={i}>
                                <tr key={i}>
                                    <td className="text-center">
                                        <p className="mb-0">Order ID {item.order_id} {item.delivered_by}</p>
                                        <p className="mb-0">{item.delivery_address}</p>
                                    </td>
                                    <td className="text-center">
                                        <p className="mb-0 font-semibold text-base">
                                            <span className="text-black">{item.delivery_firstname}</span>
                                            <br />
                                            {moment(item.date_added).format("MMM-d-Y h:i:sa")}
                                        </p>
                                    </td>
                                    <td className="text-center">
                                        {item.buying_ghost_order === 1 ?
                                            <span>Order Verification Complete</span>
                                        :
                                            <span className="cursor-pointer" onClick={() => handleMessgeCLick(item._id)}>{item.contact_message_text}</span>
                                        }
                                        
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question">
                                            {item.custom_sms[0].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question">
                                            {item.custom_sms[1].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className="delivered_order_question">
                                            {item.custom_sms[2].question_label}
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        {item.order_type_img && (
                                            item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                                <img src={item.order_type_img} alt="Order Type" className="w-full mx-auto cursor-pointer" onClick={()=> getInvoice(item._id, item.order_type)} />
                                            :
                                                <img src={item.order_type_img} alt="Order Type" className="w-full mx-auto" />
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <p className="mb-2.5">{item.status_text}</p>
                                        <p className="mb-0 cursor-pointer" onClick={() => handleDeliveredTime(item._id)}>Delivered</p>
                                        <p className="mt-1.5 mb-0 text-black text-base hidden delTime" id={item._id+"_delTime"}>{moment(item.mark_complete_time).format("HH:mma")}</p>
                                    </td>
                                    <td className="text-center">
                                        {item.delivered_image_data !== ''
                                        ?
                                            <img src={item.delivered_image_data} alt="Delivered" className="w-[100px]" />
                                        :
                                            item.delivered_text_data
                                        }
                                    </td>
                                </tr>

                                {/* Orders messages */}
                                <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_ordermsg"} key={item._id+"_ordermsg"}>
                                </tr>
                            </Fragment>
                        ))
                    )}
                </tbody>
            </Table>


            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

        </div>
    )
}

export default DeliveredOrders