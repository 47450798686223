import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ContactContent from './ContactContent';
import { useDispatch } from 'react-redux';
import { emptyBusinessSession } from '../../../../store/actions/businessActions/businessUsers';
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import ProfilePayment from './ProfilePayment';
import TripContent from './TripContent';
import OddsOrders from './OddsOrders';
import { useTab } from '../TabContext';
import MoreOrders from './Moreorders';
import { TRIPS_ACTIVE_RESET } from '../../../../store/types/businessTypes/tripsRequested';

const FooterButtons = () => {

    const dispatch = useDispatch();
    const { activeKey, setActiveKey } = useTab();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // handle active tab
    const handleActiveTab = (k) => {
        setActiveKey(k);
        dispatch({ type: TRIPS_ACTIVE_RESET })
    }

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    return(
        <>
            <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => handleActiveTab(k)}>
                <div className='flex flex-col w-full mt-20px'>
                    <Tab.Content>
                        <Tab.Pane eventKey="home"></Tab.Pane>
                        <Tab.Pane eventKey="profile"><ProfilePayment /></Tab.Pane>
                        <Tab.Pane eventKey="trips"><TripContent /></Tab.Pane>
                        <Tab.Pane eventKey="orders"><OddsOrders /></Tab.Pane>
                        <Tab.Pane eventKey="contact"><ContactContent /></Tab.Pane>
                        <Tab.Pane eventKey="moreOrders"><MoreOrders /></Tab.Pane>
                    </Tab.Content>
                
                    <Nav variant="pills" className="flex-row justify-around footer-btn-links mt-20px">
                        <Nav.Item>
                            <Nav.Link eventKey="home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="profile">My Profile / Payment</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="trips">Trips Requested</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="orders">ODDS Orders</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleShow}>Tutorial</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="contact" className='green-btn'>Contact Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Tab.Container>

            <Modal show={show} onHide={handleClose} centered={true} className="popup-dashboard-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton></Modal.Header>
                
                <Modal.Body className="relative">
                    <iframe width="" height="700" src="https://www.youtube.com/embed/oNNKrJBj9nk" title="ODDS Product Tutorial" frameBorder="0" className="runT" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" style={{width: '100%'}}></iframe>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default FooterButtons