import MetaData from "../../layout/MetaData"
import salesMan from "../../assests/images/sales-meeting/sales-man-odds.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import icon from '../../assests/images/sales-meeting/arrow.png';

const JoinSalesTeam = () => {
    return(
        <>
            <MetaData title={"Join Our Sales Team – ODDS – On Demand Delivery Solution"} />
             
            <section className="join-sales-section">
                <div className="max-w-screen-1xl mx-auto py-50 md:py-100px px-2.5">
                    <div className="w-full flex flex-col md:flex-row">
                        <div className="w-full md:w-1/2 p-2.5 flex flex-col gap-[20px]">
                            <a href="/careers" className="flex items-center gap-[20px] py-2.5 text-[#7a7a7a] no-underline">
                                <img src={icon} alt="icon" className="w-10" />
                                <h2 className="text-base mb-0">Back to listing</h2>
                            </a>
                            <h1 className='mb-0 font-semibold text-3xl'>Join Our Sales Team</h1>
                            <h2 className="mb-0 font-semibold text-primary-text text-[25px]">Start selling today!</h2>
                            <div>
                               <p><strong>About Us</strong></p>
                                <p>At ODDS, we're at the forefront of the rapidly expanding on-demand delivery industry, and we're looking for dynamic individuals to join our sales team. If you're a seasoned sales professional or have a passion for connecting with people, this is your chance to be part of one of the fastest-growing sectors.</p> 
                            </div>
                            <div>
                                <p><strong>Who We're Looking For</strong></p>
                                <p>Are you a natural salesperson, or are you eager to embark on a rewarding sales career? Whether you thrive in face-to-face interactions or are a phone communication expert, we want to hear from you! We're seeking individuals who are tech-savvy, comfortable with email and texting, and ready to master our cutting-edge web-based software solution.</p>
                            </div>
                            <img src={salesMan} alt="Sales Man Odds" className="w-full" />
                            <div>
                                <p><strong>Requirements</strong></p>
                                <ul className="list-disc">
                                    <li><strong>Communication Skills:</strong> A knack for effective communication.</li>
                                    <li><strong>Sales and Negotiation Skills:</strong> Proven ability to pitch and negotiate.</li>
                                    <li><strong>Computer Literacy:</strong> Comfortable navigating digital platforms.</li>
                                    <li><strong>Driver's License:</strong> Mobility for potential face-to-face interactions.</li>
                                    <li><strong>Own Laptop:</strong> To access our software and resources.</li>
                                    <li><strong>Own Cell Phone:</strong> Essential for communication.</li>
                                </ul>
                                <p><strong>Tasks & Responsibilities</strong></p>
                                <p>As a key member of our sales team, you'll be responsible for generating leads through strategic cold calling. Your primary objective will be promoting our On-Demand Delivery Solution, showcasing its benefits to restaurant owners and store managers. Enjoy the satisfaction of uncapped commission, which includes a fixed component each time a customer signs up and utilizes our platform, as well as recurring commissions.</p>
                            </div>
                            <div>
                                <p><strong>Benefits</strong></p>
                                <ul className="list-disc">
                                    <li><strong>Uncapped Commission:</strong> Your success is rewarded without limits, with a fixed component and recurring commissions.</li>
                                    <li><strong>Full Initial and Ongoing Training:</strong> We invest in your professional growth.</li>
                                    <li><strong>Amazing Commission and Accelerator:</strong> Your hard work translates to significant earnings.</li>
                                    <li><strong>No Micromanagement:</strong> Enjoy autonomy in your role.</li>
                                    <li><strong>Work at Your Own Pace:</strong> Flexibility to thrive on your terms.</li>
                                    <li><strong>Remote or Face-to-Face Sales:</strong> Choose the approach that suits you.</li>
                                </ul>
                                <p><strong>Join Us Today!</strong></p>
                                <p>If you're ready for a dynamic career in sales, seize this opportunity for an immediate start. Join us and be part of a team that's reshaping the future of on-demand delivery. Register today and take the first step toward a rewarding and lucrative career!</p>
                            </div>
                        </div>

                        <div className="w-full md:w-1/2 bg-[#f0f0f0] p-30px lg:p-50 flex flex-col gap-[20px] md:sticky md:top-[100px] h-max">
                            <h3 className='mb-0 font-semibold text-3xl text-primary-text'>Sales Representative</h3>
                            <h3 className="text-[#23a455] font-roboto font-semibold text-xl mb-0"><strong>Ongoing</strong></h3>
                            <h3 className="font-semibold text-xl mb-0">Commission + Residual Commission</h3>
                            <h3 className="text-xl mb-0">Freelance (1099)</h3>
                            <h3 className="text-xl mb-0">Remote USA / Remote Canada</h3>
                            <p></p>
                            <div className="p-15px lg:p-30px border-div-blue rounded-[20px]">
                                <h3 className="text-lg">Thank you for visiting and applying for a sales position at OddsDrive.com. Please <strong>join our upcoming Zoom call on Wednesday at 5:30 PM (Eastern Standard Time) for more information.</strong><br />
                                You only need to join the call once. We appreciate your interest and look forward to connecting with you.</h3>
                                <div className="pt-20px text-center">
                                    <a href="https://us06web.zoom.us/j/85325295869?pwd=WkFweEMvQUNVRXd2SXFJTG9MeU5nQT09" className="uppercase bg-[#2493e2] font-semibold text-[15px] text-white py-15px px-25px inline-block rounded-[10px] leading-none text-center no-underline hover:underline"><FontAwesomeIcon icon={faVideoCamera} className="mr-2.5"/> Join Zoom Call</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JoinSalesTeam