import { Button, Form } from "react-bootstrap";
import {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_BUSINESS_ACH_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { clearErrors, detailBusinessUser, updateBusinessProfileAch } from "../../../../store/actions/businessActions/businessUsers";
import { useSnackbar } from "notistack";

const ProfileAchForm = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);

    const { errorAch, isAchUpdated } = useSelector((state) => state.businessProfile);

    const [hasAch, setHasAch] = useState(false);

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [legalName, setLegalName] = useState('');
    const [dbaName, setDbaName] = useState('');
    const [bankName, setBankName] = useState('');
    const [routingNum, setRoutingNum] = useState('');
    const [accountNum, setAccountNum] = useState('');

    const handleAchSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData();
        formData.set("legal_name", legalName);
        formData.set("aba_name", dbaName);
        formData.set("bank_name", bankName);
        formData.set("aba_routing_number", routingNum);
        formData.set("aba_account_number", accountNum);
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);

        dispatch(updateBusinessProfileAch(formData))
    }

    useEffect(() => {

        if(loading === false && businessUserDetail && businessUserDetail.status === 1){

            setObjectId(businessUserDetail.user._id)
            setCustomerId(businessUserDetail.user.customer_id);
            setUserEmail(businessUserDetail.user.email);
            setLegalName(businessUserDetail.businessdata.legal_name ? businessUserDetail.businessdata.legal_name : '');
            setDbaName(businessUserDetail.businessdata.aba_name ? businessUserDetail.businessdata.aba_name : "");
            setBankName(businessUserDetail.businessdata.bank_name ? businessUserDetail.businessdata.bank_name : "");

            if(businessUserDetail.businessdata.aba_routing_number){
                const routing = businessUserDetail.businessdata.aba_routing_number.slice(-4);
                // Append 'XXXX' to the base string
                const newRouting = `XXXX${routing}`;
                
                setRoutingNum(newRouting);
            } else {
                setRoutingNum('')
            }

            if(businessUserDetail.businessdata.aba_account_number){
                const account = businessUserDetail.businessdata.aba_account_number.slice(-4);
                // Append 'XXXX' to the base string
                const newAccount = `XXXX${account}`;
                
                setAccountNum(newAccount);
            } else {
                setAccountNum('')
            }
            
            if(businessUserDetail.businessdata.legal_name && businessUserDetail.businessdata.aba_name && businessUserDetail.businessdata.bank_name && businessUserDetail.businessdata.aba_routing_number && businessUserDetail.businessdata.aba_account_number ){
                setHasAch(true);
            } else {
                setHasAch(false);
            }
        }

        // Profile ACH Error
        if (errorAch) {
            enqueueSnackbar(errorAch, { variant: "error" });
            dispatch(clearErrors());
        }

        // Profile ACH updated
        if (isAchUpdated) {

            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: userEmail,
                customer_id: customerId,
            }
            dispatch(detailBusinessUser(userDetails))
            dispatch({ type: UPDATE_BUSINESS_ACH_RESET });
        }
    },[dispatch, loading, isAchUpdated, errorAch, businessUserDetail, objectId, userEmail, customerId, enqueueSnackbar])

    return(
        <div>
            <h4 className=" mb-20px">Ach Payment Information</h4>

            <Form method="post" onSubmit={handleAchSubmit} className="ach_payment_form">
                <div className="flex flex-col md:flex-row -mx-12px">
                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px" controlId="legal_name_new">
                            <Form.Control type="text" name="legal_name_new" placeholder="Legal Name of Business" required value={legalName} onChange={(e) => setLegalName(e.target.value)} />
                        </Form.Group>
                    </div>
                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px" controlId="dba_name_new">
                            <Form.Control type="text" name="dba_name_new" placeholder="DBA Name of Business" required value={dbaName} onChange={(e) => setDbaName(e.target.value)} />
                        </Form.Group>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row -mx-12px mb-15px">
                    
                    <div className="w-full md:w-1/3 px-12px">
                        <Form.Group className="mb-15px" controlId="bank_name_new">
                            <Form.Control type="text" name="bank_name_new" placeholder="Bank Name" required value={bankName} onChange={(e) => setBankName(e.target.value)} />
                        </Form.Group>
                    </div>

                    <div className="w-full md:w-1/3 px-12px">
                        <Form.Group className="mb-15px" controlId="routing_num_new">
                            <Form.Control type="text" name="routing_num_new" placeholder="Aba Routing #" required value={routingNum} onChange={(e) => setRoutingNum(e.target.value)} />
                        </Form.Group>
                    </div>

                    <div className="w-full md:w-1/3 px-12px">
                        <Form.Group className="mb-15px" controlId="account_num_new">
                            <Form.Control type="text" name="account_num_new" placeholder="Account Number" required value={accountNum} onChange={(e) => setAccountNum(e.target.value)} />
                        </Form.Group>
                    </div>

                </div>

                <Form.Group className="text-right">
                    <Button type="submit" className="btn-profile-submit" disabled={hasAch}>Submit</Button>
                </Form.Group>

            </Form>
        </div>
    )
}

export default ProfileAchForm