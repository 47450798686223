import oddsBg from '../../../assests/images/home/odds-bg.jpg';

const ArtCenter = () => {
    return(
        <section className="home-art-center min-h-[850px] relative overflow-hidden flex">
            <div className='w-full p-2.5 bg-[#0003] z-10'>
                <div className='p-30px md:p-50 w-full bg-[#000000b3]'>
                    <div className='max-w-screen-1xl text-white mx-auto'>
                        <h4 className='text-[28.8px] text-center mb-0'><span className='font-semibold'>24 hour a day</span> State Of The Art Center To Handle All Your Logistic, Marketing, & Delivering Needs.</h4>
                    </div>
                </div>
            </div>
            <img src={oddsBg} alt='Odds bg' className='w-full h-full absolute top-0 object-cover left-0 object-bottom-center -z-10' />
        </section>
    )
}

export default ArtCenter