import { GET_SCAN_DRIVERS_FAIL, GET_SCAN_DRIVERS_REQUEST, GET_SCAN_DRIVERS_SUCCESS } from "../../types/businessTypes/scanDrivers";

export const getScanDriversReducers = (state = { types: {} }, { type, payload }) => {
    switch (type) {
        case GET_SCAN_DRIVERS_REQUEST:
            return {
                loading: true,
            };
        case GET_SCAN_DRIVERS_SUCCESS:
            return {
                ...state,
                loading: false,
                scandrivers: payload,
            };
        case GET_SCAN_DRIVERS_FAIL:
            return {
                ...state,
                loading: false,
                scandrivers: null,
                error: payload,
            };
        default:
            return state;
    }
};