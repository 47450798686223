import React, { createContext, useContext, useState } from 'react';

const TabContext = createContext();

export function TabProvider({ children }) {
  const [activeKey, setActiveKey] = useState('home'); // Default tab key

  return (
    <TabContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </TabContext.Provider>
  );
}

export function useTab() {
  return useContext(TabContext);
}
