import axios from "axios";
import { CLEAR_ERRORS, DOWNLOAD_PHONE_FAIL, DOWNLOAD_PHONE_REQUEST, DOWNLOAD_PHONE_SUCCESS, TRIPS_FILTER_FAIL, TRIPS_FILTER_REQUEST, TRIPS_FILTER_SUCCESS } from "../../types/businessTypes/tripsRequested";

// Download Phone Numbers
export const downloadPhoneTrips = (dates) => async (dispatch) => {
    try {
        dispatch({ type: DOWNLOAD_PHONE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/downloadphonenumbers`,
            dates,
            config
        );

        dispatch({
            type: DOWNLOAD_PHONE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: DOWNLOAD_PHONE_FAIL,
            payload: error.response.data,
        });
    }
};

// Get trips filters orders
export const getTripsFilterOrders = (filter) => async (dispatch) => {
    try {
        dispatch({ type: TRIPS_FILTER_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/getorderbyfilter`,
            filter,
            config
        );

        dispatch({
            type: TRIPS_FILTER_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: TRIPS_FILTER_FAIL,
            payload: error.response.data,
        });
    }
};

// Clear All Errors
export const clearOrderErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}