import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/home/Home";
import Login from "./pages/business/Login/Login";
import Register from "./pages/business/Register/Register";
import ConsumerRegister from "./pages/consumer/ConsumerRegister/ConsumerRegister";
import ConsumerLogin from "./pages/consumer/ConsumerLogin/ConsumerLogin";
import SalesLogin from "./pages/sales/SalesLogin/SalesLogin";
import Dashboard from "./pages/business/Dashboard/Dashbaord";
import ProtectedRoute from "./ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { registerType } from "./store/actions/registerTypesAction";
import { useEffect, useState } from "react";
import MarketingAds from "./pages/business/MarketingAds/MarketingAds";
import Refer from "./pages/refer/Refer";
import RequestDemo from "./pages/requestDemo/RequestDemo";
import IntegrationHelp from "./pages/integrationHelp/IntegrationHelp";
import { getChatAdmin } from "./store/actions/businessActions/chatAdmins";
import Integrations from "./pages/integrations/Integrations";
import ConsumerDashboard from "./pages/consumer/ConsumerDashboard/ConsumerDashboard";
import SalesDashboard from "./pages/sales/SalesDashboard/SalesDashboard";
import Pricing from "./pages/pricing/Pricing";
import AboutUs from "./pages/aboutUs/AboutUs";
import SalesMeeting from "./pages/salesMeeting/SalesMeeting";
import JoinSalesTeam from "./pages/joinSalesTeam/JoinSalesTeam";
import Careers from "./pages/joinSalesTeam/Careers";
import RentFree from "./pages/rentFree/RentFree";
import PageNotFound from "./pages/pageNotFound/PageNotFound";

function App() {

  const dispatch = useDispatch();

  const { types, loading } = useSelector((state) => state.registerType);

  const [businessType, setBusinessType] = useState(1);
  const [consumerType, setConsumerType] = useState(2);
  const [salesType, setSalesType] = useState(3);

  useEffect(() => {

    if(loading === false && types.length > 0 ){

      setBusinessType(types.filter(item => item.name === 'businesses')[0].customer_group_id)
      setConsumerType(types.filter(item => item.name === 'consumer')[0].customer_group_id)
      setSalesType(types.filter(item => item.name === 'sales_person')[0].customer_group_id)

    }

    if(loading === undefined){
       dispatch(registerType()); 
       dispatch(getChatAdmin());
    }

    
  }, [dispatch, loading, types])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/refer" element={<Refer />} />
          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/help" element={<IntegrationHelp />} />
          <Route path="/integrations" element={<Integrations />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/sales-meeting" element={<SalesMeeting />} />
          <Route path="/join-our-sales-team" element={<JoinSalesTeam />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/rent-free" element={<RentFree />} />
          
          {/* Business Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/login/user/:userId" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={
            <ProtectedRoute group={businessType}>
              <Dashboard />
            </ProtectedRoute>
          } />

          <Route path="/marketingads" element={
            <ProtectedRoute group={businessType}>
              <MarketingAds />
            </ProtectedRoute>
          } />

          {/* Consumer Routes */}
          <Route path="/consumerlogin" element={<ConsumerLogin />} />
          <Route path="/consumerregister" element={<ConsumerRegister />} />
          <Route path="/consumerdashboard" element={
            <ProtectedRoute group={consumerType} isConsumer={true}>
              <ConsumerDashboard />
            </ProtectedRoute>
          } />

          {/* Sales Routes */}
          <Route path="/loginsales" element={<SalesLogin />} />
          <Route path="/saleperson" element={
            <ProtectedRoute group={salesType} isSales={true}>
              <SalesDashboard />
            </ProtectedRoute>
          } />
          
          
          <Route path="*" element={<PageNotFound />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;