import axios from "axios";
import { REGISTER_TYPE_FAIL, REGISTER_TYPE_REQUEST, REGISTER_TYPE_SUCCESS } from "../types/registerTypes";

// Register Type
export const registerType = () => async (dispatch) => {
    try {

        dispatch({ type: REGISTER_TYPE_REQUEST });

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/usercustomergroup/`,
            {headers: { 
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                'Content-Type': 'application/json'
            }}
        );
          
        dispatch({
            type: REGISTER_TYPE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: REGISTER_TYPE_FAIL,
            payload: error.response.data.message,
        });
    }

};