import MetaData from "../../../layout/MetaData"
import DashboardHeader from "../Dashboard/DashboardHeader";
import icon1 from '../../../assests/images/business/marketing-ads/icon-1.jpg';
import icon2 from '../../../assests/images/business/marketing-ads/icon-2.png';
import icon3 from '../../../assests/images/business/marketing-ads/icon-3.png';
import icon4 from '../../../assests/images/business/marketing-ads/icon-4.png';
import FooterBtn from "./FooterBtn";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { detailBusinessUser } from "../../../store/actions/businessActions/businessUsers";
import { Button, Form, Table } from "react-bootstrap";
import './MarketingAds.css';
import ChatSection from "../../../components/ChatComponent/ChatSection";
import { clearAdsErrors, createAdsRequest, deleteAdsImage, deleteQueueList, getAdsImagesList, getCustomerPhoneCount, getSmsQueueList, sendAdsSMS, uploadAdsImage } from "../../../store/actions/businessActions/MarketingAds";
import Loading from "../../../layout/Loading";
import { useSnackbar } from "notistack";
import { DELETE_ADS_IMAGE_RESET, DELETE_QUEUE_LIST_RESET, SEND_ADS_SMS_RESET } from "../../../store/types/businessTypes/MarketingAds";

const MarketingAds = () => {
    
    const iconList = [
        {
            icon: icon1,
            title: "Conveniently Engage All your Contacts at Once",
        },
        {
            icon: icon2,
            title: "Schedule Texts & Campaigns with Ease",
        },
        {
            icon: icon3,
            title: "Create a Closer Connection with Personalization",
        },
        {
            icon: icon4,
            title: "Track Message Performance to Optimizing Message",
        },
    ]

    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [phoneCountVal, setPhoneCountVal] = useState(null);
    const [adsImage, setAdsImage] = useState('');
    const [sendDate, setSendDate] = useState('');
    const [sendTime, setSendTime] = useState('');
    const [asapVal, setAsapVal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [smsData, setSmsData] = useState('');
    const [smsLink, setSmsLink] = useState('');
    const [testNumber, setTestNumber] = useState('');

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { userDetail } = useSelector((state) => state.userDetails);

    const { phoneCount, loading } = useSelector((state) => state.phoneNoCount);
    const { loading: uploadLoad, success, error, isDeleted, successAds, errorAds, loadingAds, loadingSms, successSms, errorSms, isDeletedQueue } = useSelector((state) => state.adsData);
    const { adsImageList, loading: imageLoading } = useSelector((state) => state.adsImageLists);
    const { smsQueueList, loading: queueLoading } = useSelector((state) => state.smsQueueLists);

    // Handle download phone 
    const handlePhoneCount = () => {
        if(dateStart && dateEnd){

            const formData = new FormData();
            formData.set("start_date", dateStart);
            formData.set("end_date", dateEnd);
            formData.set("_id", userDetail && userDetail.userId);

            dispatch(getCustomerPhoneCount(formData));
        }
    }

    // handle Asap click
    const handleAsapClick = () => {
        setAsapVal(!asapVal);
        setSendDate('');
        setSendTime('');
    }

    // handle send Date click
    const handleSendDate = (e) => {
        setSendDate(e.target.value);
        setAsapVal(false);
    }

    // handle send Time click
    const handleSendTime = (e) => {
        setSendTime(e.target.value);
        setAsapVal(false);
    }

    // handle Image change
    const handleImageChange = (e) => {
        setAdsImage(e.target.files[0]);
    }

    // handle Ads image upload 
    const handleAdsImage = (e) => {

        e.preventDefault();

        const formData = new FormData();
        formData.set("textimg", adsImage);
        formData.set("_id", userDetail && userDetail.userId);

        dispatch(uploadAdsImage(formData))

    }

    // handle Ads image delete
    const handleDeleteAdsImage = (imageId) => {
        if (window.confirm('Are you sure want to delete?')) {
            dispatch(deleteAdsImage(imageId))
        }
        
    }

    // handle Choose Image
    const handleChooseImage = (imageId) => {

        if(dateStart === '' || dateEnd === '' || phoneCountVal === null){
            enqueueSnackbar("Please Choose the time period of Phone Numbers Collected.", { variant: "error" });
            return
        } else if(asapVal === false){
            if(sendDate === '' || sendTime === '')
            {
                enqueueSnackbar("Please let us know when you want to send the SMS.", { variant: "error" });
                return
            }
        }
        setSelectedImage(imageId);

    }

    // Validate phone number and message text filled
    const validateValues = () => {
        if(testNumber === ''){
            enqueueSnackbar("Enter a valid Mobile Number", { variant: "error" });
            return false;
        } else if(smsData === ''){
            enqueueSnackbar("Enter a SMS text", { variant: "error" });
            return false;
        } else if(smsLink === ''){
            enqueueSnackbar("Enter a valid link", { variant: "error" });
            return false;
        } else {
            return true;
        }
    }

    // handle Process order
    const handleProcessOrder = (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.set("_id", userDetail && userDetail.userId);
        formData.set("asap_value", asapVal === true ? 1 : 0);
        formData.set("sms_data", smsData);
        formData.set("sms_link", smsLink);
        formData.set("selected_image", selectedImage);
        formData.set("start_date_phone", dateStart);
        formData.set("end_date_phone", dateEnd);
        formData.set("send_date", sendDate);
        formData.set("send_time", sendTime);

        dispatch(createAdsRequest(formData));
    }

    // handle Send SMS
    const handleSendSms = (e) => {
        e.preventDefault();
        if(validateValues()){
            const formData = new FormData();
            formData.set("test_number", testNumber);
            formData.set("sms_link", smsLink);
            formData.set("sms_data", smsData);
            formData.set("selected_image_id", selectedImage);

            dispatch(sendAdsSMS(formData));
        }
    }

    // handle delete Queue
    const handleDeleteQueue = (id) => {
        if (window.confirm('Are you sure want to delete?')) {
            dispatch(deleteQueueList(id))
        }
    }

    // handle phone change
    const handlePhoneChange = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setTestNumber(value);
    }

    const resetForm = () => {
        setDateStart('');
        setDateEnd('');
        setPhoneCountVal(null);
        setAdsImage('');
        setSendDate('');
        setSendTime('');
        setAsapVal(false);
        setSelectedImage('');
        setSmsData('');
        setSmsLink('');
        setTestNumber('');
    }

    useEffect(() => {

        // Image Upload error
        if (error) {
            enqueueSnackbar("Upload another image.", { variant: "error" });
            dispatch(clearAdsErrors());
        }

        // Image uppload success
        if (success) {
            enqueueSnackbar(success, { variant: "success" });
            dispatch(clearAdsErrors());
            dispatch(getAdsImagesList(userDetail && userDetail.userId));
        }

        // Create ads error
        if (errorAds) {
            enqueueSnackbar(errorAds, { variant: "error" });
            dispatch(clearAdsErrors());
        }

        // Create Ads success
        if (successAds) {
            enqueueSnackbar(successAds, { variant: "success" });
            dispatch(clearAdsErrors());
            dispatch(getSmsQueueList(userDetail && userDetail.userId));
            resetForm();
        }

        // Send sms success
        if (successSms) {
            enqueueSnackbar(successSms, { "Test message has been sent! Please check your cell phone.": "success" });
            dispatch({ type: SEND_ADS_SMS_RESET });
        }

        // Send sms error
        if(errorSms){
            enqueueSnackbar(errorSms, { variant: "error" });
            dispatch({ type: SEND_ADS_SMS_RESET });
        }

        // Image deleted
        if(isDeleted){
            enqueueSnackbar("Image Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_ADS_IMAGE_RESET });
            dispatch(getAdsImagesList(userDetail && userDetail.userId));
        }

        // Queue list deleted
        if(isDeletedQueue){
            enqueueSnackbar("Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_QUEUE_LIST_RESET });
            dispatch(getSmsQueueList(userDetail && userDetail.userId));
        }

        if(phoneCount && phoneCount.status === 1){
            setPhoneCountVal(phoneCount.phonecount);
        }

        if(userDetail && userDetail.userId){
           
            var userDetails = {
                obj_id: userDetail.userId,
                email: userDetail.userEmail,
                customer_id: userDetail.customerId,
            }
            dispatch(detailBusinessUser(userDetails));
            
        }

        if(imageLoading === undefined){
            dispatch(getAdsImagesList(userDetail && userDetail.userId));
        }

        if(queueLoading === undefined){
            dispatch(getSmsQueueList(userDetail && userDetail.userId));
        }
        
    }, [dispatch, userDetail, success, error, enqueueSnackbar, isDeleted, phoneCount, errorAds, successAds, successSms, errorSms, imageLoading, queueLoading, isDeletedQueue])

    return(
        <>
            <MetaData
                title={"Marketing Ads"}
            />

            {loading &&
                <Loading />
            }
            

            <section className="business-dashboard markeing-ads-section sales-pages">

                {/* Header */}
                <DashboardHeader />

                {/* main section */}
                <div className="max-w-screen-2xl mx-auto px-12px">
                    <div className="main-inner p-20px">
                        <h1 className="text-center">What does Our Texting Do for You?</h1>
                        <div className="flex flex-col md:flex-row flex-wrap my-15px">
                            {iconList.map((item,i) => (
                                <div className="w-full md:w-1/2 px-12px mb-20px" key={i}>
                                    <div className="flex items-center gap-[20px]">
                                        <img src={item.icon} alt="Icon" className="w-20 sm:w-100px h-20 sm:h-100px" />
                                        <h2 className="text-lg sm:text-[22px]">{item.title}</h2>
                                    </div>
                                </div>
                            ))}
                            
                        </div>

                        <div className="flex flex-col md:flex-row -mx-12px">
                            {/* form column */}
                            <div className="w-full md:w-2/3 px-12px">
                                <div className="my-20px">
                                    <p className="mb-0">Choose the time period of Phone Numbers Collected </p>
                                </div>
                                <div className="my-20px flex flex-wrap gap-2.5 items-center">
                                    <span>from</span>
                                    <input type="date" id="fs_date4" name="fs_date4" className="form-control text-control" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
                                    <span>to</span>
                                    <input type="date" id="fe_date4" name="fe_date4" className="form-control text-control" value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
                                    <Button type="button" className="report_send_sms" onClick={() => handlePhoneCount()}>Download Phone #'s</Button>
                                </div>

                                {phoneCount && phoneCount.status === 1 &&
                                    <div className="my-20px text-[#39b547] font-semibold">
                                        <p className="mb-0">Congratulations! We have found {phoneCountVal} Unique Customer Numbers</p>
                                    </div>
                                }

                                <div className="my-20px">
                                    <p className="mb-0">Please Choose Date & Time you want your text Sent: </p>
                                </div>
                                <div className="my-20px flex flex-wrap gap-2.5 items-center">
                                    <span>(Day)</span>
                                    <input type="date" id="fs_date5" name="fs_date5" className="form-control text-control" value={sendDate} onChange={(e) => handleSendDate(e)} />
                                    <span>(Time)</span>
                                    <input type="time" id="fs_time5" name="fs_time5" className="form-control text-control" value={sendTime} onChange={(e) => handleSendTime(e)} />
                                    <Button type="button" className={`select_asap ${asapVal ? 'active' : ''}`} onClick={handleAsapClick}>ASAP</Button>
                                </div>

                                <div className="message_form_div">
                                    <Form method="post" className="message_form" encType="multipart/form-data" onSubmit={handleAdsImage}>
                                        <span>Upload new art work or choose from below. Size 234 X 320 (jpeg-gif) </span>
                                        <input type="file" name="fileupload1" id="fileupload1" className="w-[220px]" required onChange={handleImageChange} />
                                        <Button type="submit" className="submit_message">{uploadLoad ? 'Uploading' : 'Submit'}</Button>
                                    </Form>
                                </div>
                                <div className="ads_section">
                                    {adsImageList && adsImageList.status === 1 && (
                                        <>
                                            <p className="my-20px">Please choose your current image that is available for sending</p>
                                            <ul className="flex flex-wrap p-0">
                                                {adsImageList.getList.map((item,i) => (
                                                    <li key={i} className={`w-full sm:w-6/12 xl:w-3/12 pr-20px ${selectedImage === item._id ? 'active' : ''}`}>
                                                        <div className="h-60">
                                                            <img src={`${process.env.REACT_APP_API_URL}/images/restaurant_ads/${item.upload_image}`} alt={item._id} className="max-h-full object-contain object-top mx-auto cursor-pointer" onClick={() => handleChooseImage(item._id)} />
                                                        </div>
                                                        
                                                        <p className="flex justify-center my-20px gap-[15px]">
                                                            <Button type="button" className="image_list_btn" onClick={() => handleChooseImage(item._id)}>Choose</Button>
                                                            <Button type="button" className="image_list_btn" onClick={() => handleDeleteAdsImage(item._id)}>Delete</Button>
                                                        </p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>

                                <div className="send_text_data my-20px flex flex-col lg:flex-row gap-6">
                                    <div className="w-full md:w-1/2">
                                        <Form.Group className="mb-2.5" controlId="sms_data">
                                            <Form.Control as="textarea" name="sms_data" rows={3} placeholder="SMS Text Message" maxLength={150} value={smsData} onChange={(e) => setSmsData(e.target.value)} />
                                        </Form.Group>
                                        <p className="mb-0">Example 10% off with this Ad Fri - Sunday </p>
                                        <p className="mb-0">{150 - smsData.length} Characters allowed </p>
                                    </div>
                                    <div className="w-full md:w-1/2">
                                        <Form.Group className="mb-2.5" controlId="sms_link">
                                            <Form.Control type="text" name="sms_link" placeholder="Link to your store or Uber - DD or GH" value={smsLink} onChange={(e) => setSmsLink(e.target.value)}  />
                                        </Form.Group>
                                        <p className="mb-0">www.yourstore.com or your uber - gh - dd link</p>
                                    </div>
                                </div>

                                {selectedImage !== '' &&
                                    <div className="test_msg my-20px flex flex-col sm:flex-row gap-2.5 items-start sm:items-center">
                                        Send a Test Msg on your Mobile: 
                                        <input type="tel" name="test_number" id="test_number" maxLength="10" className="form-control text-control" value={testNumber} onChange={(e) => handlePhoneChange(e)} />
                                        <Button type="button" className="send_msg_asap min-w-28" onClick={handleSendSms}>{loadingSms ? 'Sending..' : 'Send'}</Button>
                                    </div>
                                }

                                {phoneCount && phoneCount.status === 1 &&
                                    <div className="my-20px">
                                        <p className="mb-0">The total fee to send {phoneCountVal} marketing notifications is $0</p>
                                    </div>
                                }

                                {selectedImage !== '' &&
                                    <Button type="button" className="process_order min-w-44" onClick={handleProcessOrder}>{loadingAds ? 'Processing..' : 'Process Order'}</Button>
                                }

                            </div>

                            {/* chat column */}
                            <div className="w-full md:w-1/3 px-12px">
                                <ChatSection />
                            </div>

                        </div>

                        <div className="in_queue_msgs">
                            {smsQueueList && smsQueueList.length >= 1 &&
                                <>
                                <h3>Queue List </h3>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>SMS Text</th>
                                            <th>SMS Link</th>
                                            <th>Send Date/Time</th>
                                            <th>Status</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {smsQueueList.map((itemList,i) => (
                                                <tr key={i}>
                                                    <td>{itemList.sms_data}</td>
                                                    <td>{itemList.sms_link}</td>
                                                    <td>
                                                        {itemList.asap_value === '1' ?
                                                            'Asap'
                                                        : itemList.sms_sent_time ?
                                                            itemList.sms_sent_time
                                                        :   
                                                            (itemList.send_date + " " + itemList.send_time)
                                                        }
                                                    </td>
                                                    <td>{itemList.send_status === 1 ? 'Sent' : 'In Queue'}</td>
                                                    <td>
                                                        {itemList.selected_image ?
                                                            <img src={`${process.env.REACT_APP_API_URL}/images/restaurant_ads/${itemList.selected_image}`} alt={itemList._id} className="w-20 object-contain object-top" />
                                                        :
                                                            null
                                                        }
                                                        
                                                    </td>
                                                    <td>
                                                        {itemList.send_status === 1 ? 
                                                            null 
                                                        : 
                                                            <Button type="button" className="del_msg" onClick={(e) => handleDeleteQueue(itemList._id)}>DELETE</Button>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                </>
                            }
                        </div>

                        {/* Footer Btns */}
                        <FooterBtn />
                    </div>
                </div>
            </section>
        </>
    )
}

export default MarketingAds