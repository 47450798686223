import { CLEAR_ERRORS, SALES_USER_DETAIL_FAIL, SALES_USER_DETAIL_REQUEST, SALES_USER_DETAIL_SUCCESS, UPDATE_SALES_FAIL, UPDATE_SALES_PASSWORD_FAIL, UPDATE_SALES_PASSWORD_REQUEST, UPDATE_SALES_PASSWORD_RESET, UPDATE_SALES_PASSWORD_SUCCESS, UPDATE_SALES_REQUEST, UPDATE_SALES_RESET, UPDATE_SALES_SUCCESS } from "../../types/salesTypes/salesUsers";

// sales person user details
export const salesDetailsReducer = (state = { salesUserDetail: {} }, { type, payload }) => {
    switch (type) {
        case SALES_USER_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case SALES_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                salesUserDetail: payload,
            };
        case SALES_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                salesUserDetail: null,
                error: payload,
            };
        default:
            return state;
    }
}

// Sales Profile reducer
export const salesProfileReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_SALES_REQUEST:
        case UPDATE_SALES_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case UPDATE_SALES_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
         case UPDATE_SALES_RESET:
            return {
                ...state,
                isUpdated: false,
                error: null,
            }
        // Password 
        case UPDATE_SALES_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isPassUpdated: payload,
            };
        case UPDATE_SALES_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                errorPass: payload,
            }
        case UPDATE_SALES_PASSWORD_RESET:
            return {
                ...state,
                isPassUpdated: false,
                errorPass: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};