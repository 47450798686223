import { Nav } from "react-bootstrap"
import { emptyBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { useDispatch } from "react-redux";

const FooterBtn = () => {

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    return(
        <Nav className="flex-row justify-around footer-btn-links mt-20px">
            <Nav.Item>
                <Nav.Link href="/dashboard">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard">My Profile / Payment</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard">Trips Requested</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard">ODDS Orders</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard">Tutorial</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/dashboard" className='green-btn'>Contact Us</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default FooterBtn