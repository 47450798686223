import { Button, Form } from "react-bootstrap"
import './Login.css';
import shape1 from '../../../assests/images/business/shape1.png';
import shape2 from '../../../assests/images/business/shape2.png';
import MetaData from "../../../layout/MetaData";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, emptyBusinessSession, loginBusinessUser, loginBusinessUserFromAdmin, setBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { useNavigate } from "react-router-dom";
import { RESET_BUSINESS } from "../../../store/types/businessTypes/businessUsers";
import { registerType } from "../../../store/actions/registerTypesAction";
import { useLocation } from 'react-router-dom';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
  

    const { types } = useSelector((state) => state.registerType);

    const { isAuthenticated, error, user } = useSelector((state) => state.businessUser);

    const { userDetail } = useSelector((state) => state.userDetails);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const messageRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        var group = types.filter(item => item.name === 'businesses');
        var customer_group_id = group[0].customer_group_id;

        const formData = new FormData();
        formData.set("email", email);
        formData.set("password", password);
        formData.set("customer_group_id", customer_group_id);

        dispatch(loginBusinessUser(formData));
    }

    // Fetching user Id from URL for Auto login
    useEffect(() => {
        const fetchUserId = () => {
            const pathParts = location.pathname.split('/');
            return pathParts[pathParts.length - 1];
        };

        const userId = fetchUserId();
        if (location.pathname === `/login/user/${userId}`) {
            dispatch(emptyBusinessSession());
            dispatch({ type: RESET_BUSINESS});
            console.log(userId)
            dispatch(loginBusinessUserFromAdmin(userId));
        }
    }, [dispatch, location.pathname]);
    // End Fetching user Id from URL for Auto login

    useEffect(() => {

        if (error) {
            setErrorMessage(error);
            messageRef.current.focus();

            setTimeout(()=> {
                setErrorMessage("");
                dispatch(clearErrors());
            }, 10000)
            
        }

        if (isAuthenticated) {
            var userDetails = {
                userId: user._id,
                userEmail: user.email,
                customerGroup: user.customer_group_id,
                customerId: user.customer_id,
                userName: user.firstname,
            }
            dispatch(setBusinessSession(userDetails))

            dispatch({ type: RESET_BUSINESS});
        }

        if(userDetail && userDetail.userId){
           
            //console.log(userDetail) 
            navigate('/dashboard');
        }

        dispatch(registerType());
        
    }, [dispatch, isAuthenticated, error, user, userDetail, navigate])
    
    return(
        <>
            {/* SEO Meta */}
            <MetaData
                title={"ODDS Log In"}
            />
            
            {/* Login section */}
            <section className="business-login">
                <div className="max-w-screen-1xl mx-auto py-100px flex flex-col relative">
                    <div className="px-15px">
                        <h1 className="text-[58px] font-semibold text-center mb-0">ODDS Business Login</h1>
                    </div>

                    <div tabIndex="-1" ref={messageRef} className="message text-center">
                        {errorMessage && (
                            <div className="text-red-500">{errorMessage}</div>
                        )}
                    </div>
                    
                    <div className="mt-20px px-15px">
                        <div className="form business-login-register max-w-700 mx-auto py-8 md:py-10 px-8 md:px-16">
                            <Form className="form-login-regiser form" onSubmit={handleSubmit} method="post">

                                <Form.Group className="mt-20px" controlId="email">
                                    <Form.Control type="email" placeholder="Email" name="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="mt-20px" controlId="password">
                                    <Form.Control type="password" placeholder="Password" name="password" required value={password} onChange={(e) => setPassword(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mt-20px text-center">
                                    <Button type="submit" className="btn-submit mt-100px">Log in</Button>
                                </Form.Group>

                                <p className="get-started text-xl mt-20px text-center">Don't have an account? <a href="/register" className="btn-stated text-base text-primary-blue">Get Started</a></p>

                            </Form>
                        </div>
                    </div>

                    <img src={shape1} alt="Shape1" className="max-w-200 absolute -top-6 -z-10" />
                    <img src={shape2} alt="Shape2" className="max-w-100 absolute top-0 bottom-0 right-0 m-auto -z-10" />
                </div>
            </section>
        </>
    )
}

export default Login