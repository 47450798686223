import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap"
import { usePlacesWidget } from "react-google-autocomplete";

const MyProfilePayment = () =>{

    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [restaurantCity, setRestaurantCity] = useState('');
    const [restaurantState, setRestaurantState] = useState('');
    const [restaurantZipcode, setRestaurantZipcode] = useState('');
    const [restaurantCountry, setRestaurantCountry] = useState('');
    const [restaurantStreetAddress, setRestaurantStreetAddress] = useState('');
    const [restaurantLat, setRestaurantLat] = useState('');
    const [restaurantLong, setRestaurantLong] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [restaurantPhone, setRestaurantPhone] = useState('');
    const [restaurantEmailAdd, setRestaurantEmailAdd] = useState('');
    const [accountNumber, setAccountNumber] = useState(''); 
    const [ccExpirationMonth, setCcExpirationMonth] = useState(''); 
    const [ccExpirationYear, setCcExpirationYear] = useState('');
    const [ccCvv, setCcCvv] = useState(''); 
    const [years, setYears] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    // address autocomplete
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {
                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setRestaurantStreetAddress(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setRestaurantZipcode(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setRestaurantCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setRestaurantState(state)
        
                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setRestaurantCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setRestaurantLat(lat);
                setRestaurantLong(lng);
            }
          setRestaurantAddress(place.formatted_address);
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "us" },
        },
    });

     // handle restaurant mobile number
     const handleRestaurantPhone = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setRestaurantPhone(value);
    }

    useEffect(() => {
        // Year dropdown
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let i = 0; i < 15; i++) {
        yearsArray.push(currentYear + i);
        }
        setYears(yearsArray);
    }, [])

    // get year as 2 digit
    const getTwoDigitYear = (year) => year % 100;

    return(
        <div className="p-2.5">
            <h4 className="flex justify-between mb-20px">Profile <span></span></h4>

            <Form method="post" onSubmit={handleSubmit} className="business_profile_form">
                <div className="flex flex-col md:flex-row -mx-12px">
                    <div className="w-full md:w-1/2 px-12px">

                        <Form.Group className="mb-15px" controlId="restaurant_address">
                            <Form.Control ref={ref}  type="text" placeholder="Address*" name="restaurant_address" required defaultValue={restaurantAddress} />
                            <input type="hidden" name="restaurant_street_address" defaultValue={restaurantStreetAddress} />
                            <input type="hidden" name="restaurant_country" defaultValue={restaurantCountry} />
                            <input type="hidden" name="restaurant_lat" defaultValue={restaurantLat} />
                            <input type="hidden" name="restaurant_long" defaultValue={restaurantLong} />
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_city">
                            <Form.Control type="text" placeholder="City*" name="restaurant_city" required value={restaurantCity} onChange={(e) => setRestaurantCity(e.target.value)}/>
                        </Form.Group>

                        <Form.Select name="restaurant_state" id="restaurant_state" className="mb-15px" value={restaurantState} onChange={(e) => setRestaurantState(e.target.value)} required>
                            <option>State</option>
                            
                        </Form.Select>

                        <Form.Group className="mb-15px" controlId="restaurant_zipcode">
                            <Form.Control type="text" placeholder="Zipcode*" name="restaurant_zipcode" required value={restaurantZipcode} onChange={(e) => setRestaurantZipcode(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_phone">
                            <Form.Control type="tel" placeholder="Phone*" name="restaurant_phone" required value={restaurantPhone} onChange={handleRestaurantPhone} minLength={10} maxLength={10}/>
                        </Form.Group>
                    </div>

                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px" controlId="first_name">
                            <Form.Control type="text" name="first_name" placeholder="First Name*" required value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="last_name">
                            <Form.Control type="text" placeholder="Last Name*" name="last_name" required value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_email">
                            <Form.Control type="email" placeholder="Email*" name="restaurant_email" required value={restaurantEmailAdd} onChange={(e) => setRestaurantEmailAdd(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="account_number">
                            <Form.Control type="text" placeholder="Card Number*" name="account_number" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} maxLength={16} />
                        </Form.Group>

                        <div className="flex flex-col sm:flex-row flex-wrap gap-2.5 sm:items-center mb-15px select-flex">
                            <Form.Select name="exp_month" id="exp_month" value={ccExpirationMonth} onChange={(e) => setCcExpirationMonth(e.target.value)}>
                                <option value="">MM</option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Form.Select>

                            <label>Exp/Year</label>

                            <Form.Select name="cc_expiration_year" id="cc_expiration_year" value={ccExpirationYear}  onChange={(e) => setCcExpirationYear(e.target.value)}>
                                <option value="">YY</option>
                                {years.map((year) => (
                                    <option key={year} value={getTwoDigitYear(year).toString().padStart(2, '0')}>
                                     {year}
                                    </option>
                                ))}
                            </Form.Select>

                            <label>CVV</label>
                            
                            <Form.Group controlId="cc_cvv">
                                <Form.Control type="password" placeholder="CVV" name="cc_cvv" value={ccCvv} onChange={(e) => setCcCvv(e.target.value)}/>
                            </Form.Group> 
                            
                            <Form.Group className="text-right flex-1">
                                <Button type="submit" className="btn-consumer-profile">Submit</Button>
                            </Form.Group>
                        </div>

                    </div>

                </div>
            </Form>
        </div>
    )
}

export default MyProfilePayment