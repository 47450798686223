const OneOnOneTab = () => {
    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <h4 className="pb-6 title_font text-center">1 on 1 Training</h4>

            <iframe 
                src="https://calendly.com/sales-oddsdrive/sales-training-with-dali" 
                width="100%" 
                height="900px" 
                frameBorder="0" 
                title="Sales Training With Dali"
            />

        </div>
    )
}

export default OneOnOneTab