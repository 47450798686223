// Download phone types
export const DOWNLOAD_PHONE_REQUEST = "DOWNLOAD_PHONE_REQUEST";
export const DOWNLOAD_PHONE_SUCCESS = "DOWNLOAD_PHONE_SUCCESS";
export const DOWNLOAD_PHONE_FAIL = "DOWNLOAD_PHONE_FAIL";
export const DOWNLOAD_PHONE_RESET = "DOWNLOAD_PHONE_RESET";

// Get Trips filter orders
export const TRIPS_FILTER_REQUEST = "TRIPS_FILTER_REQUEST";
export const TRIPS_FILTER_SUCCESS = "TRIPS_FILTER_SUCCESS";
export const TRIPS_FILTER_FAIL = "TRIPS_FILTER_FAIL";
export const TRIPS_FILTER_RESET = "TRIPS_FILTER_RESET";
export const TRIPS_ACTIVE_RESET = "TRIPS_ACTIVE_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";