import { applyMiddleware, compose, createStore } from "redux";
import rootReducers from './reducers';
import { thunk } from "redux-thunk";



const initalState = {
    userDetails: {
        userDetail: localStorage.getItem('userDetail')
            ? JSON.parse(localStorage.getItem('userDetail'))
            : {},
    },
}

const middleware = [thunk];

const store = createStore(
    rootReducers,
    initalState,
    compose(applyMiddleware(...middleware))
)

export default store