import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import noriskImg from '../../../assests/images/home/no-risk-odds.webp'

const NoRisk = () => {

    const listItem = [
        "No contract!",
        "No hidden fees!",
        "No upfront costs!",
        "Flat delivery fee per order.",
        "FREE computer system!"
    ]

    return(
        <section className="home-no-risk-section">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-3/5 py-30px px-2.5 flex flex-col gap-[20px]">
                    <h6 className='text-primary-green uppercase font-semibold tracking-[1px] mb-0'>No Risk</h6>
                    <h3 className='text-4xl font-semibold'>Try our solution hassle free today!</h3>
                    <ul className='p-0 m-0'>
                        {listItem.map((item,i) => (
                            <li key={i} className='flex flex-row items-center pb-2.5'>
                                <FontAwesomeIcon icon={faCheck} className='text-2xl text-primary-blue pr-12px mr-20px' />
                                <span className='text-xl'>{item}</span>
                            </li>
                        ))}
                    </ul>
                    
                </div>

                <div className="w-full md:w-2/5 pl-2.5 md:pl-20px py-15px md:py-0 pr-2.5 md:pr-0 text-center">
                    <img
                        src={noriskImg}
                        alt='No Risk'
                        className='w-full'
                    />
                    <a href="/register" className="inline-block uppercase text-primary-white text-base font-semibold bg-primary-red border-primary-red border-2 px-30px py-15px no-underline rounded-50 leading-none tracking-wider hover:text-primary-red hover:bg-white mt-20px">Get Started</a>
                </div>

            </div>
        </section>
    )
}

export default NoRisk