import MetaData from "../../layout/MetaData";
import { Link } from 'react-router-dom';
import integrationsImg from '../../assests/images/integrations/integration.png';
import grubhub from '../../assests/images/integrations/grubhub.jpg';
import ubereats from '../../assests/images/integrations/ubereats.png';
import chownow from '../../assests/images/integrations/chownow-logo.png';
import orderspoon from '../../assests/images/integrations/ospoon-orange.png';
import caviar from '../../assests/images/integrations/caviar-logo.png';
import gopuff from '../../assests/images/integrations/gopuff-logo.png';
import doordash from '../../assests/images/integrations/DoorDash-logo.png';
import walgreens from '../../assests/images/integrations/walgreens.jpg';
import ehungry from '../../assests/images/integrations/ehungry.png';
import instacart from '../../assests/images/integrations/instacart-logo.png';
import ordermark from '../../assests/images/integrations/Ordermark.png';
import postmates from '../../assests/images/integrations/Postmates-Logo.png';
import goodyear from '../../assests/images/integrations/goodyear.jpg';
import seamless from '../../assests/images/integrations/seamless-logo.png';
import lowes from '../../assests/images/integrations/lowes.jpg';
import ftd from '../../assests/images/integrations/ftd.jpg';
import slice from '../../assests/images/integrations/slice.png';
import homeDepot from '../../assests/images/integrations/home-depot.jpg';
import cvsHealth from '../../assests/images/integrations/cvs-health.jpg';
import shape1 from '../../assests/images/business/shape1.png';
import {AnimatedOnScroll} from "react-animated-css-onscroll";

const Integrations = () => {

    return(
        <>
            <MetaData
                title={"Integration - ODDS - On Demand Delivery Solution"}
            />

            <section className="integration-section relative overflow-hidden z-10">
                <div className="max-w-screen-1xl mx-auto py-50 text-center text-primary-text33">
                    <div className="w-full p-2.5">
                        <h1 className="text-5xl md:text-[56px] font-bold mb-20px text-primary-text">Integrations</h1>
                        <p className="mb-20px">Seamless Integration with Existing Aggregators: Our system easily integrates with any POS you might already be using. This consolidation allows all your orders and deliveries to be managed from one centralized platform, eliminating the need for multiple tablets and printers. Plus, we provide you with a complimentary computer, so you'll have all you need at no extra cost!</p>
                    </div>

                    <div className="w-full p-2.5">
                        <img src={integrationsImg} alt="Integration" className="w-full slideInDown" />
                    </div>
                </div>
                <img src={shape1} alt="Shape1" className="hidden md:block max-w-[148px] absolute bottom-[50px] right-6 -z-10 bounceInDown" />
                <img src={shape1} alt="Shape1" className="hidden md:block max-w-[90px] absolute bottom-1/3 left-6 -z-10 bounceInDown filter-color-blue" />
            </section>

            {/* client logos */}
            <section className="integration-gallery-section relative overflow-hidden">
                <div className="max-w-screen-1xl pt-50 mx-auto text-center text-primary-text33">
                    <div className="w-full p-2.5">
                        <h1 className="text-5xl md:text-[56px] font-bold mb-20px text-primary-text">All in one</h1>
                        <p className="mb-20px">Our solution seamlessly integrates with all aggregators, including your sales app, website, POS, and online ordering system.</p>
                    </div>
                </div>

                <div className={`gallery-images-block max-w-screen-1xl py-50 mx-auto text-center text-primary-text33`}>
                    <div className="w-full p-2.5">
                        <div className="flex justify-center items-end gap-1.5 sm:gap-4">
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={100}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={grubhub}
                                        alt="GRUBHUB"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000">
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={ubereats}
                                        alt="UberEats"
                                    />
                                </div>
                            </AnimatedOnScroll>

                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={150}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={chownow}
                                        alt="ChowNow"
                                    />
                                </div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="flex flex-wrap justify-center items-end gap-1.5 sm:gap-4">
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000">
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={orderspoon}
                                        alt="orderspoon"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={caviar}
                                        alt="Caviar"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={300}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={doordash}
                                        alt="Doordash"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={700}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={gopuff}
                                        alt="Gopuff"
                                    />
                                </div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="flex flex-wrap justify-center items-end gap-1.5 sm:gap-4">
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={walgreens}
                                        alt="Walgreens"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={100}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={ehungry}
                                        alt="ehungry"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000">
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={instacart}
                                        alt="Instacart"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000">
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={ordermark}
                                        alt="Ordermark"
                                    />
                                </div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="flex justify-center items-end gap-1.5 sm:gap-4">
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={450}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={postmates}
                                        alt="Postmates"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={goodyear}
                                        alt="GoodYear"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000">
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={seamless}
                                        alt="Seamless"
                                    />
                                </div>
                            </AnimatedOnScroll>
                        </div>
                        <div className="flex flex-wrap justify-center items-end gap-1.5 sm:gap-4">
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={lowes}
                                        alt="Lowes"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={ftd}
                                        alt="FTD"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={600}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={slice}
                                        alt="Slice"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={homeDepot}
                                        alt="Home Depot"
                                    />
                                </div>
                            </AnimatedOnScroll>
                            <AnimatedOnScroll animationIn="bounceInDown" duration="1000" animationInDelay={200}>
                                <div className="w-1/3 sm:w-auto">
                                    <img
                                        className="h-auto w-full sm:w-44"
                                        src={cvsHealth}
                                        alt="CVS Health"
                                    />
                                </div>
                            </AnimatedOnScroll>
                        </div>

                        <div className="pt-20 pb-15px">
                          <Link to="/register" className="text-white text-base font-medium bg-primary-blue mr-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider border-2 border-primary-blue hover:bg-transparent hover:text-primary-blue">Get Started</Link>  
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Integrations