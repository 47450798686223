import MetaData from "../../layout/MetaData"

const Careers = () => {
    return(
        <>
            <MetaData title={"Careers – ODDS – On Demand Delivery Solution"} />

            <section className="join-sales-section">
                <div className="max-w-screen-1xl mx-auto py-50 md:py-100px px-2.5">
                    <div className="flex flex-col md:flex-row gap-[20px] rounded-[25px] career-border-div p-30px items-center">
                        <div className="w-full md:w-2/5 p-2.5">
                            <h2 className="text-[28px] font-semibold">Sales Representative</h2>
                            <h3 className="text-[#23a455] font-roboto font-semibold text-xl mt-20px mb-0"><strong>Ongoing</strong></h3>
                        </div>
                        <div className="w-full md:w-2/5 p-2.5">
                            <h3 className="font-semibold text-xl mb-2.5">Commission + Residual Commission</h3>
                            <p>Freelance (1099)</p>
                            <p className="pt-15px">Remote USA / Remote Canada</p>
                        </div>
                        <div className="w-full md:w-1/5 p-2.5 text-center">
                            <a href="/join-our-sales-team" className="inline-block leading-none block rounded-[10px] bg-[#35da4b] text-black py-15px px-30px text-center no-underline hover:underline">More Info</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers