// New  Internal Order
export const NEW_INTERNAL_ORDER_REQUEST = "NEW_INTERNAL_ORDER_REQUEST";
export const NEW_INTERNAL_ORDER_SUCCESS = "NEW_INTERNAL_ORDER_SUCCESS";
export const NEW_INTERNAL_ORDER_FAIL = "NEW_INTERNAL_ORDER_FAIL";

// Get Orders Details
export const MY_ORDERS_REQUEST = "MY_ORDERS_REQUEST";
export const MY_ORDERS_SUCCESS = "MY_ORDERS_SUCCESS";
export const MY_ORDERS_FAIL = "MY_ORDERS_FAIL";

// Get order Invoice Details
export const ORDERS_INVOICE_REQUEST = "ORDERS_INVOICE_REQUEST";
export const ORDERS_INVOICE_SUCCESS = "ORDERS_INVOICE_SUCCESS";
export const ORDERS_INVOICE_FAIL = "ORDERS_INVOICE_FAIL";

// Get Delivered Orders
export const DELIVERED_ORDERS_REQUEST = "DELIVERED_ORDERS_REQUEST";
export const DELIVERED_ORDERS_SUCCESS = "DELIVERED_ORDERS_SUCCESS";
export const DELIVERED_ORDERS_FAIL = "DELIVERED_ORDERS_FAIL";


export const CLEAR_ERRORS = "CLEAR_ERRORS";