import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import './OrderInvoice.css';
import { useDispatch, useSelector } from "react-redux";
import { clearOrderErrors } from "../../../../store/actions/businessActions/businessOrders";
import { useSnackbar } from "notistack";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

const OrderInvoice = ({id, orderType, setDisplayInvoice}) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [invoiceShow, setInvoiceShow] = useState(false);
    const { orderInvoice, error, loading } = useSelector((state) => state.ordersInvoice);
    const [itemCount, setItemCount] = useState();

    const handleInvoiceClose = () => {
        setInvoiceShow(false)
        setDisplayInvoice(false)
    };
    const handleInvoiceShow = () => setInvoiceShow(true);

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        // onBeforePrint: () => console.log("before printing..."),
        // onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });
         
    useEffect(() =>{

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearOrderErrors());
        }
        
        if(orderInvoice && orderInvoice.status === 1){

            if(orderInvoice.orderItems && orderType === 6){
                handleInvoiceShow();
            }

            if(orderInvoice.orderItems && orderInvoice.show_invoice === 1){
                var orderItemCount = 0;
               
                orderInvoice.orderItems.map((item) => (
                    JSON.parse(item.order_items).map((order) => (
                        orderItemCount += order.quantity
                    ))
                    
                ))
                    
               
                console.log(JSON.parse(orderInvoice.orderItems[0].order_items))
                
                setItemCount(orderItemCount)
                handleInvoiceShow();

            } else if(orderInvoice.orderItems && orderType === 3){

                if(orderInvoice.orderItems[0]){
                   const url = orderInvoice.orderItems[0].pdf_url; 
                    const windowName = 'newWindow'; 
                    const windowFeatures = 'width=400,height=300';

                    window.open(url, windowName, windowFeatures); 
                }
                
            } 
            
        }

    }, [dispatch, error, orderType, enqueueSnackbar, loading, orderInvoice])

    return(
        orderType === 6 ?
            <Modal size="xl" show={invoiceShow} onHide={handleInvoiceClose} centered={true} className="popup-order-modal" backdropClassName="dashboard-backdrop">
                {orderInvoice && orderInvoice.status === 1 &&
                    <>
                        <Modal.Header className="order-invoice-head" closeButton>
                            <div className="text-center w-full">
                                <Button type="button" className="print_invoice_btn" onClick={() => { handlePrint(null, () => contentToPrint.current);}}>Print</Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="relative" ref={contentToPrint}>
                            {orderInvoice.orderItems.map((item, i) => (
                                <pre key={i}>
                                    {item.html_data}
                                </pre>
                            ))}
                        </Modal.Body>
                    </>
                }
            </Modal>
        : orderInvoice && orderInvoice.show_invoice === 1 ?
       
            <Modal size="xl" show={invoiceShow} onHide={handleInvoiceClose} centered={true} className="popup-order-modal" backdropClassName="dashboard-backdrop">

                {orderInvoice && orderInvoice.status === 1 &&
                <>
                    <Modal.Header className="order-invoice-head" closeButton>
                        <div className="text-center w-full">
                            <Button type="button" className="print_invoice_btn" onClick={() => { handlePrint(null, () => contentToPrint.current);}}>Print</Button>
                        </div>
                    </Modal.Header>

                    <Modal.Body className="relative" ref={contentToPrint}>
                        <table style={{width: "100%"}} cellPadding={'8px'}>
                            <tbody>
                                
                                <tr>
                                    <td colSpan={3}>
                                        <div className="table-flex" style={{position: 'relative'}}>
                                            <strong  style={{position: 'absolute'}}>Order Number: {orderInvoice.orderdetails.order_id}</strong>
                                            <div style={{flex: 1}}>
                                            <img src={orderInvoice.header_image} alt="Order" width={"150px"} style={{margin: "auto"}}/>
                                            </div>
                                        </div>
                                    </td>
                                </tr> 
                                <tr>
                                    <td colSpan={3} style={{backgroundColor: "#f1f1f1", textAlign: "center"}}>You will need to deliver</td>
                                </tr>
                                <tr style={{borderBottom: "2px solid black"}}>
                                    <td style={{width: "30%"}}>Customer</td>
                                    <td style={{width: "30%"}}>Order Size</td>
                                    <td style={{width: "40%"}}>Order time</td>
                                </tr>
                                <tr>
                                    <td ><strong>{orderInvoice.orderdetails.delivery_firstname} {orderInvoice.orderdetails.delivery_phone}</strong></td>
                                    <td ><strong>{itemCount} {itemCount > 1 ? 'items' : 'item' }</strong></td>
                                    <td >{moment(orderInvoice.orderdetails.date_added).format("lll")}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Delivery Instructions: <strong>{orderInvoice.orderdetails.order_notes}</strong></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Payment: <strong>Pre-Paid</strong></td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={{width: "100%"}} cellPadding={'8px'}>
                            <tbody>
                                <tr style={{borderBottom: "2px solid black"}}>
                                    <td style={{width: "10%"}}>Qty.</td>
                                    <td style={{width: "60%"}}>Item</td>
                                    <td style={{width: "15%", textAlign: "right"}}>Price</td>
                                    <td style={{width: "15%", textAlign: "right"}}>Subtotal</td>
                                </tr>

                                {orderInvoice.orderItems.map((item) => (
                                    JSON.parse(item.order_items).map((order,i) => (
                                        <tr key={i}>
                                            <td style={{verticalAlign: 'top'}}><strong>{order.quantity}</strong></td>
                                            <td>
                                                <strong>{order.name}</strong>
                                                {/* Special Instructions */}
                                                {order.special_instructions && <p style={{margin: '0'}}>{order.special_instructions}</p> }

                                                {/* Line options */}
                                                {order.line_options && order.line_options.length >= 1 && order.line_options.map((line,i) => (
                                                    <>
                                                    <p key={i} style={{margin: '0'}}>{line.name}</p>
                                                    {line.options && line.options.length >= 1 && line.options.map((options,i) => (
                                                        <p key={i} style={{margin: '0', fontStyle: 'italic'}}>{options.title}</p>
                                                    ))}
                                                    </>
                                                ))}
                                            </td>
                                            <td style={{textAlign: "right", verticalAlign: 'top'}}>
                                                {orderType === 4 ? 
                                                    <strong>${order.merchant_total / 100}</strong>
                                                :
                                                    <strong>${order.total}</strong>
                                                }
                                                
                                            </td>
                                            <td style={{textAlign: "right", verticalAlign: 'top'}}>
                                                {orderType === 4 ? 
                                                    <strong>${order.total / 100}</strong>
                                                :
                                                    <strong>${order.quantity * order.total}</strong>
                                                }
                                                
                                            </td>
                                        </tr>
                                    ))
                                ))}

                                <tr>
                                    <td colSpan={4} style={{backgroundColor: "#f1f1f1", textAlign: "center"}}>End of Order</td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{textAlign: "right"}}><strong>Subtotal</strong></td>
                                    <td style={{textAlign: "right"}}><strong>${orderInvoice.orderdetails.order_subtotal}</strong></td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{textAlign: "right"}}><strong>Tax</strong></td>
                                    <td style={{textAlign: "right"}}><strong>${orderInvoice.orderdetails.delivery_tax}</strong></td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{textAlign: "right"}}><strong>Tip Amount</strong></td>
                                    <td style={{textAlign: "right"}}><strong>${orderInvoice.orderdetails.order_tip}</strong></td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{textAlign: "right"}}><strong>Delivery Fee</strong></td>
                                    <td style={{textAlign: "right"}}><strong>${orderInvoice.orderdetails.delivery_fee}</strong></td>
                                </tr>

                                <tr>
                                    <td colSpan={3} style={{textAlign: "right"}}><strong>Total</strong></td>
                                    <td style={{textAlign: "right"}}><strong>${orderInvoice.orderdetails.order_total}</strong></td>
                                </tr>

                            </tbody>
                        </table>


                    </Modal.Body>
                </>
                }

            </Modal> 
        :
            null
    )
}

export default OrderInvoice