import MetaData from "../../layout/MetaData"

const RequestDemo = () => {
    return(
        <>
            <MetaData
                title={"Request Demo – ODDS – On Demand Delivery Solution"}
            />

            <section className="request-demo-section relative overflow-hidden">
                <div className="max-w-screen-1xl mx-auto pt-50 md:pt-20 pb-0 flex flex-col text-center text-primary-text relative">
                    <div className="w-full p-2.5">
                        <h1 className="text-5xl md:text-[56px] font-bold mb-20px">Request Demo</h1>
                        <h5 className="leading-relaxed text-22">Wanting to streamline your restaurant delivery needs in the United States and Canada?
                            <br/>
                        Just choose your time slot for a demonstration.</h5>
                    </div>
                </div>
            </section>

            <section className="request-demo-iframe relative overflow-hidden">
                <div className="max-w-screen-1xl mx-auto pb-50 lg:pb-0">
                    <div className="w-full h-750 p-2.5">
                        <iframe src="https://calendly.com/buyingcom/on-demand-delivery-solution-demo-clone?embed_domain=oddsdrive.com&embed_type=Inline" width="100%" height="100%" frameBorder="0" title="Select a Date & Time - Calendly"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestDemo