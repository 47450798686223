import { useEffect, useState } from "react";

const OddsOrders = () => {

    const [tripDate, setTripDate] = useState('');
    const [fsDate, setFsDate] = useState('');
    const [feDate, setFeDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setTripDate(formattedDate);
        setFsDate(formattedDate);
        setFeDate(formattedDate);
    }, []);
    
    
    const handleTripChange = (event) => {
        setTripDate(event.target.value);
    };

    const handleFeChange = (event) => {
        setFeDate(event.target.value);
    };

    const handleFsChange = (event) => {
        setFsDate(event.target.value);
    };

    return(
        <div className="trip-content p-2.5">
            <div className="custom_trips_table_outer block">
                <div className="flex flex-col sm:flex-row items-center justify-around gap-[20px]">
                    <button className="order_today">Today Financials</button>
                    <button className="order_today">Yesterday Financials</button>
                    <button className="order_today">This Week Financials</button>
                    <button className="order_today">Last Week Financials</button>
                </div>
                <div className="hidden sm:flex items-center justify-center gap-[20px] mt-30px">
                    <div>
                        <input type="date" name="fs_trip_date" id="fs_trip_date" className="trip-date" value={tripDate} onChange={handleTripChange}/>
                    </div>
                    <button className="order_today green">Select Date</button>
                    <div className="flex flex-col lg:flex-row items-center gap-2.5">
                        <input type="date" name="fs_date3" id="fs_date3" className="trip-date" value={fsDate} onChange={handleFsChange} />
                        <span>To</span>
                        <input type="date" name="fe_date3" id="fe_date3" className="trip-date" value={feDate} onChange={handleFeChange} />
                    </div>
                    <button className="order_today green">Download Phone #'s</button>
                </div>
            </div>
        </div>
    )
}

export default OddsOrders