import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import ghostRest from '../../../../assests/images/business/ghost_rest.jpg';
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_BUSINESS_ACH_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { clearErrors, detailBusinessUser, updateBusinessProfileAch } from "../../../../store/actions/businessActions/businessUsers";
import { useSnackbar } from "notistack";

const MoreOrders = () =>{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);

    const { errorAch, isAchUpdated } = useSelector((state) => state.businessProfile);

    const [hasAch, setHasAch] = useState(false);

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [legalName, setLegalName] = useState('');
    const [dbaName, setDbaName] = useState('');
    const [bankName, setBankName] = useState('');
    const [routingNum, setRoutingNum] = useState('');
    const [accountNum, setAccountNum] = useState('');

    const handleAchSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData();
        formData.set("legal_name", legalName);
        formData.set("aba_name", dbaName);
        formData.set("bank_name", bankName);
        formData.set("aba_routing_number", routingNum);
        formData.set("aba_account_number", accountNum);
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);

        dispatch(updateBusinessProfileAch(formData))
    }

    useEffect(() => {

        if(loading === false && businessUserDetail && businessUserDetail.status === 1){

            setObjectId(businessUserDetail.user._id)
            setCustomerId(businessUserDetail.user.customer_id);
            setUserEmail(businessUserDetail.user.email);
            setLegalName(businessUserDetail.businessdata.legal_name ? businessUserDetail.businessdata.legal_name : '');
            setDbaName(businessUserDetail.businessdata.aba_name ? businessUserDetail.businessdata.aba_name : "");
            setBankName(businessUserDetail.businessdata.bank_name ? businessUserDetail.businessdata.bank_name : "");

            if(businessUserDetail.businessdata.aba_routing_number){
                const routing = businessUserDetail.businessdata.aba_routing_number.slice(-4);
                // Append 'XXXX' to the base string
                const newRouting = `XXXX${routing}`;
                
                setRoutingNum(newRouting);
            } else {
                setRoutingNum('')
            }

            if(businessUserDetail.businessdata.aba_account_number){
                const account = businessUserDetail.businessdata.aba_account_number.slice(-4);
                // Append 'XXXX' to the base string
                const newAccount = `XXXX${account}`;
                
                setAccountNum(newAccount);
            } else {
                setAccountNum('')
            }
            

            if(businessUserDetail.businessdata.legal_name && businessUserDetail.businessdata.aba_name && businessUserDetail.businessdata.bank_name && businessUserDetail.businessdata.aba_routing_number && businessUserDetail.businessdata.aba_account_number){
                setHasAch(true);
            } else {
                setHasAch(false);
            }
        }

        // Profile ACH Error
        if (errorAch) {
            enqueueSnackbar(errorAch, { variant: "error" });
            dispatch(clearErrors());
        }

        // Profile ACH updated
        if (isAchUpdated) {

            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: userEmail,
                customer_id: customerId,
            }
            dispatch(detailBusinessUser(userDetails))
            dispatch({ type: UPDATE_BUSINESS_ACH_RESET });
        }
    },[dispatch, loading, isAchUpdated, errorAch, businessUserDetail, objectId, userEmail, customerId, enqueueSnackbar])


    return(
        <div className="more-orders-tab p-2.5">
            <h6 className="pb-2.5 font-semibold">Sign Up for Additional Vertical Orders. No additional tablet is needed, the orders will appear on your Driver Now page in Pink.</h6>
            <Form className="additional-more-orders-form" method="post" onSubmit={handleAchSubmit}>

                <div className="flex flex-col lg:flex-row gap-[20px] mb-15px">
                    <Form.Group as={Col} controlId="legal_name">
                        <Form.Control type="text" name="legal_name" placeholder="Legal Name of Business" value={legalName} onChange={(e) => setLegalName(e.target.value)} required/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="dba_name">
                        <Form.Control type="text" name="dba_name" placeholder="DBA Name of Business" value={dbaName} onChange={(e) => setDbaName(e.target.value)} required />
                    </Form.Group>
                </div>
                
                <div className="flex flex-col lg:flex-row gap-[20px] mb-15px">
                    <Form.Group as={Col} controlId="bank_name">
                        <Form.Control type="text" name="bank_name" placeholder="Bank Name" value={bankName} onChange={(e) => setBankName(e.target.value)} required/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="routing_num">
                        <Form.Control type="text" name="routing_num" placeholder="Aba Routing #" value={routingNum} onChange={(e) => setRoutingNum(e.target.value)} required />
                    </Form.Group>

                    <Form.Group as={Col} controlId="account_num">
                        <Form.Control type="text" name="account_num" placeholder="Account Number" value={accountNum} onChange={(e) => setAccountNum(e.target.value)} required />
                    </Form.Group>

                </div>

                <Form.Group className="text-right">
                    <Button type="submit" className="btn-profile-submit" disabled={hasAch}>Submit</Button>
                </Form.Group>

            </Form>

            <h6 className="pb-2.5 font-semibold">All Ach Payments are made weekly on Monday, Funds usually arrive in 1 - 2 days.</h6>
            <img src={ghostRest} alt="Ghost Rest" className="w-full" />
            <h6 className="py-2.5 font-semibold text-xl">Pros of Vertical/Ghost Restaurants</h6>
            <p>Basically a vertical/ghost is another brand for your restaurant. More places for customers to see food options. Example you have a store running already on Uber GH or DD. You use your current name.</p>

            <p>Example: Great Pizza of Ohio. Now what we do is create a 2nd name for your vertical. Example Great Chicken Wings of Ohio. Not everyone is looking for the same type of food and the searches are different.</p>

            <p>It will just be additional orders that you will receive. The driver will make the pick-up and delivery automatically.</p>
            
            <p>Verticals will be displayed on Google Shopping. Doordash Store Fronts, Bing Shopping, Chownow, Uber, Ehungry, Menufy, Caviar, and Grubhub. All Verticals will be set for 12 miles and all marketing is paid by the blockchain for remarketing.</p>
            
            <p>The average vertical that we create produces 5.7 orders per day per vertical.</p>
            
            <p>We also do the marketing for you at no cost. We do not charge for the delivery, that is FREE, we only charge like the aggregators do a fee of 22% of the order. All payments are made weekly by ACH on Mondays to your bank account.</p>
            
            <p>Once we make your first Vertical successful we then will build out other verticals for you so that you have basically many verticals running to add additional orders to your overall flow of orders.</p>
            
            <p>There is no fee for the buildout or the tablets. All provided Free of charge. We will use your current menu so that as orders come in you will be familiar with the order being placed. A driver is automatically dispatched for you with complete tracking capability.</p>
            
            <p>Once you have your first vertical running we send you a system at no charge and you are also able to use that system if you need drivers for internal orders. A internal order is someone calling on the phone and asking DO YOU DELIVER. Now you would say (YES) and you can press a button on our system to have a driver make the pick-up for you.</p>
            
            <p>We also provide you your customers info so that if you wanted to remarket to them in the future their data is stored for your access.</p>
        </div>
    )
}

export default MoreOrders