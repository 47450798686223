import MetaData from "../../layout/MetaData"
import logo from '../../assests/images/odds-logo.png';
import rentFreeImg from '../../assests/images/home/rent-free-head.jpg';
import rentIntro from '../../assests/images/home/free-rent.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-solid-svg-icons";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import './RentFree.css';

const RentFree = () => {

    const [businessName, setBusinessName] = useState('');
    const [contactName, setContactName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [monthlyLease, setMonthlyLease] = useState('');
    const [leaseBegin, setLeaseBegin] = useState('');
    const [leaseExpire, setLeaseExpire] = useState('');
    const [qtyDelivered, setQtyDelivered] = useState('');
    const [whatPos, setWhatPos] = useState('');
    const [question, setQuestion] = useState('');
    const [termsValue, setTermsValue] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return(
        <>
            <MetaData title={"Rent Free Program – ODDS – On Demand Delivery Solution"} />

            <section className="rent-free-section relative overflow-hidden bg-[#0009]">
                <div className="w-full mx-auto px-30px py-20 md:py-150 z-10 text-white text-center flex flex-col gap-[20px]">
                    <h1 className="text-center text-[33px] md:text-[50px]">Never pay rent again 
                        <br />when you partner with <img src={logo} alt="Logo" className="w-[250px] invert inline-block" />
                    </h1>
                    <a href="#form-rent" className="inline-block text-primary-white md:text-[22px] font-semibold bg-primary-red mx-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider hover:bg-white hover:text-primary-red">
                    Get started now <FontAwesomeIcon icon={faCircleDown} className="ml-2.5"/></a>
                </div>

                <img
                    src={rentFreeImg}
                    alt='Rent Free'
                    className='absolute top-0 left-0 -z-10 w-full h-full object-cover'
                />
            </section>

            <section className="rent-introduction-section">
                <div className="max-w-screen-1xl mx-auto pt-100px py-50 flex flex-col gap-[20px]">
                    <div className="w-full px-2.5">
                        <h2 className="text-[40px] md:text-[56px] text-center">Introducing our <br /><strong>NEW Free-Rent Program</strong></h2>
                    </div>
                    <div className="flex flex-col lg:flex-row py-2.5">
                        <div className="w-full lg:w-1/2 px-2.5">
                            <p className="text-xl">It's simple. When you transition your third-party aggregator services like Grubhub and DoorDash to <strong>self-delivery with ODDS,</strong> you'll unlock a range of benefits, including the extraordinary advantage of <u>rent-free operations.</u></p>
                            <p className="text-xl">Every month, we'll cover your store's lease payments for as long as you remain enrolled in the program.</p>
                            <p className="text-xl">In addition, qualifying participants receive:</p>
                            <ul className="list-disc text-xl">
                                <li>a massive 50% discount accross all aggregators</li>
                                <li>a $5.00 rebate on every single order</li>
                                <li>an expansive 12-mile delivery range which doubles your orders immediately</li>
                            </ul>
                        </div>
                        <div className="w-full lg:w-1/2 px-2.5">
                            <img src={rentIntro} alt="Rent Introducing" className="object-cover mx-auto rounded-[100%] size-[300px] md:size-[400px]" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="rent-form-section" id="form-rent">
                <div className="max-w-screen-1xl mx-auto py-50 flex flex-col gap-[20px] px-2.5">
                    <h3 className="text-center text-[43px] font-semibold">Ready to Get Started?</h3>
                    <div className="text-center">
                        <p className="text-xl">Complete the form below to initiate the approval process for participation in our Rent-Free Program. Should you have any questions, feel free to reach out to us at (833) 928-9464.</p>
                        <p className="text-xl">We look forward to partnering with you to optimize and save on all of your delivery needs!</p>
                    </div>

                    <div className="mx-auto w-full md:w-3/5 ">
                        <Form className="rent-free-form form pb-15px" action="post" onSubmit={handleSubmit}>
                            <Form.Group className="mb-15px">
                                <p className="uppercase"><strong>Business Details</strong></p>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="business_name">
                                <Form.Control type="text" name="business_name" placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="contact_name">
                                <Form.Control type="text" name="contact_name" placeholder="Contact Name" value={contactName} onChange={(e) => setContactName(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="address">
                                <Form.Control type="text" name="address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="phone">
                                <Form.Control type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required placeholder="Phone"/>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="email">
                                <Form.Control type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email"/>
                            </Form.Group>

                            <Form.Group className="mb-15px">
                                <p className="uppercase"><strong>Lease Details</strong></p>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="monthly_lease">
                                <Form.Control type="number" name="monthly_lease" value={monthlyLease} onChange={(e) => setMonthlyLease(e.target.value)} required placeholder="Your Monthly Lease Payment For Business"/>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="lease_begin">
                                <Form.Control type="date" name="lease_begin" value={leaseBegin} onChange={(e) => setLeaseBegin(e.target.value)} required placeholder="When Did Your Lease Begin"/>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="lease_expire">
                                <Form.Control type="date" name="lease_expire" value={leaseExpire} onChange={(e) => setLeaseExpire(e.target.value)} required placeholder="When Does Your Lease Expire"/>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="qty_delivered">
                                <Form.Control type="number" name="qty_delivered" value={qtyDelivered} onChange={(e) => setQtyDelivered(e.target.value)} required placeholder="Total Qty Delivery Orders Weekly"/>
                            </Form.Group>

                            <Form.Group className="mb-15px flex gap-[20px]">
                                <Form.Check
                                    label="POS"
                                    name="checktype"
                                    type="checkbox"
                                    id={'checktype-1'}
                                    required
                                />
                                <Form.Check
                                    label="Tablet"
                                    name="checktype"
                                    type="checkbox"
                                    id={'checktype-2'}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="what_pos">
                                <Form.Control type="text" name="what_pos" value={whatPos} onChange={(e) => setWhatPos(e.target.value)} placeholder="If POS, What POS Are You Using"/>
                            </Form.Group>

                            <Form.Group className="mb-15px" controlId="question">
                                <Form.Control as="textarea" rows={3} name="question" value={question} onChange={(e) => setQuestion(e.target.value)} placeholder="Any Questions"/>
                            </Form.Group>

                            <Form.Group className="mb-15px">
                                <Form.Check
                                    label="Please check the box for permission for us to contact you with your results by email on your application and to send you additional information on new programs and driving options we offer. Thank you for your consent."
                                    name="terms"
                                    type="checkbox"
                                    id={'terms'}
                                    required
                                    value={termsValue} 
                                    onChange={() => setTermsValue(!termsValue)}
                                />
                            </Form.Group>

                            <Form.Group className="mt-20px text-center">
                                <Button type="submit" className="btn-submit mt-10">Submit</Button>
                            </Form.Group>

                        </Form>
                    </div>
                   
                </div>
            </section>
        </>
    )
}

export default RentFree