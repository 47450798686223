import axios from "axios";
import { ADD_ADS_IMAGE_FAIL, ADD_ADS_IMAGE_REQUEST, ADD_ADS_IMAGE_SUCCESS, CLEAR_ERRORS, CREATE_ADS_FAIL, CREATE_ADS_REQUEST, CREATE_ADS_SUCCESS, DELETE_ADS_IMAGE_FAIL, DELETE_ADS_IMAGE_REQUEST, DELETE_ADS_IMAGE_SUCCESS, DELETE_QUEUE_LIST_FAIL, DELETE_QUEUE_LIST_REQUEST, DELETE_QUEUE_LIST_SUCCESS, GET_IMAGE_LIST_FAIL, GET_IMAGE_LIST_REQUEST, GET_IMAGE_LIST_SUCCESS, GET_PHONE_COUNT_FAIL, GET_PHONE_COUNT_REQUEST, GET_PHONE_COUNT_SUCCESS, GET_QUEUE_LIST_FAIL, GET_QUEUE_LIST_REQUEST, GET_QUEUE_LIST_SUCCESS, SEND_ADS_SMS_FAIL, SEND_ADS_SMS_REQUEST, SEND_ADS_SMS_SUCCESS } from "../../types/businessTypes/MarketingAds";

// Get Customer Phone count
export const getCustomerPhoneCount = (countDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_PHONE_COUNT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/getcustomerphonecountbyid`,
            countDate,
            config
        );

        dispatch({
            type: GET_PHONE_COUNT_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: GET_PHONE_COUNT_FAIL,
            payload: error.response.data,
        });
    }
};


// Upload Ads Image
export const uploadAdsImage = (addData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_ADS_IMAGE_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/uploadadsimage`,
            addData,
            config
        );

        dispatch({
            type: ADD_ADS_IMAGE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: ADD_ADS_IMAGE_FAIL,
            payload: error
        });
    }
};

// Get Ads Images List
export const getAdsImagesList = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_IMAGE_LIST_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getAdsList/${id}`,
            config
        );

        dispatch({
            type: GET_IMAGE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: GET_IMAGE_LIST_FAIL,
            payload: error.response.data,
        });
    }
};

// Delete Ads Image
export const deleteAdsImage = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_ADS_IMAGE_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_URL}/deleteads/${id}`,
            config
        );

        dispatch({
            type: DELETE_ADS_IMAGE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: DELETE_ADS_IMAGE_FAIL,
            payload: error.response.data.error,
        });
    }
}

// Create Ads
export const createAdsRequest = (addData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_ADS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/createads`,
            addData,
            config
        );

        dispatch({
            type: CREATE_ADS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CREATE_ADS_FAIL,
            payload: error.response.data,
        });
    }
};

// Create Ads SMS
export const sendAdsSMS = (smsData) => async (dispatch) => {
    try {
        dispatch({ type: SEND_ADS_SMS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/sendtestadsms`,
            smsData,
            config
        );

        dispatch({
            type: SEND_ADS_SMS_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: SEND_ADS_SMS_FAIL,
            payload: error.response.data.error,
        });
    }
};

// Get SMS Queue List
export const getSmsQueueList = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_QUEUE_LIST_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getSmsQueueList/${id}`,
            config
        );

        dispatch({
            type: GET_QUEUE_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: GET_QUEUE_LIST_FAIL,
            payload: error.response.data,
        });
    }
};

// Delete Queue List
export const deleteQueueList = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_QUEUE_LIST_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            },
        }

        const { data } = await axios.delete(
            `${process.env.REACT_APP_API_URL}/deletesmsqueue/${id}`,
            config
        );

        dispatch({
            type: DELETE_QUEUE_LIST_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: DELETE_QUEUE_LIST_FAIL,
            payload: error.response.data.error,
        });
    }
}

// Clear All Errors
export const clearAdsErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}