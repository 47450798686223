import scaleImg from '../../../assests/images/home/single-map-radius.webp';

const DoubleScale = () => {
    return(
        <section className="home-double-scale" id="features">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-2/5 pl-2.5 md:pl-20px py-15px md:py-0 pr-2.5 md:pr-0">
                    <img
                        src={scaleImg}
                        alt='Double Your Sales!'
                        className='w-full'
                    />
                </div>

                <div className="w-full md:w-3/5 py-30px px-2.5 md:px-50 flex flex-col gap-[20px]">
                    <h3 className='text-4xl font-semibold'>Double Your Sales!</h3>
                    <p className='text-xl mb-0 text-primary-text md:mr-30px'>Expand your business delivery range to 20 miles which means your business will have a broader clientele, which expands your sales immediately!</p>
                    <a href="#connected" className="uppercase text-white text-base font-semibold bg-primary-blue mr-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider border-2 border-primary-blue hover:bg-transparent hover:text-primary-blue">Learn More</a>
                </div>

            </div>
        </section>
    )
}

export default DoubleScale