import testimonialImg from '../../../assests/images/home/people-working-call-center-1.jpg';
import testimonial1 from '../../../assests/images/home/testimonial1.jpg';
import testimonial2 from '../../../assests/images/home/testimonial-2.jpg';
import testimonial3 from '../../../assests/images/home/testimonial-3.jpg';

const Testimonials = () => {

    const testimonialsList = [
        {
            content: "We used to use the delivery services provided by the big food aggregator platforms, since switching to ODDS for our delivery needs we are not only more in control of our deliveries but we have also drastically increased our sales revenue.",
            name: "Samantha Jane",
            job: "Jane's Place",
            image: testimonial1,
        },
        {
            content: "Registering was a no brainer, there was absolutely no risk as there are no lock in contracts, so I thought why not and I was right. In just over 30 days our sales doubled and we saved thousands per month in delivery fees. I would recommend it to any restaurant!",
            name: "Jake Warren",
            job: "Pizza Time",
            image: testimonial2,
        },
        {
            content: "I love the seamless integration with all of the services that we are already using. That was a huge selling feature for us. It all just simply works so well together.",
            name: "Kim Smith",
            job: "Park Lounge",
            image: testimonial3,
        }
    ]

    return(
        <section className="home-testimonials relative oerflow-hidden bg-[#00000080]" id="integrate">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 items-center z-10">
                <div className='w-full px-2.5 text-center mb-20px'>
                    <h6 className='text-primary-green uppercase font-semibold tracking-[1px] mb-20px'>Testimonials</h6>
                    <h3 className='text-4xl md:text-[46px] font-semibold text-white mb-15px'> What clients are saying about ODDS</h3>
                </div>
                <div className='w-full px-2.5 flex flex-col md:flex-row gap-[20px] md:gap-2.5'>
                    {testimonialsList.map((item,i) => (
                        <div className='w-full p-11 lg:m-6 bg-white rounded-xl' key={i}>
                            <p className='text-lg mb-20px'>"{item.content}"</p>
                            <div className='flex items-center'>
                                <img src={item.image} alt={item.name} className='size-[60px] rounded-full mr-15px' />
                                <div className='testimonial-content'>
                                    <h6 className='mb-0 font-semibold'>{item.name}</h6>
                                    <p className='mb-0'>{item.job}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <img
                src={testimonialImg}
                alt='Testimonial'
                className='absolute top-0 left-0 -z-10 w-full h-full object-cover'
            />
        </section>
    )
}

export default Testimonials