import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import aboutImg from '../../assests/images/about/about-us.png';
import './AboutUs.css';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import shape5 from '../../assests/images/refer/shape5.png';
import shape1 from '../../assests/images/about/shape1.png';
import shape2 from '../../assests/images/about/shape2.png';
import MetaData from '../../layout/MetaData';
import TeamSection from './TeamSection/TeamSection';
import GallerySection from './GallerySection/GallerySection';

const AboutUs = () => {
    return(
        <>
            <MetaData
                title={"About Us - ODDS - On Demand Delivery Solution"}
            />

            <section className="about-content-section bg-primary-bgForms relative overflow-hidden z-10">
                <div className="max-w-screen-1xl mx-auto pb-50 px-2.5">
                    <div className="w-full max-w-[470px] mx-auto">
                        <img src={aboutImg} alt='About Us' className='w-full' />
                    </div>
                    <div className="w-full max-w-[700px] mx-auto mb-[20px] text-center">
                        <h1 className='font-semibold text-primary-text md:text-[56px] text-center'>Who are we?</h1>
                        <div className='line-divider'></div>
                        <p className='text-lg mb-0 text-left'>At ODDS, we are at the forefront of innovation in technology, ushering logistics and e-commerce into a new era through solutions tailored for both consumers and businesses. Our flagship product, the "On Demand Delivery Solution," revolutionizes the last-mile delivery landscape for restaurants, prioritizing convenience, ease of use, safety, and cost-effectiveness.</p>
                        <p className='text-lg mb-0 text-left'>With over a decade of operational excellence, ODDS is proud to call Mount Arlington, New Jersey, our corporate headquarters. Our reach extends nationally across the United States and Canada, where our On Demand Delivery Solution has been embraced. Through an expansive network of dedicated delivery drivers, we ensure that no order remains undelivered, covering every town and city throughout the USA and Canada.</p>

                        <p className='mt-20px text-left'>In practical terms, our mission is to empower businesses by:</p>
                        <ul className='text-left p-0'>
                            <li className='flex items-center gap-[20px] pb-1.5 mt-1.5'><FontAwesomeIcon icon={faCheckCircle} className='text-primary-blue' /> Providing On-Demand Delivery Drivers: We connect restaurants with a reliable fleet of on-demand delivery drivers, ensuring swift and efficient delivery of orders to customers.</li>
                            <li className='flex items-center gap-[20px] pb-1.5 mt-1.5'><FontAwesomeIcon icon={faCheckCircle} className='text-primary-blue' /> Cost Savings on Deliveries: Through our innovative solutions, we help restaurants optimize their delivery operations, reducing costs while maintaining top-notch service quality.</li>
                            <li className='flex items-center gap-[20px] pb-1.5 mt-1.5'><FontAwesomeIcon icon={faCheckCircle} className='text-primary-blue' /> Driving Sales Growth: ODDS is committed to helping restaurants grow their sales by expanding their reach and providing access to a broader customer base.</li>
                            <li className='flex items-center gap-[20px] pb-1.5 mt-1.5'><FontAwesomeIcon icon={faCheckCircle} className='text-primary-blue' /> Comprehensive Marketing Services: In addition to delivery support, we offer comprehensive marketing services to enhance a restaurant's visibility and customer engagement.</li>
                        </ul>
                        <p className='text-left'>Our dedication to transforming the delivery industry and supporting restaurants is at the heart of what we do. Welcome to ODDS, where we're shaping the future of delivery, one meal at a time.</p>
                    </div>

                    <div className="w-full max-w-[700px] mx-auto text-center">
                        <h2 className='text-primary-text md:text-[35px] text-center'>Our mission</h2>
                        <div className='line-divider'></div>
                        <p className='mb-0 text-left'>At ODDS, we are driven by a powerful mission - to revolutionize the logistics and e-commerce landscape using cutting-edge technology. Our aim is to level the playing field, providing small to medium enterprises (SMEs) with the opportunity to stand shoulder-to-shoulder with industry giants. <b>How We Achieve Our Mission:</b> We harness the potential of emerging technology to introduce transparency, security, and efficiency into the world of logistics and e-commerce. By doing so, we empower SMEs to compete on a global scale. <b>What Drives Us:</b> The belief that every business, regardless of its size, should have access to the tools and resources needed to thrive in the digital age. Our passion lies in enabling SMEs to not just survive but flourish in an increasingly competitive marketplace. <b>Our Commitment:</b> We are committed to reshaping the future of commerce, fostering innovation, and creating opportunities that empower SMEs to reach new heights. Through our dedication and relentless pursuit of excellence, we are redefining the possibilities for businesses of all sizes.<br />
                        Join us in our mission to disrupt the status quo, empower SMEs, and redefine the future of logistics and e-commerce. Together, we'll build a world where every business has the chance to achieve greatness.</p>
                    </div>
                </div>

                <img src={shape5} alt="Shape5" className="hidden md:block max-w-[102px] absolute top-[180px] left-[14%] -z-10" />
                <img src={shape1} alt="Shape1" className="hidden md:block max-w-[102px] absolute top-[50%] right-[10%] -z-10" />
                <img src={shape2} alt="Shape2" className="hidden md:block max-w-[182px] absolute top-[50%] left-[20px] -z-10" />
                    
            </section>

            <TeamSection />
            <GallerySection />
        </>
    )
}

export default AboutUs