const HomeTab = () => {
    return(
        <div className="px-15px w-full">
            <iframe 
                src="https://odds-1.gitbook.io/sales" 
                className="documentation-iframe" 
                width="100%" 
                height="1100px" 
                title="Sales Documentation"
            />
        </div>
    )
}

export default HomeTab