import MetaData from "../../layout/MetaData"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import poster from '../../assests/images/home/video-poster-1.webp';
import driverNow from '../../assests/images/help/drivernow-blimpie-subs.jpg';
import videoPoster from '../../assests/images/home/topbar-drivernow.jpg';

const IntegrationHelp = () => {
    return(
        <>
            <MetaData
                title={"Help – ODDS – On Demand Delivery Solution"}
            />

            <section className="integration-help-section relative overflow-hidden">
                <div className="max-w-screen-1xl mx-auto py-50 text-primary-text33">
                    <div className="w-full p-2.5">
                        <p>Odds Help Document</p>
                        <h1 className="text-5xl md:text-50 font-bold mb-20px leading-tight">On-Demand Delivery <br/>with ODDS Drive</h1>
                        <div className="w-full md:w-[70%] py-2.5">
                            <LiteYouTubeEmbed
                                id="_g5X8WBLls8"
                                adNetwork={false}
                                params=""
                                playlist={false}
                                playlistCoverId="_g5X8WBLls8"
                                thumbnail={poster}
                                noCookie={false} 
                            />
                        </div>

                        <div className="py-2.5">
                            <p>With <a href='/' className="no-underline">ODDS Drive</a> you get an all-in-one POS system that consolidates 28 of your current sales aggregators into one streamlined location - and you don't have to change a thing. Sell food and beverage items online, and have them delivered to customers easily with our on-demand fleet of drivers available 24 hours per day. </p>
                            <p>To get started, <a href='/register' className="no-underline">register now and begin delivering with</a> ODDS Drive immediately.</p>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">Pricing</h2>
                            <p>What's great is, ODDS Drive handles all of your deliveries, so there are no delivery fees assessed to your store. Your POS equipment is all <strong>free of charge.</strong> </p>
                            <p>With ODDS Drive you'll receive a delivery radius of up to 20 miles to double your sales, access to our free text marketing program and a 24/7 driver network in all 50 states.</p>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">Setting Up On-Demand Delivery with ODDS Drive</h2>
                            <p>To set up On-Demand Delivery:</p>
                            <ol className="list-decimal mb-20px">
                                <li className="mb-20px">If you have not yet registered, <a href="/register" className="no-underline">register now</a> and create your account. </li>
                                <li className="mb-20px">Once you've registered and set up your account you'll be taken to your delivery dashboard. It looks like this.</li>
                            </ol>
                            <img src={driverNow} alt="drivernow-blimpie" className="w-full max-w-600 mb-20px" />
                            <ol className="list-decimal mb-20px">
                                <li value={3} className="mb-20px">Registering triggers a member of our team to give you a welcome call within the same business day but you can get started right away prior to this call.</li>
                                <li className="mb-20px">On the main dashboard you will see the option to go ahead and schedule a delivery. But before you do, make sure to scroll to the bottom of the dashboard and click on “My Profile / Payments” to set up all of your payment details. You can choose to pay for deliveries with a credit card or ACH. </li>
                                <li className="mb-20px">Below the payment details you will see a place to input your username and passwords for your external aggregator orders, ie. GrubHub, DoorDash and Uber. GrubHub and Doordash should get setup within minutes while Uber will require someone from our team to give you a call. </li>
                                <li className="mb-20px">Once you've input your payment info and aggregators if applicable, it's time to schedule a delivery. To do so, fill in all of the necessary details and simply hit the green “Driver Now” button. </li>
                                <li className="mb-20px">A driver request will then be created and shown on the main dashboard. </li>
                                <li className="mb-20px">If you are placing an external order with an outside aggregator, there is nothing extra for you to do, it will simply appear on the main dashboard once our team has set these up for you with the information you submitted in step #5.</li>
                                <li className="mb-20px">If you have any questions, simply send us a message in the live chat box on the right side of your dashboard.</li>
                                <li className="mb-20px">When a member of our team gives you a call, we will organize for you to receive your free of charge computer from which to operate your POS system.</li>
                                <li className="mb-20px">If you'd like visual step-by-step instructions in setting up on-demand delivery, check out the tutorial video below and at the top of this help article.</li>
                            </ol>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">Tutorial</h2>
                            
                            <div className="w-full md:w-[70%] py-2.5">
                                <video
                                    src="https://oddsdrive.com/wp-content/uploads/2024/02/CORRECTED-VIDEOS.mp4"
                                    poster={videoPoster}
                                    controls
                                    className="video-popup"
                                />
                            </div>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">Delivery Settings</h2>
                            
                            <p>Here are some of the delivery settings that you will be able to control within your dashboard.</p>
                            <ul className="list-disc">
                                <li>Ability to control and pause all aggregators with one button</li>
                                <li>Delay driver pick up time if needed</li>
                                <li>Catering drivers on staff at all times</li>
                                <li>Tracking of driver locations</li>
                                <li>Live chat capability and access to admins 24 hours a day</li>
                            </ul>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">Workflow Settings</h2>
                            
                            <p>Here are some of the workflow settings that you will be able to control within your dashboard.</p>
                            <ul className="list-disc">
                                <li>Mark orders as ready</li>
                                <li>Print order tickets</li>
                                <li>Full tracking of driver location by restaurant</li>
                                <li>Customize delivery from start to finish</li>
                                <li>Automatic refunds tied to your aggregators</li>
                            </ul>
                        </div>

                        <div className="mt-30px py-2.5">
                            <h2 className="font-semibold text-primary-text mb-20px">FAQ</h2>
                            
                            <p>To learn more, <a href='/request-demo' className="no-underline">setup a demo with our team.</a></p>
                            
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default IntegrationHelp