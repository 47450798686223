import { ADD_ADS_IMAGE_FAIL, ADD_ADS_IMAGE_REQUEST, ADD_ADS_IMAGE_SUCCESS, CLEAR_ERRORS, CREATE_ADS_FAIL, CREATE_ADS_REQUEST, CREATE_ADS_SUCCESS, DELETE_ADS_IMAGE_FAIL, DELETE_ADS_IMAGE_REQUEST, DELETE_ADS_IMAGE_RESET, DELETE_ADS_IMAGE_SUCCESS, DELETE_QUEUE_LIST_FAIL, DELETE_QUEUE_LIST_REQUEST, DELETE_QUEUE_LIST_RESET, DELETE_QUEUE_LIST_SUCCESS, GET_IMAGE_LIST_FAIL, GET_IMAGE_LIST_REQUEST, GET_IMAGE_LIST_SUCCESS, GET_PHONE_COUNT_FAIL, GET_PHONE_COUNT_REQUEST, GET_PHONE_COUNT_SUCCESS, GET_QUEUE_LIST_FAIL, GET_QUEUE_LIST_REQUEST, GET_QUEUE_LIST_SUCCESS, SEND_ADS_SMS_FAIL, SEND_ADS_SMS_REQUEST, SEND_ADS_SMS_RESET, SEND_ADS_SMS_SUCCESS } from "../../types/businessTypes/MarketingAds";

// get customer phone count reducer
export const getPhoneCountReducer = (state = { phoneCount: [] }, { type, payload }) => {
    switch (type) {
        case GET_PHONE_COUNT_REQUEST:
            return {
                loading: true,
            };
        case GET_PHONE_COUNT_SUCCESS:
            return {
                loading: false,
                phoneCount: payload,
            };
        case GET_PHONE_COUNT_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};


// upload Ads image reducer
export const uploadAdsImageReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case ADD_ADS_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADD_ADS_IMAGE_SUCCESS:
            return {
                loading: false,
                success: payload.message,
                adsData: payload,
            };
        case ADD_ADS_IMAGE_FAIL:
            return {
                loading: false,
                error: payload,
            };
        // Delete Ads Image
        case DELETE_ADS_IMAGE_REQUEST:
            return {
                ...state,
                loadingDel: true,
            };
        case DELETE_ADS_IMAGE_SUCCESS:
            return {
                ...state,
                loadingDel: false,
                isDeleted: payload,
            };
        case DELETE_ADS_IMAGE_FAIL:
            return {
                ...state,
                loadingDel: false,
                error: payload,
            };
        case DELETE_ADS_IMAGE_RESET:
            return {
                ...state,
                isDeleted: false,
            };
        // create ads
        case CREATE_ADS_REQUEST:
            return {
                ...state,
                loadingAds: true,
            };
        case CREATE_ADS_SUCCESS:
            return {
                loadingAds: false,
                successAds: payload.message,
                adsData: payload,
            };
        case CREATE_ADS_FAIL:
            return {
                loadingAds: false,
                errorAds: payload,
            };
        // send ads SMS
        case SEND_ADS_SMS_REQUEST:
            return {
                ...state,
                loadingSms: true,
            };
        case SEND_ADS_SMS_SUCCESS:
            return {
                loadingSms: false,
                successSms: payload.message,
                adsData: payload,
            };
        case SEND_ADS_SMS_FAIL:
            return {
                loadingAds: false,
                errorSms: payload,
            };
        case SEND_ADS_SMS_RESET:
            return {
                ...state,
                loadingAds: false,
                errorSms: null,
                successSms: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                success: null,
                errorAds: null,
                successAds: null,
                errorQueue: null,
            };
        // Delete Queue List
        case DELETE_QUEUE_LIST_REQUEST:
            return {
                ...state,
                loadingDelQueue: true,
            };
        case DELETE_QUEUE_LIST_SUCCESS:
            return {
                ...state,
                loadingDelQueue: false,
                isDeletedQueue: payload,
            };
        case DELETE_QUEUE_LIST_FAIL:
            return {
                ...state,
                isDeletedQueue: false,
                errorQueue: payload,
            };
        case DELETE_QUEUE_LIST_RESET:
            return {
                ...state,
                isDeletedQueue: false,
            };
        default:
            return state;
    }
};

// get Ads Images List reducer
export const getAdsImagesReducer = (state = { adsImageList: [] }, { type, payload }) => {
    switch (type) {
        case GET_IMAGE_LIST_REQUEST:
            return {
                loading: true,
            };
        case GET_IMAGE_LIST_SUCCESS:
            return {
                loading: false,
                adsImageList: payload,
            };
        case GET_IMAGE_LIST_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// get SMS Queue List reducer
export const getSmsQueueReducer = (state = { smsQueueList: [] }, { type, payload }) => {
    switch (type) {
        case GET_QUEUE_LIST_REQUEST:
            return {
                loading: true,
            };
        case GET_QUEUE_LIST_SUCCESS:
            return {
                loading: false,
                smsQueueList: payload,
            };
        case GET_QUEUE_LIST_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};