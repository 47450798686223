import MetaData from "../../../layout/MetaData"
import './ConsumerStyle.css';
import { TabProvider } from "../../business/Dashboard/TabContext"
import ConsumerDashboardHeader from "./ConsumerDashboardHeader"
import DriverNowConsumer from "./DriverNowConsumer/DriverNowConsumer"
import FooterButtons from "./FooterButtons/FooterButtons"

const ConsumerDashboard = () => {
    return(
        <>
            <MetaData
                title={"Consumer Dashbaord"}
            />
            
            <section className="consumer-dashboard business-dashboard sales-pages">
                {/* header */}
                <ConsumerDashboardHeader />

                {/* main section */}
                <div className="max-w-screen-2xl mx-auto px-12px">
                    <div className="main-inner p-20px">
                        <TabProvider>

                            {/* Driver Now Section */}
                            <DriverNowConsumer />

                            {/* Footer Section */}
                            <FooterButtons />
                        </TabProvider>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default ConsumerDashboard