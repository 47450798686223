import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapImage from '../../../assests/images/home/map.png';

const DriverAvailability = () => {

    const listItem = [
        "Simply press a button and a driver will pick up your order within 18-20 minutes, day or night.",
        "Live driver tracking from the moment your delivery has been accepted.",
        "Communicate with our support team 24/7 quickly and easily.",
        "Drivers available in every ZIP code in USA and Canada."
    ]

    return(
        <section className="home-driver-availability bg-black text-white" id="connected">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-4/6 py-30px px-2.5 flex flex-col gap-[20px]">
                    <h6 className='text-primary-green uppercase font-semibold tracking-[1px] mb-0'>Driver availability</h6>
                    <h3 className='text-4xl font-semibold'>Drivers available 24/7</h3>
                    <ul className='p-0 m-0'>
                        {listItem.map((item,i) => (
                            <li key={i} className='flex flex-row items-center pb-2.5'>
                                <FontAwesomeIcon icon={faCheck} className='text-2xl text-primary-blue pr-12px mr-20px' />
                                <span className='text-xl'>{item}</span>
                            </li>
                        ))}
                    </ul>
                    <a href="#integrate" className="uppercase text-white text-base font-semibold bg-primary-blue mr-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider border-2 border-primary-blue hover:bg-transparent hover:text-primary-blue">Learn More</a>
                </div>

                <div className="w-full md:w-2/6 pl-2.5 md:pl-20px py-15px md:py-0 pr-2.5 md:pr-0">
                    <img
                        src={mapImage}
                        alt='Driver availability'
                        className='w-full'
                    />
                </div>

            </div>
        </section>
    )
}

export default DriverAvailability