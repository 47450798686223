import { Nav, Tab } from "react-bootstrap"
import './SalesDashboard.css'
import MetaData from "../../../layout/MetaData"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressCard, faDesktop, faFile, faGraduationCap, faHouseChimney, faPlusSquare, faUserLarge } from "@fortawesome/free-solid-svg-icons"
import { emptyBusinessSession } from "../../../store/actions/businessActions/businessUsers"
import { useDispatch, useSelector } from "react-redux"
import HomeTab from "./HomeTab"
import OneOnOneTab from "./OneOnOneTab"
import SalesDemoRequest from "./SalesDemoRequest"
import MyProfileTab from "./MyProfileTab/MyProfileTab"
import AddSalesReport from "./AddSalesReport"
import DeliveryReport from "./Reports/DeliveryReport"
import CommissionReport from "./Reports/CommissionReport"
import { useEffect } from "react"
import { clearErrors, detailSalesUser } from "../../../store/actions/salesActions/salesUsers"

const SalesDashboard = () =>{

    const dispatch = useDispatch();

    const { userDetail } = useSelector((state) => state.userDetails);

    const { error } = useSelector((state) => state.salesUserDetails);

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    useEffect(() => {

        if(userDetail && userDetail.userId){
           
            var userDetails = {
                obj_id: userDetail.userId,
                email: userDetail.userEmail,
                customer_id: userDetail.customerId,
            }
            dispatch(detailSalesUser(userDetails))
        }

        if(error){
            dispatch(emptyBusinessSession())
            dispatch(clearErrors())
        }
        
    }, [dispatch, userDetail, error])

    return(
        <>
            <MetaData
                title={"Sale Person"}
            />

            <section className="business-dashboard sales-pages">
                <Tab.Container id="sales-dashboard-tabs" defaultActiveKey="home">
                    <div className="flex flex-col 992:flex-row min-h-[50vh]">
                        <div className="w-full 992:w-1/5 4xl:w-[16%] bg-[#f8f9fa] 992:bg-black text-white">
                            <Nav className="flex-column sales-nav-left">
                                <Nav.Item>
                                    <Nav.Link eventKey="home"><FontAwesomeIcon icon={faHouseChimney} /> Home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="details"><FontAwesomeIcon icon={faAddressCard} /> My Details</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="one_on_one"><FontAwesomeIcon icon={faGraduationCap} /> 1 on 1 Training</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="sales_demo"><FontAwesomeIcon icon={faDesktop} /> Sales Demo Request</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link><FontAwesomeIcon icon={faFile} /> Reports</Nav.Link>
                                    <div className="nav-dropdown">
                                        <Nav.Link eventKey="delivery_report">Delivery Report</Nav.Link>
                                        <Nav.Link eventKey="commission_report">Commission Report</Nav.Link>
                                    </div>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="add_report"><FontAwesomeIcon icon={faPlusSquare} /> Add Sales Report</Nav.Link>
                                </Nav.Item>

                                <Nav.Item className="logout_menu">
                                    <Nav.Link onClick={handleLogout}><FontAwesomeIcon icon={faUserLarge} /> Logout</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="w-full 992:w-4/5 4xl:w-[84%]">
                            <Tab.Content className="sales-tab-content">
                                {/* home tab */}
                                <Tab.Pane eventKey="home">
                                    <HomeTab />
                                </Tab.Pane>

                                {/* My Details tab */}
                                <Tab.Pane eventKey="details">
                                    <MyProfileTab />
                                </Tab.Pane>

                                {/* One on One tab */}
                                <Tab.Pane eventKey="one_on_one">
                                    <OneOnOneTab />
                                </Tab.Pane>

                                {/* Sales Demo tab */}
                                <Tab.Pane eventKey="sales_demo">
                                    <SalesDemoRequest />
                                </Tab.Pane>

                                {/* Delivery Report tab */}
                                <Tab.Pane eventKey="delivery_report">
                                    <DeliveryReport />
                                </Tab.Pane>

                                {/* Commission Report tab */}
                                <Tab.Pane eventKey="commission_report">
                                    <CommissionReport />
                                </Tab.Pane>

                                {/* Add Sales Report tab */}
                                <Tab.Pane eventKey="add_report">
                                    <AddSalesReport />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </section>
        </>
    )
}

export default SalesDashboard