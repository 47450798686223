import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap"
import ChatSection from "../../../../components/ChatComponent/ChatSection"
import { useEffect, useState } from "react"
import marker from '../../../../assests/images/business/marker.png';
import { usePlacesWidget } from "react-google-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";

const DriverNowConsumer = () =>{

    const { enqueueSnackbar } = useSnackbar();

    const [pickupName, setPickupName] = useState('');
    const [pickupAddress, setPickupAddress] = useState('');
    const [pickupPhone, setPickupPhone] = useState('');
    const [pickupNotes, setPickupNotes] = useState('');
    const [pickupStreet, setPickupStreet] = useState('');
    const [pickupState, setPickupState] = useState('');
    const [pickupCity, setPickupCity] = useState('');
    const [pickupZip, setPickupZip] = useState('');
    const [pickupLat, setPickupLat] = useState('');
    const [pickupLong, setPickupLong] = useState('');

    const [deliveryName, setDeliveryName] = useState('');
    const [deliveryAddress, setDeliverAddress] = useState('');
    const [deliveryPhone, setDeliveryPhone] = useState('');
    const [deliveryNotes, setDeliveryNotes] = useState('');

    const [preOrder, setPreOrder] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(new Date().toISOString().split("T")[0]);
    const [deliveryTime, setDeliveryTime] = useState();
    
    // credit card forms
    // eslint-disable-next-line
    const [userName, setUserName] = useState('');
    const [accountNumber, setAccountNumber] = useState(''); 
    const [ccExpirationMonth, setCcExpirationMonth] = useState(''); 
    const [ccExpirationYear, setCcExpirationYear] = useState('');
    const [ccCvv, setCcCvv] = useState(''); 
    // eslint-disable-next-line
    const [hasCard, setHasCard] = useState(false); 
    const [years, setYears] = useState([]);

    const [showCard, setShowCard] = useState(false);
    const handleCardClose = () => setShowCard(false);
    const handleCardShow = () => setShowCard(true);

    // handle pickup mobile number
    const handlePickupMobile = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setPickupPhone(value);
    }

    // handle delivery mobile number
    const handleDeliveryMobile = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setDeliveryPhone(value);
    }

    // handle pre order change
    const handlePreOrderChange = () => {
        setPreOrder(!preOrder)
    }

    // handle pickup address change
    const handlePickupAddressChange = (e) =>{
        setPickupAddress(e.target.value);
        setPickupStreet('');
        setPickupCity('');
        setPickupState('');
        setPickupZip('');
    }

    const { ref: ref2 } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {

                setPickupAddress(place.formatted_address);

                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setPickupStreet(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setPickupZip(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setPickupCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setPickupState(state)

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setPickupLat(lat);
                setPickupLong(lng);
            }
            
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "" },
        },
    });

    // handle delivery address change
    const handleAddressChange = (e) =>{
        setDeliverAddress(e.target.value);
    }

    const { ref: ref1 } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {

                setDeliverAddress(place.formatted_address);

                // const addressComponents = place.address_components;
            }
            
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "" },
        },
    });

    // handle driver now submit
    const handleSubmit = (e) => {
        e.preventDefault();

        if(pickupAddress === ''){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }

        if(pickupState === ''){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }
        if(pickupCity === '' ){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }
        if(pickupStreet === '' ){
            enqueueSnackbar("Choose Address for delivery from dropdown", { variant: "error" });
            return false;
        }
        if(pickupZip === ''){
            enqueueSnackbar("Choose Complete Address for delivery from dropdown", { variant: "error" });
            return false;
        }

        const formData = new FormData();
        
        formData.set("pickup_name", pickupName);
        formData.set("pickup_phone", pickupPhone);
        formData.set("pickup_address", pickupAddress);
        formData.set("pickup_street", pickupStreet);
        formData.set("pickup_state", pickupState);
        formData.set("pickup_city", pickupCity);
        formData.set("pickup_postcode", pickupZip);
        formData.set("pickup_lat", pickupLat);
        formData.set("pickup_long", pickupLong);
        formData.set("pre_order", preOrder === true ? 1 : 0);
        formData.set("delivery_datetime", preOrder === true ? deliveryDate + " " + deliveryTime : "");
        
    }

    // handle card form reset
    const handleCardReset = () => {
        setAccountNumber("");
        setCcExpirationMonth("");
        setCcExpirationYear("");
        setCcCvv("");
    }

    // credit card form submit
    const handleCardSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set("cc_number", accountNumber);
        formData.set("cc_expiration", ccExpirationMonth+"/"+ccExpirationYear);
        formData.set("cc_cvv", ccCvv);

    }

    useEffect(() => {
        // Year dropdown
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let i = 0; i < 15; i++) {
        yearsArray.push(currentYear + i);
        }
        setYears(yearsArray);
    }, [])

    // get year as 2 digit
    const getTwoDigitYear = (year) => year % 100;

    return(
        <div className="driver-now-section">
            <div className="flex flex-col md:flex-row -mx-12px">
                {/* form column */}
                <div className="w-full md:w-2/3 px-12px">
                    <h4 className="text-[28px] text-center font-bold font-asap mb-20px">FILL IN ADDRESS INFO & PRESS DRIVER NOW</h4>
                    <Form className="form-driver-now" onSubmit={handleSubmit} method="post">
                        <div className="flex flex-col lg:flex-row gap-[20px]">
                            <Form.Group className="w-full lg:w-4/12" controlId="pickup_name">
                                <Form.Label>Pick Up Location</Form.Label>
                                <Form.Control type="text" name="pickup_name" value={pickupName} onChange={(e) => setPickupName(e.target.value)} required placeholder="Enter"/>
                            </Form.Group>

                            <Form.Group className="w-full lg:w-4/12 relative" controlId="pickup_address">
                                <Form.Label>Pick Up Address</Form.Label>
                                <Form.Control ref={ref2} type="text" name="pickup_address" value={pickupAddress} onChange={handlePickupAddressChange} placeholder="Enter a location" required />
                                <img src={marker} className="marker-location" alt="marker" />
                            </Form.Group>

                            <Form.Group className="w-full lg:w-4/12" controlId="pickup_phone">
                                <Form.Label>Pick Up Location Phone#</Form.Label>
                                <Form.Control type="tel" name="pickup_phone" minLength={10} maxLength={10} value={pickupPhone} onChange={handlePickupMobile} required />
                            </Form.Group>
                        </div>

                        <Form.Group className="mt-15px" controlId="pickup_notes">
                            <Form.Label>Special Instructions: Notes For Driver to help with Pick Up. Name or Order # for Pick Up</Form.Label>
                            <Form.Control type="text" name="pickup_notes" value={pickupNotes} onChange={(e) => setPickupNotes(e.target.value)}/>
                        </Form.Group>

                        <div className="flex flex-col lg:flex-row gap-[20px] mt-15px">
                            <Form.Group className="w-full lg:w-4/12" controlId="delivery_name">
                                <Form.Label>Delivery Name</Form.Label>
                                <Form.Control type="text" name="delivery_name" value={deliveryName} onChange={(e) => setDeliveryName(e.target.value)} required placeholder="Enter"/>
                            </Form.Group>

                            <Form.Group className="w-full lg:w-5/12 relative" controlId="delivery_address">
                                <Form.Label>Address for delivery: Choose from dropdown</Form.Label>
                                <Form.Control ref={ref1} type="text" name="delivery_address" value={deliveryAddress} onChange={handleAddressChange} placeholder="Enter a location" required />
                                <img src={marker} className="marker-location" alt="marker" />
                            </Form.Group>

                            <Form.Group className="w-full lg:w-3/12" controlId="delivery_phone">
                                <Form.Label>Delivery Phone#</Form.Label>
                                <Form.Control type="tel" name="delivery_phone" minLength={10} maxLength={10} value={deliveryPhone} onChange={handleDeliveryMobile} required />
                            </Form.Group>
                        </div>

                        <Form.Group className="mt-15px" controlId="pickup_notes">
                            <Form.Label>Special Instructions: Notes For Driver to help with delivery. Name or Apt # Gate Code</Form.Label>
                            <Form.Control type="text" name="pickup_notes" value={deliveryNotes} onChange={(e) => setDeliveryNotes(e.target.value)}/>
                        </Form.Group>

                        <div className="flex flex-col lg:flex-row gap-[20px] mt-25px">
                            <div className="w-full lg:w-3/4">
                                <div className="w-full hidden sm:flex flex-col lg:flex-row -mx-12px switch-label">

                                    {/* Pre order */}
                                    <div className="w-full lg:w-2/12 px-12px">
                                        <label className='flex flex-col cursor-pointer select-none items-start'>
                                            <span className='label flex items-center text-black mb-2.5'>
                                                Pre Order
                                            </span>
                                            <div className='relative'>
                                                <input
                                                    type='checkbox'
                                                    checked={preOrder}
                                                    onChange={handlePreOrderChange}
                                                    className='sr-only'
                                                    name="pre_order"
                                                />
                                                <div
                                                    className={`switch-icon box block h-8 w-14 rounded-full ${
                                                        preOrder ? 'bg-primary-dashBlue' : 'bg-white'
                                                    }`}
                                                ></div>
                                                <div
                                                    className={`switch-move-icon absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full  border-2 transition ${
                                                        preOrder ? 'border-white bg-primary-dashBlue translate-x-full' : 'bg-white border-primary-dashBlue'
                                                    }`}
                                                ></div>
                                            </div>
                                            
                                        </label>
                                    </div>

                                    {/* date pick */}
                                    <div className="w-full lg:w-4/12 px-12px">
                                        <Form.Group controlId="delivery_date">
                                            <Form.Label>Date For Pick Up</Form.Label>
                                            <Form.Control type="date" name="delivery_date" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} disabled={!preOrder} min={new Date().toISOString().split('T')[0]} />
                                        </Form.Group>
                                    </div>

                                    {/* Time For Delivery */}
                                    <div className="w-full lg:w-4/12 px-12px">
                                        <Form.Group controlId="delivery_time">
                                            <Form.Label>Time For Delivery</Form.Label>
                                            <Form.Control type="time" name="delivery_time" value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)} disabled={!preOrder} />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 relative self-baseline mt-auto">
                                {hasCard ? 
                                    <button type="submit" className="quickorder" id="quickorder">Driver Now</button>
                                :
                                    <button type="button" className="quickorder" id="quickorder" onClick={handleCardShow}>Driver Now</button>
                                }
                                
                            </div>
                        </div>

                    </Form>
                </div>

                {/* chat column */}
                <div className="w-full md:w-1/3 px-12px">
                    <ChatSection />
                </div>

            </div>

            {/* Card Modal */}
            <Modal show={showCard} onHide={handleCardClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="mb-0 text-center w-full text-black">Please enter your form of payment.</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <Form className="user-cc-form" method="post" onSubmit={handleCardSubmit}>
                        <Card className="text-center">
                            <Card.Body>
                                <Form.Group className="mb-15px" controlId="user_name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="user_name" placeholder="Enter your name" value={userName} readOnly />
                                </Form.Group>

                                <Form.Label htmlFor="card_number">Credit Card Number</Form.Label>
                                <InputGroup className="mb-15px">
                                    <Form.Control
                                        name="card_number"
                                        aria-describedby="basic-addon2"
                                        value={accountNumber}
                                        onChange={(e) => setAccountNumber(e.target.value)}
                                        required
                                        maxLength={16}
                                    />
                                    <InputGroup.Text id="basic-addon2">
                                        <FontAwesomeIcon icon={faCreditCard} />
                                    </InputGroup.Text>
                                </InputGroup>

                                <div className="flex flex-col sm:flex-row gap-2.5">
                                    <Form.Group controlId="cc_expiration_month" className="w-full">
                                        <Form.Label>Month</Form.Label>
                                        <Form.Select name="cc_expiration_month" value={ccExpirationMonth} onChange={(e) => setCcExpirationMonth(e.target.value)} required>
                                            <option value="">MM</option>
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05">May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId="cc_expiration_year" className="w-full">
                                        <Form.Label>Year</Form.Label>
                                        <Form.Select name="cc_expiration_year" value={ccExpirationYear} onChange={(e) => setCcExpirationYear(e.target.value)} required>
                                            <option value="">YY</option>
                                            {years.map((year) => (
                                                <option key={year} value={getTwoDigitYear(year).toString().padStart(2, '0')}>
                                                {getTwoDigitYear(year).toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group controlId="cc_ccv" className="w-full">
                                        <Form.Label>CVV/CVC</Form.Label>
                                        <Form.Control type="password" name="cc_ccv" placeholder="123" value={ccCvv} onChange={(e) => setCcCvv(e.target.value)} maxLength={4} required />
                                    </Form.Group>

                                </div>

                            </Card.Body>
                            <Card.Footer className="flex gap-2.5 items-center justify-center bg-white">
                                <Button type="submit" className="card-form-btns">Add</Button>
                                <Button type="button" className="card-form-btns" onClick={handleCardReset}>Reset</Button>
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DriverNowConsumer