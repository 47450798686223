import axios from "axios";
import { GET_SCAN_DRIVERS_FAIL, GET_SCAN_DRIVERS_REQUEST, GET_SCAN_DRIVERS_SUCCESS } from "../../types/businessTypes/scanDrivers";

// Get Scan Drivers
export const getScanDrivers = () => async (dispatch) => {
    try {

        dispatch({ type: GET_SCAN_DRIVERS_REQUEST });

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getfakedrivers/`,
            {headers: { 
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                'Content-Type': 'application/json'
            }}
        );
          
        dispatch({
            type: GET_SCAN_DRIVERS_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_SCAN_DRIVERS_FAIL,
            payload: error.response.data.message,
        });
    }

};