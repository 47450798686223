import MetaData from "../../layout/MetaData"

const PageNotFound = () => {
    return(
        <>
            <MetaData title={"Page Not Found – ODDS – On Demand Delivery Solution"} />

            <section className="page-not-found">
                <div className="max-w-screen-1xl mx-auto pt-100px py-50 px-2.5 flex flex-col gap-[20px] text-center">
                    <h3 className="text-6xl font-semibold text-primary-darkBlue pt-1 text-center">Page Not Found</h3>
                    <p className="text-2xl">Oops! That page can't be found.</p>
                    <a href="/" className="inline-block text-primary-white border-2 border-primary-blue font-semibold bg-primary-blue mx-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider hover:bg-white hover:text-primary-blue">Back to home</a>
                </div>
            </section>
        </>
    )
}

export default PageNotFound