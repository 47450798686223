import { CLEAR_ERRORS, DOWNLOAD_PHONE_FAIL, DOWNLOAD_PHONE_REQUEST, DOWNLOAD_PHONE_RESET, DOWNLOAD_PHONE_SUCCESS, TRIPS_ACTIVE_RESET, TRIPS_FILTER_FAIL, TRIPS_FILTER_REQUEST, TRIPS_FILTER_RESET, TRIPS_FILTER_SUCCESS } from "../../types/businessTypes/tripsRequested";

// download phone trips reducer
export const downloadPhoneTripsReducer = (state = { downloadPhone: [] }, { type, payload }) => {
    switch (type) {
        case DOWNLOAD_PHONE_REQUEST:
            return {
                loading: true,
            };
        case DOWNLOAD_PHONE_SUCCESS:
            return {
                loading: false,
                downloadPhone: payload,
            };
        case DOWNLOAD_PHONE_FAIL:
            return {
                loading: false,
                error: payload,
            };
        case DOWNLOAD_PHONE_RESET:
            return {
                ...state,
                downloadPhone: [],
                error: null,
                loading: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

// get trips requested orders reducers
export const getTripsRequestedReducers = (state = { tripsOrders: [] }, { type, payload }) => {
    switch (type) {
        case TRIPS_FILTER_REQUEST:
            return {
                tripLoading: true,
                tripActive: "",
            };
        case TRIPS_FILTER_SUCCESS:
            return {
                tripLoading: false,
                tripsOrders: payload,
            };
        case TRIPS_FILTER_FAIL:
            return {
                tripLoading: false,
                tripError: payload,
            };
        case TRIPS_FILTER_RESET:
            return {
                ...state,
                tripsOrders: [],
                tripError: null,
                tripLoading: null,
            }
        case TRIPS_ACTIVE_RESET:
            return {
                ...state,
                tripsOrders: [],
                tripError: null,
                tripLoading: null,
                tripActive: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                tripError: null,
            };
        default:
            return state;
    }
};