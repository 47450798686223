// Register Business User Type
export const REGISTER_BUSINESS_REQUEST = "REGISTER_BUSINESS_REQUEST";
export const REGISTER_BUSINESS_SUCCESS = "REGISTER_BUSINESS_SUCCESS";
export const REGISTER_BUSINESS_FAIL = "REGISTER_BUSINESS_FAIL";

// Login Business User Type
export const LOGIN_BUSINESS_REQUEST = "LOGIN_BUSINESS_REQUEST";
export const LOGIN_BUSINESS_SUCCESS = "LOGIN_BUSINESS_SUCCESS";
export const LOGIN_BUSINESS_FAIL = "LOGIN_BUSINESS_FAIL";

// Business User Details Type
export const BUSINESS_USER_DETAIL_REQUEST = "BUSINESS_USER_DETAIL_REQUEST";
export const BUSINESS_USER_DETAIL_SUCCESS = "BUSINESS_USER_DETAIL_SUCCESS";
export const BUSINESS_USER_DETAIL_FAIL = "BUSINESS_USER_DETAIL_FAIL";

// Update Business users Type
export const UPDATE_BUSINESS_REQUEST = "UPDATE_BUSINESS_REQUEST";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_RESET = "UPDATE_BUSINESS_RESET";
export const UPDATE_BUSINESS_FAIL = "UPDATE_BUSINESS_FAIL";

// Update ACH Form Type
export const UPDATE_BUSINESS_ACH_REQUEST = "UPDATE_BUSINESS_ACH_REQUEST";
export const UPDATE_BUSINESS_ACH_SUCCESS = "UPDATE_BUSINESS_ACH_SUCCESS";
export const UPDATE_BUSINESS_ACH_RESET = "UPDATE_BUSINESS_ACH_RESET";
export const UPDATE_BUSINESS_ACH_FAIL = "UPDATE_BUSINESS_ACH_FAIL";

// Pay ACH Payment Type
export const PAY_ACH_REQUEST = "PAY_ACH_REQUEST";
export const PAY_ACH_SUCCESS = "PAY_ACH_SUCCESS";
export const PAY_ACH_RESET = "PAY_ACH_RESET";
export const PAY_ACH_FAIL = "PAY_ACH_FAIL";

// Update Credit Card Form Type
export const UPDATE_BUSINESS_CC_REQUEST = "UPDATE_BUSINESS_CC_REQUEST";
export const UPDATE_BUSINESS_CC_SUCCESS = "UPDATE_BUSINESS_CC_SUCCESS";
export const UPDATE_BUSINESS_CC_RESET = "UPDATE_BUSINESS_CC_RESET";
export const UPDATE_BUSINESS_CC_FAIL = "UPDATE_BUSINESS_CC_FAIL";

// Update Radius
export const UPDATE_BUSINESS_DATA_REQUEST = "UPDATE_BUSINESS_DATA_REQUEST";
export const UPDATE_BUSINESS_DATA_SUCCESS = "UPDATE_BUSINESS_DATA_SUCCESS";
export const UPDATE_BUSINESS_DATA_RESET = "UPDATE_BUSINESS_DATA_RESET";
export const UPDATE_BUSINESS_DATA_FAIL = "UPDATE_BUSINESS_DATA_FAIL";

// Create Business User Session
export const USER_BUSINESS_SESSION = "USER_BUSINESS_SESSION";
export const USER_BUSINESS_ENDSESSION = "USER_BUSINESS_ENDSESSION";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RESET_BUSINESS = "RESET_BUSINESS";