import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessRadius } from "../../../../store/actions/businessActions/businessUsers";
import { UPDATE_BUSINESS_DATA_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { clearOrderErrors } from "../../../../store/actions/businessActions/businessOrders";

const DelayDriver = () => {

    const dispatch = useDispatch(); 
    const [businessId, setBusinessId] = useState('');
    const [active, setActive] = useState(1);
    const [activeChange, setActiveChange] = useState(false);
    const [oddsStatus, setOddsStatus] = useState(0);
    const [statusChange, setStatusChange] = useState(false);
    const [isOdds, setIsOdds] = useState(false);

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);
    const { isRadiusUpdated, errorRadius } = useSelector((state) => state.businessProfile);
 
    const handleClick = (event) => {
        setActive(event.target.id);
        setActiveChange(true)
    }

    const handleStatusChange = () => {
        setOddsStatus(oddsStatus === 0 ? 1 : 0);
        setStatusChange(true);
    }

    useEffect(() => {

        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
          
            setActive(businessUserDetail.businessdata.driver_time ? businessUserDetail.businessdata.driver_time : 1)
            setOddsStatus(businessUserDetail.businessdata.odds_status ? businessUserDetail.businessdata.odds_status : 0)
            setIsOdds(businessUserDetail.businessdata.is_odds && businessUserDetail.businessdata.is_odds === 1 ? true : false)
            setBusinessId(businessUserDetail.businessdata._id ? businessUserDetail.businessdata._id : '')
        }

        if (errorRadius) {
            console.log(errorRadius)
            dispatch(clearOrderErrors());
        }

        if(isRadiusUpdated){
            dispatch({ type: UPDATE_BUSINESS_DATA_RESET });
            setActiveChange(false)
            setStatusChange(false)
        }

        if(activeChange){
            setActiveChange(false)
            const formData = {
                "_id" : businessId,
                "driver_time": active,
            }
    
            dispatch(updateBusinessRadius(formData))
        }

        if(statusChange){
            setStatusChange(false)
            const formData = {
                "_id" : businessId,
                "odds_status": oddsStatus,
            }
    
            dispatch(updateBusinessRadius(formData))
        }
    }, [dispatch, errorRadius, isRadiusUpdated, businessUserDetail, loading, activeChange, businessId, active, statusChange, oddsStatus])


    return(
        <div className="delay-driver hidden sm:flex mt-20px  flex-col lg:flex-row justify-center items-center">
            <h5 className="text-center font-semibold text-2xl mb-0 mr-15px">Delay Time</h5>
            <div className="buttons delay-btns flex gap-15px p-15px flex-wrap justify-center">
                <Fragment>
                    <button type="button" className={`delay_choose_btn ${active === 1 ? "active" : ""}`} id="1" onClick={handleClick}>ASAP</button>
                    <button type="button" className={`delay_choose_btn ${active === 2 ? "active" : ""}`} id="2" onClick={handleClick}>30 min</button>
                    <button type="button" className={`delay_choose_btn ${active === 3 ? "active" : ""}`} id="3" onClick={handleClick}>40 min</button>
                    <button type="button" className={`delay_choose_btn ${active === 4 ? "active" : ""}`} id="4" onClick={handleClick}>50 min</button>
                    <button type="button" className={`delay_choose_btn ${active === 5 ? "active" : ""}`} id="5" onClick={handleClick}>60 min</button>
                    {isOdds &&
                        <button type="button" className={`delay_choose_btn ${businessUserDetail && businessUserDetail.businessdata && oddsStatus === 1 ? 'btn-green' : 'btn-red'}`} onClick={handleStatusChange}>{oddsStatus === 0 ? 'ODDS Off - Manual Mode' : 'ODDS On - Automatic'}</button>
                    }
                    
                </Fragment>
            </div>
        </div>
    )
}

export default DelayDriver