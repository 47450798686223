import { GET_CHAT_ADMIN_FAIL, GET_CHAT_ADMIN_REQUEST, GET_CHAT_ADMIN_SUCCESS } from "../../types/businessTypes/chatAdmins";

export const getChatAdminReducers = (state = { types: {} }, { type, payload }) => {
    switch (type) {
        case GET_CHAT_ADMIN_REQUEST:
            return {
                loading: true,
            };
        case GET_CHAT_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                chatAdmin: payload,
            };
        case GET_CHAT_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                chatAdmin: null,
                error: payload,
            };
        default:
            return state;
    }
};