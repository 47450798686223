import MetaData from "../../layout/MetaData"
import Banner from "./HomeBanner/Banner"
import DoubleScale from "./DoubleScale/DoubleScale"
import DriverAvailability from "./DriverAvailability/DriverAvailability"
import HowItWorks from "./HowItWorks/HowItWorks"
import EasyIntegration from "./EasyIntegration/EasyIntegration"
import EliminateDrivers from "./EliminateDrivers/EliminateDrivers"
import Testimonials from "./Testimonials/Testimonials"
import FreeSystem from "./FreeSystem/FreeSystem"
import NoRisk from "./NoRisk/NoRisk"
import FAQSection from "./FAQSection/FAQSection"
import ArtCenter from "./ArtCenter/ArtCenter"
import SignUpSection from "./SignUpSection/SignUpSection"
import Customers from "./Customers/Customers"

const Home = () => {
    return(
        <>
            <MetaData
                title={"ODDS - On Demand Delivery Solution"}
                description={"The perfect On Demand Delivery Solution for any restaurant that want's to increase profits and reduce delivery fees!"}
            />

            <Banner />
            <HowItWorks />
            <DoubleScale />
            <DriverAvailability />
            <EasyIntegration />
            <EliminateDrivers />
            <Testimonials />
            <FreeSystem />
            <NoRisk />
            <FAQSection />
            <ArtCenter />
            <Customers />
            <SignUpSection />
        </>
    )
}

export default Home