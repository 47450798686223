import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import plaidLogo from '../../../../assests/images/business/plaid_logo.jfif';
import ProfileAchForm from "./ProfileAchForm";
import { usePlacesWidget } from "react-google-autocomplete";
import { clearErrors, detailBusinessUser, payBusinessACH, updateBusinessProfile } from "../../../../store/actions/businessActions/businessUsers";
import { PAY_ACH_RESET, UPDATE_BUSINESS_RESET } from "../../../../store/types/businessTypes/businessUsers";
import { useSnackbar } from "notistack";

const ProfilePayment = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { businessUserDetail, loading } = useSelector((state) => state.businessUserDetails);

    const { error, isUpdated, isACHPay, loadingACHPay, errorACHPay } = useSelector((state) => state.businessProfile);

    const [objectId, setObjectId] = useState('');
    const [restaurantId, setRestaurantId] = useState('');
    const [restId, setRestId] = useState('');
    const [emailAdd, setEmailAdd] = useState('');

    const [restaurantName, setRestaurantName] = useState('');
    const [restaurantAddress, setRestaurantAddress] = useState('');
    const [restaurantCity, setRestaurantCity] = useState('');
    const [restaurantState, setRestaurantState] = useState('');
    const [restaurantZipcode, setRestaurantZipcode] = useState('');
    const [restaurantCountry, setRestaurantCountry] = useState('');
    const [restaurantStreetAddress, setRestaurantStreetAddress] = useState('');
    const [restaurantLat, setRestaurantLat] = useState('');
    const [restaurantLong, setRestaurantLong] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [restaurantEmailAdd, setRestaurantEmailAdd] = useState('');
    const [restaurantPhone, setRestaurantPhone] = useState('');
    const [restaurantPassword, setRestaurantPassword] = useState('');
    const [accountNumber, setAccountNumber] = useState(''); 
    const [ccExpiration, setCcExpiration] = useState(''); 
    const [ccExpirationYear, setCcExpirationYear] = useState(''); 
    const [ccCvv, setCcCvv] = useState(''); 
    const [salePerson, setSalePerson] = useState('');
    const [ghUsername, setGhUsername] = useState('');
    const [ghPassword, setGhPassword] = useState('');
    const [doordashUsername, setDoordashUsername] = useState('');
    const [doordashPassword, setDoordashPassword] = useState('');
    const [uberUsername, setUberUsername] = useState('');
    const [uberPin, setUberPin] = useState('');
    const [uberPassword, setUberPassword] = useState('');

    const [years, setYears] = useState([]);

    // submit update form
    const handleSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", restaurantId);
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("restaurant_email", restaurantEmailAdd);
        formData.set("restaurant_phone", restaurantPhone);
        formData.set("login_password", restaurantPassword);
        formData.set("restaurant_address", restaurantAddress);
        formData.set("restaurant_street_address", restaurantStreetAddress);
        formData.set("restaurant_city", restaurantCity);
        formData.set("restaurant_state", restaurantState);
        formData.set("restaurant_zipcode", restaurantZipcode);
        formData.set("restaurant_country", restaurantCountry);
        formData.set("restaurant_lat", restaurantLat);
        formData.set("restaurant_long", restaurantLong);
        formData.set("gh_username", ghUsername); 
        formData.set("gh_password", ghPassword);
        formData.set("doordash_username", doordashUsername);
        formData.set("doordash_password", doordashPassword);
        formData.set("uber_username", uberUsername);
        formData.set("uber_password", uberPassword);
        formData.set("uber_pin", uberPin);
        formData.set("sales_person", salePerson)

        dispatch(updateBusinessProfile(formData));
    }

    // address autocomplete
    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_API_MAP_KEY,
        onPlaceSelected: (place) => {
            if (place) {
                const addressComponents = place.address_components;
                // console.log(addressComponents)
        
                // Extract address components
                const streetAddress = addressComponents.find(component =>
                    component.types.includes('route')
                )?.long_name || '';

                setRestaurantStreetAddress(streetAddress);
        
                const postalCode = addressComponents.find(component =>
                    component.types.includes('postal_code')
                )?.long_name || '';

                setRestaurantZipcode(postalCode)
        
                const city = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('administrative_area_level_2')
                )?.long_name || '';

                setRestaurantCity(city)
        
                const state = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                )?.long_name || '';

                setRestaurantState(state)
        
                const country = addressComponents.find(component =>
                    component.types.includes('country')
                )?.short_name || '';

                setRestaurantCountry(country);

                const lat = place.geometry?.location.lat() || '';
                const lng = place.geometry?.location.lng() || '';

                setRestaurantLat(lat);
                setRestaurantLong(lng);
            }
          setRestaurantAddress(place.formatted_address);
        },
        options: {
          types: ["address"],
          componentRestrictions: { country: "us" },
        },
    });

     // handle restaurant mobile number
     const handleRestaurantPhone = (e) =>{
        const value = e.target.value.replace(/\D/g, "");
        setRestaurantPhone(value);
    }

    // handle ACH Pay Payment
    const handleACHPay = () => {
        dispatch(payBusinessACH(restId))
    }

    useEffect(()=>{
        // prefill user details
        if(loading === false && businessUserDetail && businessUserDetail.status === 1){
            setObjectId(businessUserDetail.user._id)
            setRestaurantId(businessUserDetail.user.customer_id);
            setRestId(businessUserDetail.businessdata._id);
            setEmailAdd(businessUserDetail.user.email);
            setFirstName(businessUserDetail.user.firstname);
            setLastName(businessUserDetail.user.lastname);
            setRestaurantEmailAdd(businessUserDetail.businessdata.restaurant_email ? businessUserDetail.businessdata.restaurant_email : '');
            setRestaurantPhone(businessUserDetail.businessdata.restaurant_phone ? businessUserDetail.businessdata.restaurant_phone : '');
            setRestaurantName(businessUserDetail.businessdata.restaurant_name ? businessUserDetail.businessdata.restaurant_name : '');
            setRestaurantAddress(businessUserDetail.businessdata.restaurant_address ? businessUserDetail.businessdata.restaurant_address : '');
            setRestaurantStreetAddress(businessUserDetail.businessdata.restaurant_street_address ? businessUserDetail.businessdata.restaurant_street_address : '');
            setRestaurantCity(businessUserDetail.businessdata.restaurant_city ? businessUserDetail.businessdata.restaurant_city : '');
            setRestaurantState(businessUserDetail.businessdata.restaurant_state ? businessUserDetail.businessdata.restaurant_state : '');
            setRestaurantZipcode(businessUserDetail.businessdata.restaurant_zipcode ? businessUserDetail.businessdata.restaurant_zipcode : '');
            setRestaurantCountry(businessUserDetail.businessdata.restaurant_country ? businessUserDetail.businessdata.restaurant_country : '');
            setRestaurantLat(businessUserDetail.businessdata.restaurant_lat ? businessUserDetail.businessdata.restaurant_lat : '');
            setRestaurantLong(businessUserDetail.businessdata.restaurant_long ? businessUserDetail.businessdata.restaurant_long : '');
            setSalePerson(businessUserDetail.businessdata.sales_person ? businessUserDetail.businessdata.sales_person : '');
            setGhUsername(businessUserDetail.businessdata.gh_username ? businessUserDetail.businessdata.gh_username : '');
            setGhPassword(businessUserDetail.businessdata.gh_password ? businessUserDetail.businessdata.gh_password : '');
            setDoordashUsername(businessUserDetail.businessdata.doordash_username ? businessUserDetail.businessdata.doordash_username : '');
            setDoordashPassword(businessUserDetail.businessdata.doordash_password ? businessUserDetail.businessdata.doordash_password : '');
            setUberUsername(businessUserDetail.businessdata.uber_username ? businessUserDetail.businessdata.uber_username : '');
            setUberPassword(businessUserDetail.businessdata.uber_password ? businessUserDetail.businessdata.uber_password : '');
            setUberPin(businessUserDetail.businessdata.uber_pin ? businessUserDetail.businessdata.uber_pin : '');
            // setAccountNumber(businessUserDetail.businessdata.cc_number);
            setCcCvv(businessUserDetail.businessdata.cc_cvv ? businessUserDetail.businessdata.cc_cvv : '');

            if(businessUserDetail.businessdata.cc_number){
                if(businessUserDetail.businessdata.cc_number.toString().length > 8){
                    const cardNumberString = businessUserDetail.businessdata.cc_number.toString();
                    const visibleStart = cardNumberString.slice(0, 4);
                    const visibleEnd = cardNumberString.slice(-4);
                    const maskedSection = 'X'.repeat(cardNumberString.length - 8);
                    setAccountNumber(visibleStart+maskedSection+visibleEnd)
                } else {
                    setAccountNumber(businessUserDetail.businessdata.cc_number);
                }
                
            } else {
                setAccountNumber('');
            }

            if(businessUserDetail.businessdata.cc_expiration){
                const expiration = businessUserDetail.businessdata.cc_expiration.split("/");
                setCcExpiration(expiration[0])
                setCcExpirationYear(expiration[1])
            } else {
                setCcExpiration('')
                setCcExpirationYear('')
            }

           
        } 

        // Year dropdown
        const currentYear = new Date().getFullYear();
        const yearsArray = [];
        for (let i = 0; i < 15; i++) {
        yearsArray.push(currentYear + i);
        }
        setYears(yearsArray);

        // Profile Error
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        
        // Profile updated
        if (isUpdated) {

            enqueueSnackbar("Profile Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: emailAdd,
                customer_id: restaurantId,
            }
            dispatch(detailBusinessUser(userDetails))
            dispatch({ type: UPDATE_BUSINESS_RESET });
        }

        // Profile Error
        if (errorACHPay) {
            enqueueSnackbar(errorACHPay, { variant: "error" });
            dispatch({ type: PAY_ACH_RESET });
        }

        if(loadingACHPay === false && isACHPay){
            if(isACHPay.status === 1){
                window.open(isACHPay.redirect_link, '_blank');
            } else {
                var msg = isACHPay.msg;
                enqueueSnackbar(msg, { variant: "error" });
            }
            
            dispatch({ type: PAY_ACH_RESET });
        }
       

    }, [dispatch, isUpdated, businessUserDetail, loading, error, objectId, emailAdd, restaurantId, enqueueSnackbar, errorACHPay, isACHPay, loadingACHPay])

    // get year as 2 digit
    const getTwoDigitYear = (year) => year % 100;

    return(
        <div className="p-2.5">
            <h4 className="flex justify-between mb-20px">Profile <span>{businessUserDetail && businessUserDetail.status === 1 && businessUserDetail.businessdata.restaurant_name}</span></h4>

            <Form method="post" onSubmit={handleSubmit} className="business_profile_form">
                <input type="hidden" name="restaurant_id" defaultValue={restaurantId} />

                <div className="flex flex-col md:flex-row -mx-12px">
                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px hidden" controlId="logo_image">
                            <Form.Control type="file" name="logo_image" />
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_name">
                            <Form.Control type="text" placeholder="Business Name" name="restaurant_name" value={restaurantName} onChange={(e) => setRestaurantName(e.target.value)} readOnly/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_address">
                            <Form.Control ref={ref}  type="text" placeholder="Address*" name="restaurant_address" required defaultValue={restaurantAddress} />
                            <input type="hidden" name="restaurant_street_address" defaultValue={restaurantStreetAddress} />
                            <input type="hidden" name="restaurant_country" defaultValue={restaurantCountry} />
                            <input type="hidden" name="restaurant_lat" defaultValue={restaurantLat} />
                            <input type="hidden" name="restaurant_long" defaultValue={restaurantLong} />
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_city">
                            <Form.Control type="text" placeholder="City*" name="restaurant_city" required value={restaurantCity} onChange={(e) => setRestaurantCity(e.target.value)}/>
                        </Form.Group>

                        <Form.Select name="restaurant_state" id="restaurant_state" className="mb-15px" value={restaurantState} onChange={(e) => setRestaurantState(e.target.value)} required>
                            <option>State</option>
                            {businessUserDetail && businessUserDetail.status === 1 && businessUserDetail.StateNameslist.map((item,i) => (
                                <option value={item.state_name} id={item.state_code} key={i}>{item.state_name}</option>
                            ))}
                        </Form.Select>

                        <Form.Group className="mb-15px" controlId="restaurant_zipcode">
                            <Form.Control type="text" placeholder="Zipcode*" name="restaurant_zipcode" required value={restaurantZipcode} onChange={(e) => setRestaurantZipcode(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="gh_username">
                            <Form.Control type="text" placeholder="GH Username" name="gh_username" value={ghUsername} onChange={(e) => setGhUsername(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="doordash_username">
                            <Form.Control type="text" placeholder="DoorDash Username" name="doordash_username" value={doordashUsername} onChange={(e) => setDoordashUsername(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="uber_username">
                            <Form.Control type="text" placeholder="Uber Username" name="uber_username" value={uberUsername} onChange={(e) => setUberUsername(e.target.value)}/>
                        </Form.Group>

                    </div>

                    <div className="w-full md:w-1/2 px-12px">
                        <Form.Group className="mb-15px" controlId="first_name">
                            <Form.Control type="text" name="first_name" placeholder="First Name*" required value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="last_name">
                            <Form.Control type="text" placeholder="Last Name*" name="last_name" required value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_email">
                            <Form.Control type="email" placeholder="Email*" name="restaurant_email" required value={restaurantEmailAdd} onChange={(e) => setRestaurantEmailAdd(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_phone">
                            <Form.Control type="tel" placeholder="Phone*" name="restaurant_phone" required value={restaurantPhone} onChange={handleRestaurantPhone} minLength={10} maxLength={10}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="restaurant_password">
                            <Form.Control type="password" placeholder="Change Password For Buying Login" name="restaurant_password" value={restaurantPassword} onChange={(e) => setRestaurantPassword(e.target.value)} minLength={8} maxLength={15}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="account_number">
                            <Form.Control type="text" placeholder="Card Number*" name="account_number" defaultValue={accountNumber} readOnly/>
                            <input type="hidden" id="card_org_number" value="" />
                            <input type="hidden" id="card_org_change" value=""/>
                        </Form.Group>

                        <div className="flex flex-col sm:flex-row flex-wrap gap-2.5 sm:items-center mb-15px select-flex">
                            <Form.Select name="cc_expiration" id="cc_expiration" value={ccExpiration} readOnly={true}>
                                <option value=""></option>
                                <option value="01" disabled>January</option>
                                <option value="02" disabled>February</option>
                                <option value="03" disabled>March</option>
                                <option value="04" disabled>April</option>
                                <option value="05" disabled>May</option>
                                <option value="06" disabled>June</option>
                                <option value="07" disabled>July</option>
                                <option value="08" disabled>August</option>
                                <option value="09" disabled>September</option>
                                <option value="10" disabled>October</option>
                                <option value="11" disabled>November</option>
                                <option value="12" disabled>December</option>
                            </Form.Select>

                            <label>Exp/Year</label>

                            <Form.Select name="cc_expiration_year" id="cc_expiration_year" value={ccExpirationYear} readOnly>
                                <option value=""></option>
                                {years.map((year) => (
                                    <option key={year} value={getTwoDigitYear(year).toString().padStart(2, '0')} disabled>
                                     {getTwoDigitYear(year).toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </Form.Select>

                            <label>CVV</label>
                            
                            <Form.Group controlId="cc_cvv">
                                <Form.Control type="password" placeholder="CVV" name="cc_cvv" value={ccCvv} readOnly/>
                            </Form.Group>
                        </div>

                        <div className="plaid font-semibold mb-15px flex flex-wrap items-center cursor-pointer" onClick={() => handleACHPay()} >I Prefer ACH Payment. Click here to set up your Acccount. <img src={plaidLogo} style={{width:"100px"}} alt="plaid logo" /></div>
                        
                        <Form.Group className="mb-15px" controlId="sale_person">
                            <Form.Control type="text" placeholder="Sales Person Referral" name="sale_person" value={salePerson} onChange={(e) => setSalePerson(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="gh_password">
                            <Form.Control type="password" placeholder="GH Password" name="gh_password" value={ghPassword} onChange={(e) => setGhPassword(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="mb-15px" controlId="doordash_password">
                            <Form.Control type="password" placeholder="DoorDash Password" name="doordash_password" value={doordashPassword} onChange={(e) => setDoordashPassword(e.target.value)}/>
                        </Form.Group>

                        <div className="flex flex-col sm:flex-row gap-[20px] mb-15px">
                            <Form.Group controlId="uber_password" className="w-full">
                                <Form.Control type="password" placeholder="Uber Password" name="uber_password" value={uberPassword} onChange={(e) => setUberPassword(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="uber_pin" className="w-full">
                                <Form.Control type="text" placeholder="Uber Pin" name="uber_pin" value={uberPin} onChange={(e) => setUberPin(e.target.value)}/>
                            </Form.Group>
                        </div>
                        
                        <Form.Group className="text-right">
                            <Button type="submit" className="btn-profile-submit">Submit</Button>
                        </Form.Group>

                    </div>

                </div>
            </Form>

            {/* ACH form */}
            <ProfileAchForm />

        </div>
    )
}

export default ProfilePayment