import { useState } from "react";
import { Col, Form, Table } from "react-bootstrap";
import csvIcon from '../../../../assests/images/csv-icon.png';
import pdfIcon from '../../../../assests/images/pdf-icon.png';

const CommissionReport = () => {

    const [deliveryYear, setDeliveryYear] = useState('');
    const [deliveryMonth, setDeliveryMonth] = useState('');

    const handleReportSubmit = (e) => {
        e.preventDefault();
    }

    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <div className="reports_filter_section">
                <div className="reports_filter_left">
                    <Form className="sales-add-report-form business_profile_form" method="post" onSubmit={handleReportSubmit}>

                        <div className="flex flex-col md:flex-row gap-[20px]">
                            <Form.Group as={Col} controlId="report_delivery_year_comm">
                                <Form.Label>Select Year</Form.Label>
                                <Form.Select name="report_delivery_year_comm" value={deliveryYear} onChange={(e) => setDeliveryYear(e.target.value)} className="report_filter_input">
                                    <option value="">Select Year</option>
                                    <option value="2024">2024</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="report_delivery_month_comm">
                                <Form.Label>Select Month</Form.Label>
                                <Form.Select name="report_delivery_month_comm" value={deliveryMonth} onChange={(e) => setDeliveryMonth(e.target.value)} className="report_filter_input">
                                    <option value="">Select Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Form.Select>
                            </Form.Group>

                        </div>

                    </Form>
                </div>

                <div className="reports_filter_right">
                    <img src={csvIcon} alt="CSV" />
                    <img src={pdfIcon} alt="CSV" />
                </div>
            </div>
            

            <Table className="report_table" id="delivery_report_table">
                <thead>
                    <tr>
                        <th className="text-center" style={{width: '22%'}}>Date</th>
                        <th style={{width: '28%'}}>Business Name</th>
                        <th className="text-center" style={{width: '25%'}}>Type</th>
                        <th className="text-center" style={{width: '25%'}}>Commision</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="grey">
                        <td data-label="Date" className="text-center">07/01/2024</td>
                        <td data-label="Business Name">Steak House</td>
                        <td data-label="Type" className="text-center">Bonus</td>
                        <td data-label="Commision" className="text-center">$ 100.00</td>
                    </tr>
                    <tr className="tfoot">
                        <td colSpan="3" className="text-right hide-mobile">Total:</td>
                        <td data-label="Total Commision" className="text-center">$ 3,198.00</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default CommissionReport