import axios from "axios";
import { GET_CHAT_ADMIN_FAIL, GET_CHAT_ADMIN_REQUEST, GET_CHAT_ADMIN_SUCCESS } from "../../types/businessTypes/chatAdmins";

// Get Scan Drivers
export const getChatAdmin = () => async (dispatch) => {
    try {

        dispatch({ type: GET_CHAT_ADMIN_REQUEST });

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/getchatadmins/`,
            {headers: { 
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                'Content-Type': 'application/json'
            }}
        );
          
        dispatch({
            type: GET_CHAT_ADMIN_SUCCESS,
            payload: data,
        });

    } catch (error) {
        dispatch({
            type: GET_CHAT_ADMIN_FAIL,
            payload: error.response.data.message,
        });
    }

};