import { Link } from "react-router-dom";
import bannerImg from '../../../assests/images/home/home-slider.webp';
import poster from '../../../assests/images/home/video-poster-1.webp';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import './Banner.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader, faClose, faPhotoVideo, faTvAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import videoPoster from '../../../assests/images/home/topbar-drivernow.jpg';

const Banner = () =>{

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <section className="top-bar bg-primary-yellow p-20px">
                <h2 className="text-[22px] text-center mb-0 font-semibold leading-none">
                    <Link to="/rent-free" reloadDocument={true} className="text-primary-grey no-underline">Do you qualify for our FREE RENT program? Find out!</Link>
                </h2>
            </section>

            <section className="banner-home relative overflow-hidden">
                <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row z-1 relative gap-[20px]">
                    <div className="w-full py-2.5 pl-2.5 pr-6">
                        <h1 className="text-4xl md:text-5xl lg:text-[56px] font-semibold text-white">One system.<br/> Endless<br/> possibilities.</h1>
                        <h4 className="text-base md:text-xl lg:text-[26px] text-white pt-20px">Combine ALL your tablets, drivers & delivery into one powerful POS system. Designed to simplify operations & skyrocket your sales.</h4>
                    </div>
                    <div className="w-full p-2.5 flex flex-col-reverse md:flex-col gap-[20px]">
                        <LiteYouTubeEmbed
                            id="_g5X8WBLls8"
                            adNetwork={false}
                            params=""
                            playlist={false}
                            playlistCoverId="_g5X8WBLls8"
                            thumbnail={poster}
                            noCookie={false} 
                        />
                        <Link reloadDocument={true} to="/register" className="uppercase text-white text-base md:text-lg font-semibold bg-primary-red ml-0 md:ml-auto mr-auto px-6 py-12px no-underline rounded-50 leading-none hover:bg-black">Get Started Now!</Link>
                    </div>
                </div>
                <img src={bannerImg} alt="Banner" className="absolute top-0 left-0 w-full h-full object-cover object-top" />
            </section>

            <section className="banner-btn-section bg-primary-darkBlue">
                <div className="max-w-screen-medium mx-auto py-50 px-2.5 flex flex-col md:flex-row flex-wrap gap-[20px]">
                    <a href="#howitworks" className="min-w-72 justify-center uppercase text-primary-darkBlue text-base md:text-lg font-semibold bg-primary-bgBlue mx-auto px-8 py-15px no-underline rounded-50 leading-none flex items-center border-2 border-primary-bgBlue hover:rounded-xl hover:bg-transparent hover:text-white hover:transition-all"><FontAwesomeIcon icon={faBookReader} className="pr-15px text-3xl"/>Learn More</a>

                    <a href="/request-demo" className="min-w-72 justify-center uppercase text-primary-darkBlue text-base md:text-lg font-semibold bg-primary-bgBlue mx-auto px-8 py-15px no-underline rounded-50 leading-none flex items-center border-2 border-primary-bgBlue hover:rounded-xl hover:bg-transparent hover:text-white hover:transition-all"><FontAwesomeIcon icon={faTvAlt} className="pr-15px text-3xl"/>Request Demo</a>

                    <Link className="min-w-72 justify-center uppercase text-primary-darkBlue text-base md:text-lg font-semibold bg-primary-bgBlue mx-auto px-8 py-15px no-underline rounded-50 leading-none flex items-center border-2 border-primary-bgBlue hover:rounded-xl hover:bg-transparent hover:text-white hover:transition-all" onClick={handleShow}><FontAwesomeIcon icon={faPhotoVideo} className="pr-15px text-3xl"/>Watch Tutorial</Link>
                </div>
            </section>

            <Modal show={show} onHide={handleClose} centered={true} className="popup-modal">
                
                <Modal.Body className="relative">
                    <FontAwesomeIcon icon={faClose} className="absolute right-20px top-20px z-1 cursor-pointer" onClick={handleClose}/>
                    <video
                        src="https://oddsdrive.com/wp-content/uploads/2024/02/CORRECTED-VIDEOS.mp4"
                        poster={videoPoster}
                        controls
                        className="video-popup"
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Banner