import { Outlet, useLocation } from "react-router-dom"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import { useLayoutEffect } from "react";

const Layout = () => {

    const location = useLocation();
    const locationArr = ['/dashboard', '/marketingads', '/consumerdashboard'];

    // scroll to top of page after a page transition.
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname]);

    return(
        <>
            {locationArr.indexOf(location.pathname) > -1  ?
                null
            :
                <Header />
            }
            
            <Outlet />

            {locationArr.indexOf(location.pathname) > -1 ?
                null
            :
                <Footer />
            }
            
        </>
    )
}

export default Layout