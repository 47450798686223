import { useSnackbar } from "notistack";
import { Fragment, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { clearOrderErrors, gteOrderInvoice, myInternalOrders } from "../../../../store/actions/businessActions/businessOrders";
import monitizing from '../../../../assests/images/business/monitoring.gif';
import adminMonitizing from '../../../../assests/images/business/pre-loader-31.gif';
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import axios from "axios";
import OrderInvoice from "./OrderInvoice";

// Countdown Timer
const CountdownTimer = ({ initialTime, itemId, isCountdown, setIsCountdown }) => {
    // Parse the initialTime prop into minutes and seconds
    const parseTimeToSeconds = (timeStr) => {

        if(timeStr.split(':').map(Number).length === 3){

            const [hours, minutes, seconds] = timeStr.split(':').map(Number);
            return hours * 60 * 60 + minutes * 60 + seconds;

        } else if(timeStr.split(':').map(Number).length === 2){
            const [minutes, seconds] = timeStr.split(':').map(Number);
            return minutes * 60 + seconds;
        } else {
            const [seconds] = timeStr.split(':').map(Number);
            return seconds;
        }
    };
  
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const secs = seconds % 60

        if(hours > 0){
            return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        } else {
            return `${minutes < 10 ? `0${minutes}` : minutes}:${secs < 10 ? `0${secs}` : secs}`;
        }
        
    };
  
    const [timeLeft, setTimeLeft] = useState(parseTimeToSeconds(initialTime));
  
    useEffect(() => {
        if (timeLeft <= 0){ 
            // setDisplayCountdown(false)
            setIsCountdown(isCountdown.filter(item => item !== itemId));
            return
        };
  
      const intervalId = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      
      return () => clearInterval(intervalId);
      
    }, [timeLeft, itemId, setIsCountdown, isCountdown]);
  
    return (
      <span>{formatTime(timeLeft)}</span>
    );
};

const StatSection = () => {

    const dispatch = useDispatch();
    const allOrdersRefs = {}
    const { enqueueSnackbar } = useSnackbar();
    const { orders, error } = useSelector((state) => state.myInternalOrders);

    const { userDetail } = useSelector((state) => state.userDetails);

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");
    // const [displayCountdown, setDisplayCountdown] = useState(true);
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);

    const [isCountdown, setIsCountdown] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // handle map show
    const handleMapShow = (distance, time, lat, long, delAdd, pickAdd) => {
        setMapDistance(distance);
        setMapTime(time);
        setMapLat(lat);
        setMapLong(long);

        const distanceService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: pickAdd,
                    destination: delAdd,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    handleShow()

                } else {
                    alert('Error fetching distance:', status);
                }
                }
            ); 
    }

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_ordermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_ordermsg").innerHTML = '<td colSpan="6"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_ordermsg").innerHTML = '';
            });
        }
    }

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    useEffect(() => {
        if(orders && orders.updatedResults && orders.updatedResults.length >= 1){
            setIsCountdown(orders.updatedResults.map(item => item.timediff > 0 ? item._id :'' ));
        }
    }, [orders])
   
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearOrderErrors());
        }

        if(userDetail && userDetail.userId){
            dispatch(myInternalOrders(userDetail.userId));
        }
        
    }, [dispatch, error, enqueueSnackbar, userDetail]);

    return(
        <div className="stat-section">
            <div className="btn-section flex justify-center flex-wrap p-15px gap-2.5 mb-30px">
                <button>Today's Delivery {orders && orders.todayOrders}</button>
                <button>Yesterday {orders && orders.yesterdayOrders}</button>
                <button>Week {orders && orders.thisweekOrders}</button>
                <button className="hidden sm:block">Month {orders && orders.thismonthOrders}</button>
                <button className="hidden sm:block">Last Week {orders && orders.lastweekOrders}</button>
                <button className="hidden">Last 7 days 0</button>
                <button className="hidden sm:block">Last Month {orders && orders.lastmonthOrders}</button>
            </div>

            <div id="gh_orders">
                {orders && orders.updatedResults && orders.updatedResults.length >= 1 && (
                    <Table responsive className="internal-orders-table">
                        <thead>
                            <tr>
                                <th className="w-[30%]">Order Information</th>
                                <th className="w-[10%]">Timing</th>
                                <th className="w-[20%]">Driver Location / Track</th>
                                <th className="w-[10%]">View Order / P</th>
                                <th className="w-[10%]">Est. Delivery</th>
                                <th className="w-[20%]">Admin Tracking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.updatedResults.map((item, i) => (
                                <Fragment key={i}>
                                <tr key={item._id+"_order"}>
                                    <td>
                                        <h5 className="mb-0">{item.delivery_firstname} {item.delivery_phone}</h5>
                                        <p className="mb-0 text-gray-500">{item.delivery_city}, {item.delivery_state}</p>
                                        <p className="mb-0"><span type="button" className="text-black p-1 text-sm font-semibold" onClick={() => handleMessgeCLick(item._id)}>Contact Messages</span></p>
                                    </td>
                                    <td className="text-center">
                                        {
                                        item.timediff < 0 ?
                                            <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        :
                                            isCountdown.includes(item._id) ?
                                                <div>
                                                    <p className="mb-0">
                                                        <CountdownTimer initialTime={item.timediff_value} itemId={item._id} setIsCountdown={setIsCountdown} isCountdown={isCountdown} /> 
                                                    </p>
                                                    <button type="button" className="text-blue-500" onClick={() => handleMapShow(item.order_mileage_distance, item.order_time_distance, item.delivery_lat, item.delivery_long, item.delivery_address, item.pickup_address )}>Map</button>
                                                </div>

                                            :
                                                <img src={item.timer_img} alt="Order Type" className="w-20 h-20 mx-auto" />
                                        }

                                    </td>
                                    <td className={`text-center ${item.order_status_id === 6 ? 'bgpurple' : ''}`}>
                                        {item.driver_info}
                                    </td>
                                    <td>
                                        {item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto cursor-pointer" onClick={()=> getInvoice(item._id, item.order_type)} />
                                        :
                                            <img src={item.order_type_img} alt="Order Type" className="h-[120px] mx-auto" />
                                        }
                                    </td>
                                    <td className="text-center">
                                        <img src={monitizing} alt="MONITORING" className="h-20 mx-auto" />
                                    </td>
                                    <td className="text-center system-moniter text-white">
                                        SYSTEM MONITORING DRIVER
                                        <img src={adminMonitizing} alt="SYSTEM MONITORING DRIVER" className="h-5 mx-auto" />
                                    </td>
                                </tr>

                                {/* Orders messages */}
                                <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_ordermsg"} key={item._id+"_ordermsg"}>
                                </tr>

                                {/* order buttons row */}
                                <tr className="orders-button-rows" key={item._id+"_orderbtn"}>
                                    <td colSpan={6}>
                                        <div className="flex">

                                            {item.custom_sms && item.custom_sms.map((sms, i) => (
                                                <div className="w-1/4 orders_sms_div" key={i} >
                                                    <Button className="orders_sms">{sms.question}</Button>
                                                </div>
                                            ))}
                                            
                                        </div>
                                    </td>
                                </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
                
            </div>

            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default StatSection