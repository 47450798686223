import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getScanDrivers } from "../../../../store/actions/businessActions/scanDrivers";

const ScanDrivers = ({show}) => {

    const dispatch = useDispatch();
    const {scandrivers} = useSelector((state) => state.scanDrivers)

    useEffect(() => {
        dispatch(getScanDrivers())
    }, [dispatch])

    return(
        <div className={`scan_drivers_section mt-20px ${show ? 'flex flex-wrap justify-center lg:justify-between' : 'hidden'}`}>
            {scandrivers && scandrivers.map((item,i) => (
                <div className="driver_div px-1 py-2.5" key={i}>
                    <img src={`${process.env.REACT_APP_API_URL}/images/${item.image}`} alt={item.name} className="scan-drivers-img" />
                    <h5>{item.name}</h5>
                </div>
            ))}
        </div>
    )
}

export default ScanDrivers