import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import freeSystem from '../../../assests/images/home/odds-system-printers-3.webp';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import videoPoster from '../../../assests/images/home/topbar-drivernow.jpg';
import './FreeSystem.css'

const FreeSystem = () => {

    const listItem = [
        "Control Drivers From All Aggregators",
        "The Most Powerful POS In The Market",
        "Print All Orders In One Central Location",
        "Collect Customers Phone Numbers & Addresses",
        "Free Text Remarketing",
        "150 Extra Orders Per Month From Our Platform",
        "Integrates With 28 Platforms",
        "10 Mile Radius, Auto Driver Delay Feature With One Button",
        "Saves You An Average Of $5.00 Per Order Over Internal Drivers"
    ]

    return(
        <section className="home-free-system bg-primary-bgBlue">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-1/2 py-15px px-2.5">
                    <img
                        src={freeSystem}
                        alt='ODDS system'
                        className='w-full'
                    />
                </div>
                
                <div className="w-full md:w-1/2 py-30px px-2.5 flex flex-col gap-[20px]">
                    <h3 className='text-[55px] font-semibold text-primary-darkBlue'>Get Your <br/>FREE SYSTEM</h3>
                    <ul className='p-0 m-0'>
                        {listItem.map((item,i) => (
                            <li key={i} className='flex flex-row items-center pb-2.5'>
                                <FontAwesomeIcon icon={faCheck} className='text-md font-semibold text-primary-red pr-12px mr-20px' />
                                <span className='text-xl'>{item}</span>
                            </li>
                        ))}
                    </ul>
                    <a href="/request-demo" className="uppercase text-white text-base font-semibold bg-primary-red mr-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider border-2 border-primary-red hover:bg-black hover:border-black">Request Demo</a>
                </div>

            </div>
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto pt-2.5 pb-50 ">
                <div className='w-full md:w-4/5 mx-auto px-2.5 flex flex-col items-center md:gap-[20px]'>
                    <h3 className='text-4xl font-semibold'>Eliminate driver issues!</h3>
                    <video
                        src="https://oddsdrive.com/wp-content/uploads/2024/02/CORRECTED-VIDEOS.mp4"
                        poster={videoPoster}
                        controls
                        className="video-popup"
                    />
                </div>
            </div>
        </section>
    )
}

export default FreeSystem