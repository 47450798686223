import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { emptyBusinessSession } from '../../../../store/actions/businessActions/businessUsers';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { useTab } from '../../../business/Dashboard/TabContext';
import ContactContent from './ContactContent';
import TripRequested from './TripRequested';
import MyProfilePayment from './MyProfilePayment';

const FooterButtons = () => {

    const dispatch = useDispatch();
    const { activeKey, setActiveKey } = useTab();

    const handleLogout = () => {
        dispatch(emptyBusinessSession());
    }

    return(
        <>
            <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                <div className='flex flex-col w-full mt-20px'>
                    <Tab.Content>
                        <Tab.Pane eventKey="home"></Tab.Pane>
                        <Tab.Pane eventKey="profile"><MyProfilePayment /></Tab.Pane>
                        <Tab.Pane eventKey="trips"><TripRequested /></Tab.Pane>
                        <Tab.Pane eventKey="contact"><ContactContent /></Tab.Pane>
                    </Tab.Content>
                
                    <Nav variant="pills" className="flex-row justify-around footer-btn-links mt-20px">
                        <Nav.Item>
                            <Nav.Link eventKey="home">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="profile">My Profile / Payment</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="trips">Trips Requested</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="contact" className='green-btn'>Contact Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Tab.Container>
        </>
    )
}

export default FooterButtons