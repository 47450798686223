import { CLEAR_ERRORS, LOGIN_BUSINESS_FAIL, LOGIN_BUSINESS_REQUEST, LOGIN_BUSINESS_SUCCESS, REGISTER_BUSINESS_FAIL, REGISTER_BUSINESS_REQUEST, REGISTER_BUSINESS_SUCCESS, RESET_BUSINESS, USER_BUSINESS_ENDSESSION, USER_BUSINESS_SESSION, BUSINESS_USER_DETAIL_REQUEST, BUSINESS_USER_DETAIL_SUCCESS, BUSINESS_USER_DETAIL_FAIL, UPDATE_BUSINESS_REQUEST, UPDATE_BUSINESS_SUCCESS, UPDATE_BUSINESS_FAIL, UPDATE_BUSINESS_RESET, UPDATE_BUSINESS_ACH_REQUEST, UPDATE_BUSINESS_ACH_SUCCESS, UPDATE_BUSINESS_ACH_FAIL, UPDATE_BUSINESS_ACH_RESET, UPDATE_BUSINESS_CC_REQUEST, UPDATE_BUSINESS_CC_SUCCESS, UPDATE_BUSINESS_CC_FAIL, UPDATE_BUSINESS_CC_RESET, UPDATE_BUSINESS_DATA_REQUEST, UPDATE_BUSINESS_DATA_SUCCESS, UPDATE_BUSINESS_DATA_FAIL, UPDATE_BUSINESS_DATA_RESET, PAY_ACH_REQUEST, PAY_ACH_SUCCESS, PAY_ACH_FAIL, PAY_ACH_RESET } from "../../types/businessTypes/businessUsers";

export const businessUserReducer = (state = { user: {} }, { type, payload }) => {
    switch (type) {
        case LOGIN_BUSINESS_REQUEST:
        case REGISTER_BUSINESS_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case LOGIN_BUSINESS_SUCCESS:
        case REGISTER_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: payload,
            };
        case LOGIN_BUSINESS_FAIL:
        case REGISTER_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: payload,
            };
        case RESET_BUSINESS:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const businessProfileReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case UPDATE_BUSINESS_REQUEST:
        case UPDATE_BUSINESS_ACH_REQUEST:
        case UPDATE_BUSINESS_CC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };
        case UPDATE_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            }
         case UPDATE_BUSINESS_RESET:
            return {
                ...state,
                isUpdated: false,
            }
        // ACH 
        case UPDATE_BUSINESS_ACH_SUCCESS:
            return {
                ...state,
                loading: false,
                isAchUpdated: payload,
            };
        case UPDATE_BUSINESS_ACH_FAIL:
            return {
                ...state,
                loading: false,
                errorAch: payload,
            }
        case UPDATE_BUSINESS_ACH_RESET:
            return {
                ...state,
                isAchUpdated: false,
            }
        // Credit card
        case UPDATE_BUSINESS_CC_SUCCESS:
            return {
                ...state,
                loading: false,
                isCCUpdated: payload,
            }
        case UPDATE_BUSINESS_CC_FAIL:
            return {
                ...state,
                loading: false,
                errorCC: payload,
            }
        case UPDATE_BUSINESS_CC_RESET:
            return {
                ...state,
                isCCUpdated: false,
            }
        // Radius
        case UPDATE_BUSINESS_DATA_REQUEST:
            return {
                ...state,
                loadingRadius: true,
            };
        case UPDATE_BUSINESS_DATA_SUCCESS:
            return {
                ...state,
                loadingRadius: false,
                isRadiusUpdated: payload,
            }
        case UPDATE_BUSINESS_DATA_FAIL:
            return {
                ...state,
                loadingRadius: false,
                errorRadius: payload,
            }
        case UPDATE_BUSINESS_DATA_RESET:
            return {
                ...state,
                isRadiusUpdated: false,
            }
        // Pay ACH Payment
        case PAY_ACH_REQUEST:
            return {
                ...state,
                loadingACHPay: true,
            };
        case PAY_ACH_SUCCESS:
            return {
                ...state,
                loadingACHPay: false,
                isACHPay: payload,
            }
        case PAY_ACH_FAIL:
            return {
                ...state,
                loadingACHPay: false,
                errorACHPay: payload,
            }
        case PAY_ACH_RESET:
            return {
                ...state,
                isACHPay: false,
                errorACHPay: null,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const setBusinessSession = (state = { userDetail: {} }, { type, payload }) => {
    switch (type) {
        case USER_BUSINESS_SESSION:
            return {
                ...state,
                userDetail: payload,
            };
        case USER_BUSINESS_ENDSESSION:
            return {
                ...state,
                userDetail: {},
            }
        default:
            return state;
    }
}

export const businessDetailsReducer = (state = { businessUserDetail: {} }, { type, payload }) => {
    switch (type) {
        case BUSINESS_USER_DETAIL_REQUEST:
            return {
                loading: true,
            };
        case BUSINESS_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                businessUserDetail: payload,
            };
        case BUSINESS_USER_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                businessUserDetail: null,
                error: payload,
            };
        default:
            return state;
    }
}