import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver'
import { clearOrderErrors, downloadPhoneTrips, getTripsFilterOrders } from "../../../../store/actions/businessActions/tripRequested";
import Loading from "../../../../layout/Loading";
import uberEats from '../../../../assests/images/business/trips-requested/newubereats.png';
import grubhub from '../../../../assests/images/business/trips-requested/newgh_cropped.png';
import doordash from '../../../../assests/images/business/trips-requested/newdd_cropped.png';
import slice from '../../../../assests/images/business/trips-requested/slice.png';
import odds from '../../../../assests/images/business/trips-requested/odds_trips.png';
import { Modal, Table } from "react-bootstrap";
import { DOWNLOAD_PHONE_RESET } from "../../../../store/types/businessTypes/tripsRequested";
import { gteOrderInvoice } from "../../../../store/actions/businessActions/businessOrders";
import OrderInvoice from "../StatSection/OrderInvoice";
import moment from "moment";
import axios from "axios";
import { DirectionsRenderer, GoogleMap } from "@react-google-maps/api";
import generatePDF, { Margin } from 'react-to-pdf';

const TripContent = () => {

    const dispatch = useDispatch();
    const allOrdersRefs = {}
    const reportTemplateRef = useRef(null);

    const [displayLoading, setDisplayLoading] = useState(false);
    const [tripDate, setTripDate] = useState('');
    const [phoneDateStart, setPhoneDateStart] = useState('');
    const [phoneDateEnd, setPhoneDateEnd] = useState('');
    const [active, setActive] = useState(null);
    const [activeChange, setActiveChange] = useState(false);

    const [show, setShow] = useState(false);
    const [map, setMap] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [mapDistance, setMapDistance] = useState("");
    const [mapTime, setMapTime] = useState("");
    const [mapLat, setMapLat] = useState("");
    const [mapLong, setMapLong] = useState("");

    const { userDetail } = useSelector((state) => state.userDetails);
    const { downloadPhone, loading } = useSelector((state) => state.downloadPhones);
    const { tripsOrders, tripLoading, tripError, tripActive } = useSelector((state) => state.tripOrders);
    const { businessUserDetail } = useSelector((state) => state.businessUserDetails);

    const [caTax, setCaTax] = useState(false);
    const [pdfDownload, setPdfDownload] = useState(false);
    const [restaurantName, setRestaurantName] = useState('');
    const [orderId, setOrderId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [displayInvoice, setDisplayInvoice] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // handle map show
    const handleMapShow = (distance, time, lat, long, delAdd, pickAdd) => {
        setMapDistance(distance);
        setMapTime(time);
        setMapLat(lat);
        setMapLong(long);

        const distanceService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            distanceService.route(
                {
                    origin: pickAdd,
                    destination: delAdd,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (response, status) => {
                if (status === 'OK') {
                   
                    setDirectionsResponse(response);
                    directionsRenderer.setDirections(response);

                    handleShow()

                } else {
                    alert('Error fetching distance:', status);
                }
                }
            ); 
    }

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setTripDate(formattedDate);
        setPhoneDateStart(formattedDate);
        setPhoneDateEnd(formattedDate);
    }, []);
    
    const handleTripChange = (event) => {
        setTripDate(event.target.value);
    };

    const handleFeChange = (event) => {
        setPhoneDateEnd(event.target.value);
    };

    const handleFsChange = (event) => {
        setPhoneDateStart(event.target.value);
    };

    // handle filter click
    const handleFilterClick = (event) => { 
        setDisplayLoading(true);
        setActive(event.target.id);
        setActiveChange(true);
    }

    // handle download phone
    const handleDownloadPhone = () =>{

        const formData = new FormData();
        formData.set("start_date", phoneDateStart);
        formData.set("end_date", phoneDateEnd);
        formData.set("customer_obj_id", userDetail && userDetail.userId);

        dispatch(downloadPhoneTrips(formData));
        
    }

    // handle get invoice
    const getInvoice = (id, type) => {
        setOrderId(id);
        setOrderType(type);
        setDisplayInvoice(true);
        dispatch(gteOrderInvoice(id, type))
    }

    // handle contact message click
    const handleMessgeCLick = (id) => {
        if(allOrdersRefs[id].style.display === "table-row"){
            allOrdersRefs[id].style.display = "";
            document.getElementById(id+"_orderfiltermsg").innerHTML = '';
        } else {

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}/getordersms/${id}`,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
                },
            })
            .then(function (response) {
                if(response.data){
                    console.log(response)
                    var messageData = '';
                    response.data.map((item,i)=>(
                        messageData += '<div class="w-1/4 orders_messages_div"><p class="mb-0 font-semibold">Admin Notification</p><p class="mb-0">'+item.text+'</p><p class="mb-0">SMS TO '+item.contactPhone+'</p><p class="mb-0">'+(new Date(item.timestamp).toLocaleString("en-US"))+'</p></div>'
                    ))

                    document.getElementById(id+"_orderfiltermsg").innerHTML = '<td colSpan="11"><div class="flex py-1.5 px-3.5 gap-[15px]">'+messageData+'</div></td>';

                    allOrdersRefs[id].style.display = "table-row";
                }
            })
            .catch(function (response) {
                console.log(response);
                document.getElementById(id+"_orderfiltermsg").innerHTML = '';
            });
        }
    }

    // handle select date filter
    const handleSelectDateFilter = () => {
        setDisplayLoading(true)
        const formData = {
            "filter_value" : "bydate",
            "filter_date" : tripDate,
            "customer_obj_id": userDetail && userDetail.userId,
        }

        dispatch(getTripsFilterOrders(formData))
        setActive(null);
    }

    const options = {
        // default is `save`
        method: 'open',
        filename: 'report.pdf',
        page: {
           // margin is in MM, default is Margin.NONE = 0
           margin: Margin.SMALL,
           format: 'A4',
           orientation: 'portrait',
        },
        overrides: {
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                onclone: async function (doc) {
                    doc.querySelector('.pdf_generate_head').style.display = "block";
                    doc.querySelector('.pdf_generate_subhead').style.display = "block";
                }
            }
        },
    };


    useEffect(() => {
        if(downloadPhone && loading === false){
            setDisplayLoading(false)
            const worksheet = XLSX.utils.json_to_sheet(downloadPhone, { origin: 'A2', skipHeader: true });
            const workbook = XLSX.utils.book_new();

            const Heading = [
                ['Id', 'Name', 'Telephone', 'Address']
            ];
            XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
            FileSaver.saveAs(blob, `telephone.xlsx`);
            // console.log(downloadPhone)
            dispatch({ type: DOWNLOAD_PHONE_RESET})

        } 

        if(businessUserDetail && businessUserDetail.status === 1){
            setCaTax(businessUserDetail.businessdata.california_tax === 1 ? true : false);
            setPdfDownload(businessUserDetail.businessdata.excel_download === 1 ? true : false);
            setRestaurantName(businessUserDetail.businessdata.restaurant_name ? businessUserDetail.businessdata.restaurant_name : '');
        }

        if(loading === true){
            setDisplayLoading(true)
        }

        if (tripError) {
            console.log(tripError)
            dispatch(clearOrderErrors());
        }

        if(activeChange){
            setDisplayLoading(true)
            setActiveChange(false)
            const formData = {
                "filter_value" : active,
                "customer_obj_id": userDetail && userDetail.userId,
            }
    
            dispatch(getTripsFilterOrders(formData))
        }

        if(tripActive === null){
            setActive(null)
        } else{
            setActive(active)
        }

        if(tripLoading === false && tripsOrders && tripsOrders.status === 1){
            setDisplayLoading(false)
        }

    }, [dispatch, downloadPhone, loading, activeChange, active, userDetail, tripLoading, tripsOrders, tripError, tripActive, businessUserDetail])

    return(
        <>
            {displayLoading &&
                <Loading />
            }
            <div className="trip-content p-2.5 flex flex-col">
                <div className="custom_trips_table_outer block">
                    <div className="flex flex-col sm:flex-row items-center justify-around gap-[20px]">
                        <button className={`order_today ${active === 'today' ? "active" : ""}`} id="today" onClick={handleFilterClick}>Today Financials</button>
                        <button className={`order_today ${active === 'yesterday' ? "active" : ""}`} id="yesterday" onClick={handleFilterClick}>Yesterday Financials</button>
                        <button className={`order_today ${active === 'thisweek' ? "active" : ""}`} id="thisweek" onClick={handleFilterClick}>This Week Financials</button>
                        <button className={`order_today ${active === 'lastweek' ? "active" : ""}`} id="lastweek" onClick={handleFilterClick}>Last Week Financials</button>
                    </div>
                    <div className="hidden sm:flex items-center justify-center gap-[20px] mt-30px">
                        <div>
                            <input type="date" name="fs_trip_date" id="fs_trip_date" className="trip-date" value={tripDate} onChange={handleTripChange}/>
                        </div>
                        <button className="order_today green" onClick={() => handleSelectDateFilter()}>Select Date</button>
                        <div className="flex flex-col lg:flex-row items-center gap-2.5">
                            <input type="date" name="fs_date3" id="fs_date3" className="trip-date" value={phoneDateStart} onChange={handleFsChange} />
                            <span>To</span>
                            <input type="date" name="fe_date3" id="fe_date3" className="trip-date" value={phoneDateEnd} onChange={handleFeChange} />
                        </div>
                        <button className="order_today green" onClick={handleDownloadPhone}>Download Phone #'s</button>
                    </div>
                </div>

                

                {tripsOrders && tripsOrders.status === 1 && (
                <div ref={reportTemplateRef} className="pdf_generate">

                    <h2 className="pdf_generate_head hidden text-center pb-50">{restaurantName} </h2>
                    <h2 className="pdf_generate_subhead hidden text-center pb-30px">Financial Report {tripsOrders.search_time_text}</h2>
                
                    <div className="saving_table grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-[20px] w-full">
                        <div className="delivery_sales saving_table_div">
                            Your Delivery Sales <br/> ${tripsOrders.total_amount_sum}
                        </div>
                        <div className="delivery_fee saving_table_div">
                            Total Delivery Fee Collected <br/> ${tripsOrders.total_delivery_recieved_sum.toFixed(2)}
                        </div>
                        <div className="delivery_discount saving_table_div">
                            Self Delivery Discount <br/> ${tripsOrders.total_discount_sum}
                        </div>
                        <div className="delivery_tips saving_table_div">
                            Tips Collected <br/> ${tripsOrders.total_tip_sum}
                        </div>
                        <div className="delivery_inbounds saving_table_div">
                            Additional $ Paid To Store <br/> ${tripsOrders.additional_paid_store}
                        </div>
                        <div className="delivery_total_orders saving_table_div">
                            Total Orders <br/> {tripsOrders.total_order_count}
                        </div>
                        <div className="delivery_average_amount saving_table_div">
                            Average Order Amount <br/> ${tripsOrders.average_order_amount}
                        </div>
                        <div className="buying_fee_red saving_table_div">
                            ODDS Fee For Period <br/> ${tripsOrders.total_calculated_recieved_sum.toFixed(2)}
                        </div>
                        <div className="buying_fee_lastseven saving_table_div">
                            Last 7 Days Savings <br/> ${tripsOrders.last_7_day_savings}
                        </div>
                        <div className="buying_saved saving_table_div">
                            ODDS Saved You ${tripsOrders.total_odds_savings_sum} <br/> {tripsOrders.search_time_text}
                        </div>
                        <div className="delivery_uber_eats saving_table_div">
                            <img src={uberEats} alt="Uber Eats" className="w-20" />
                            <span className="uber_eats_total">${tripsOrders.uber_sum}</span>
                        </div>
                        <div className="delivery_grubhub saving_table_div">
                            <img src={grubhub} alt="Grubhub" className="w-20" />
                            <span className="grubhub_total">${tripsOrders.gh_sum}</span>
                        </div>
                        <div className="delivery_doordash saving_table_div">
                            <img src={doordash} alt="Doordash" className="w-20" />
                            <span className="dd_total">${tripsOrders.dd_sum}</span>
                        </div>
                        <div className="delivery_slice saving_table_div">
                            <img src={slice} alt="Slice" className="w-16" />
                            <span className="slice_total">${tripsOrders.slice_sum}</span>
                        </div>
                        <div className="delivery_odds saving_table_div">
                            <img src={odds} alt="ODDS" className="w-20" />
                            <span className="odds_total">${tripsOrders.odds_sum}</span>
                        </div>
                    </div>
                    <p className="delivery_far capitalize">
                        {tripsOrders.total_longer_trips >= 1 ?
                            <span>Orders in yellow were outside the range of uber - GH - DD. Orders were taken by ODDS <br/>
                                Total longer trips ({tripsOrders.total_longer_trips} ${tripsOrders.total_yellow_order_sum})</span>
                        :
                            <span>No Long Distance Trips For This Time Period {tripsOrders.search_time_text}</span>
                        }

                        {pdfDownload && <button data-html2canvas-ignore="true"  onClick={() => generatePDF(reportTemplateRef, options)}>Download</button>}
                    </p>

                    <Table responsive className="trips-requested-orders w-full trips_data" >
                        <thead>
                            <tr>
                                <th>Provider of Order</th>
                                <th>Total Amount</th>
                                <th>Customer Information</th>
                                <th>Order Date Placed</th>
                                <th>Order Time Delivered</th>
                                <th>Tip Assigned</th>
                                <th>Delivery Discount</th>
                                <th>Delivery Fee Received</th>
                                <th>Delivery Fee Calculated</th>
                                <th data-html2canvas-ignore="true">Map/Distance SMS Message</th>
                                {caTax &&
                                    <th>Ca Tax/22</th>
                                }
                                <th>ODDS Savings</th>
                            </tr>
                        </thead>
                        <tbody>

                            {tripsOrders.orderdetails.length >= 0 && tripsOrders.orderdetails.map((item,i) => (
                                <Fragment key={i}>
                                    <tr className={`${item.yellow_order === 1 ? 'bg-yellow' : ''}`}>
                                        <td>
                                            {item.order_type_image && (
                                                item.order_type === 4 || item.order_type === 3 || item.order_type === 6 || item.order_type === 2 ?
                                                    <img src={item.order_type_image} alt="Order Type" className="w-full mx-auto cursor-pointer h-[90px] object-contain" onClick={()=> getInvoice(item._id, item.order_type)} />
                                                :
                                                    <img src={item.order_type_image} alt="Order Type" className="w-full mx-auto h-[90px] object-contain" />
                                            )}
                                        </td>
                                        <td>{item.total_amount}</td>
                                        <td style={{textAlign: "left"}}>
                                            <p className="text-sm mb-0">
                                                {item.delivery_firstname} <br/>
                                                {item.delivery_address}
                                            </p>
                                        </td>
                                        <td>{moment(item.date_added).format("MM/DD/Y")}</td>
                                        <td>{moment(item.date_added).format("HH:mm a")}</td>
                                        <td>${item.order_tip}</td>
                                        <td>${item.delivery_discount}</td>
                                        <td>${item.delivery_fee_recieved.toFixed(2)}</td>
                                        <td>${item.total_delivery_fee_calculated.toFixed(2)}</td>
                                        <td data-html2canvas-ignore="true">
                                            <span className="text-primary-blue cursor-pointer" onClick={() => handleMapShow(item.order_mileage_distance, item.order_time_distance, item.delivery_lat, item.delivery_long, item.delivery_address, item.pickup_address )}>MAP</span> / <span className="text-primary-blue cursor-pointer" onClick={() => handleMessgeCLick(item._id)}>MSG</span>
                                        </td>
                                        {caTax &&
                                            <td>${item.order_california_tax ? item.order_california_tax : 0}</td>
                                        }
                                        <td>${item.odds_savings}</td>
                                    </tr>

                                    {/* Orders messages */}
                                    <tr ref={ref => allOrdersRefs[item._id] = ref} className="orders-button-messages hidden" id={item._id+"_orderfiltermsg"} key={item._id+"_orderfiltermsg"}>
                                    </tr>

                                </Fragment>
                            ))}

                            {/* Total Rows */}
                            <tr className="font-bold">
                                <td></td>
                                <td>Total Sales</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total Tips</td>
                                <td>Self Delivery Discount</td>
                                <td>Delivery Fee By Restaurant</td>
                                <td>Delivery Fee Calculated</td>
                                <td data-html2canvas-ignore="true"></td>
                                {caTax &&
                                    <td></td>
                                }
                                <td>ODDS Savings</td>
                            </tr>
                            <tr className="total_rows">
                                <td></td>
                                <td>${tripsOrders.total_amount_sum}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>${tripsOrders.total_tip_sum}</td>
                                <td>${tripsOrders.total_discount_sum}</td>
                                <td>${tripsOrders.total_delivery_recieved_sum.toFixed(2)}</td>
                                <td>${tripsOrders.total_calculated_recieved_sum.toFixed(2)}</td>
                                <td data-html2canvas-ignore="true"></td>
                                {caTax &&
                                    <td></td>
                                }
                                <td>${tripsOrders.total_odds_savings_sum.toFixed(2)}</td>
                            </tr>
                            {/* Total bottom rows */}
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Total Order Request</td>
                                <td>{tripsOrders.total_order_count}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>ODDS Delivery Fee</td>
                                <td>${tripsOrders.total_calculated_recieved_sum.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Third Party Tips</td>
                                <td>${tripsOrders.total_tip_sum}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Refund</td>
                                <td>${tripsOrders.refund_amount}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Total Charge</td>
                                <td>${tripsOrders.total_charge.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} className="bg-gray" style={{textAlign: "left"}}>Saving Financial Formula</td>
                                <td className="bg-gray"></td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Delivery Fee Collected For Store</td>
                                <td>${tripsOrders.total_delivery_recieved_sum.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Self Delivery Discount For Store</td>
                                <td>${tripsOrders.total_discount_sum.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>ODDS Fee for Delivery</td>
                                <td>${tripsOrders.total_calculated_recieved_sum.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>ODDS Saved You</td>
                                <td>${tripsOrders.total_odds_savings_sum.toFixed(2)}</td>
                            </tr>
                            <tr className="font-bold">
                                <td colSpan={7}></td>
                                <td data-html2canvas-ignore="true"></td>
                                <td colSpan={caTax ? 3 : 2} style={{textAlign: "left"}}>Sales Increased By Longer Trips ({tripsOrders.total_longer_trips})</td>
                                <td>${tripsOrders.total_yellow_order_sum}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                )}
            </div>

            {/* Order Invoice */}
            {displayInvoice &&
                <OrderInvoice id={orderId} orderType={orderType} setDisplayInvoice={setDisplayInvoice} />
            }

            {/* Map Modal */}
            <Modal show={show} onHide={handleClose} centered={true} className="popup-map-modal" backdropClassName="dashboard-backdrop">

                <Modal.Header closeButton>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Satellite Distance <br/> {mapDistance} Miles {mapTime} Minutes <br/>Locked Wheel Movement</h4>
                    <h4 className="text-base font-bold font-alata mb-0 p-2.5 leading-none">Latitude: {mapLat}<br/> Longitude: {mapLong}</h4>
                </Modal.Header>
                
                <Modal.Body className="relative">
                    <GoogleMap
                        center= {{lat: 40.0583, lng: 74.4057}}
                        zoom={7}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        onLoad={(map) => setMap(map)}
                        options={{
                            zoomControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeId: "satellite",
                        }}
                    >
                        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                    </GoogleMap>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TripContent