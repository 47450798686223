import MetaData from "../../layout/MetaData"
import './SalesMeeting.css'
import zoomLogo from '../../assests/images/sales-meeting/zoom-logo.png';
import oddsBenefits from '../../assests/images/sales-meeting/odds-benefits-communication.png';

const SalesMeeting = () => {
    return(
        <>
            <MetaData
                title={"Sales Meeting - ODDS - On Demand Delivery Solution"}
            />

            <section className="sales-meeting-section">
                <div className="max-w-screen-1xl mx-auto py-50 px-2.5">
                    <div className="w-full flex flex-col gap-[20px]">
                        <h1 className='font-semibold text-primary-text text-[48px] md:text-[56px] text-center'>Sales Meeting</h1>
                        <div className="p-20px div-box-shadow rounded-[15px]">
                            <p className="mb-0 text-lg text-center">Thank you for visiting and applying for a sales position at OddsDrive.com. <br/>
                            Please review the links below, and register to join <strong> our upcoming Zoom call on Wednesday at 5:30 PM (Eastern Standard Time).</strong><br />You only need to join the call once. We appreciate your interest and look forward to connecting with you.</p>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-[50px] mt-50">
                        <div className="w-full md:w-1/2 p-30px div-box-shadow rounded-[20px]">
                            <h2 className="mb-0 text-black text-[28px] font-semibold">Sales Representative</h2>
                            <h2 className="text-[#23a455] font-roboto font-semibold text-xl mb-20px"><strong>Ongoing</strong></h2>
                            <h2 className="font-semibold text-xl mb-2.5">Commission + Residual Commission</h2>
                            <p>Freelance (1099)</p>
                            <p className="pt-15px">Remote USA / Remote Canada</p>
                            <div className="py-2.5">
                                <a href="/join-our-sales-team" className="w-full leading-none block rounded-[10px] bg-[#35da4b] text-black py-15px px-30px text-center no-underline hover:underline">More Info</a>
                            </div>
                        </div>

                        <div className="w-full md:w-1/2 px-20px py-50 bg-[#f0f0f0] rounded-[20px] text-center">
                            <img src={zoomLogo} alt="Zoom" className="w-52 mx-auto mb-15px" />
                            <p>Join Zoom Call <strong>5:30 PM EST Time Every Wednesday</strong></p>
                            <div className="py-20px">
                                <a href="https://us06web.zoom.us/j/85325295869?pwd=WkFweEMvQUNVRXd2SXFJTG9MeU5nQT09" className="uppercase bg-[#2493e2] text-white py-15px px-25px inline-block rounded-[10px] leading-none text-center no-underline hover:underline">Join Zoom Meeting</a>
                            </div>
                            <img src={oddsBenefits} alt="ODDS Benefits" className="w-[250px] mx-auto" />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default SalesMeeting