import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, detailSalesUser, updateSalesPassword } from "../../../../store/actions/salesActions/salesUsers";
import { UPDATE_SALES_PASSWORD_RESET } from "../../../../store/types/salesTypes/salesUsers";

const ChangePassword = () =>{

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [objectId, setObjectId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { salesUserDetail, loading } = useSelector((state) => state.salesUserDetails);
    const { errorPass, isPassUpdated } = useSelector((state) => state.salesProfile);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();

        if(newPassword !== confirmPassword){
            enqueueSnackbar("Password not match", { variant: "error" });
            return
        }

        const formData = new FormData();
        formData.set("obj_id", objectId);
        formData.set("customer_id", customerId);
        formData.set("old_password", oldPassword);
        formData.set("new_password", newPassword);

        dispatch(updateSalesPassword(formData))
    }

    useEffect(() => {
        if(loading === false && salesUserDetail && salesUserDetail.status === 1){
            setObjectId(salesUserDetail.user._id)
            setCustomerId(salesUserDetail.user.customer_id);
            setEmail(salesUserDetail.user.email);
        }

        // Profile Error
        if (errorPass) {
            enqueueSnackbar(errorPass, { variant: "error" });
            dispatch(clearErrors());
        }

        // Profile updated
        if (isPassUpdated) {

            enqueueSnackbar("Password Updated Successfully.", { variant: "success" });

            var userDetails = {
                obj_id: objectId,
                email: email,
                customer_id: customerId,
            }
            dispatch(detailSalesUser(userDetails))
            dispatch({ type: UPDATE_SALES_PASSWORD_RESET });

            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        }
        

    }, [dispatch, loading, salesUserDetail, errorPass, enqueueSnackbar, objectId, email, customerId, isPassUpdated])


    return(
        <Form className="sales-profile-form business_profile_form" method="post" onSubmit={handlePasswordSubmit}>
            <Form.Group className="mb-20px" controlId="old_password">
                <Form.Label>Old password</Form.Label>
                <Form.Control type="password" name="old_password" required value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-20px" controlId="new_password">
                <Form.Label>New password</Form.Label>
                <Form.Control type="password" name="new_password" required value={newPassword} onChange={(e) => setNewPassword(e.target.value)} minLength={8}/>
            </Form.Group>

            <Form.Group className="mb-20px" controlId="confirm_password">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control type="password" name="confirm_password" required value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className="text-left">
                <Button type="submit" className="btn-profile-submit">Submit</Button>
            </Form.Group>

        </Form>
    )
}

export default ChangePassword