import integrationImg from '../../../assests/images/home/food-agg-logos.png';
import logo from '../../../assests/images/odds-logo.png';

const EasyIntegration = () => {
    return(
        <section className="home-easy-integration bg-[#f0f0f0]" id="integrate">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-2/5 px-2.5 py-15px">
                    <img
                        src={integrationImg}
                        alt='Integration'
                        className='w-full'
                    />
                </div>

                <div className="w-full md:w-3/5 py-30px px-2.5 md:px-[55px] flex flex-col gap-[20px]">
                    <img
                        src={logo}
                        alt='ODDS Logo'
                        className='w-[250px] mb-20px'
                    />
                    <h6 className='text-primary-green uppercase font-semibold tracking-[1px] mb-0'>Integration</h6>
                    <h3 className='text-4xl font-semibold'>Easy integration with 28 POS aggregators!</h3>
                    <p className='text-xl mb-0 text-primary-text md:mr-30px'>Our solution integrates with all aggregators including your sales app, website, POS or online ordering system.</p>
                </div>

            </div>
        </section>
    )
}

export default EasyIntegration