import MetaData from "../../../layout/MetaData"
import DashboardHeader from "./DashboardHeader";
import './Dashboard.css'
import DriverNowSection from "./DriverNowSection/DriverNowSection";
import FooterButtons from "./FooterButtons/FooterButtons";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, detailBusinessUser, emptyBusinessSession } from "../../../store/actions/businessActions/businessUsers";
import { useEffect, useState } from "react";
import DeliveredOrders from "./DeliveredOrders/DeliveredOrders";
import DelayDriver from "./DelayDriver/DelayDriver";
import StatSection from "./StatSection/StatSection";
import { TabProvider } from "./TabContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Dashboard = () => {

    const dispatch = useDispatch();
    const { userDetail } = useSelector((state) => state.userDetails);

    const { error } = useSelector((state) => state.businessUserDetails);

    const [topBar, setTopBar] = useState(false);

    useEffect(() => {

        if(userDetail && userDetail.userId){
           
            var userDetails = {
                obj_id: userDetail.userId,
                email: userDetail.userEmail,
                customer_id: userDetail.customerId,
            }
            dispatch(detailBusinessUser(userDetails))
        }

        if(error){
            dispatch(emptyBusinessSession())
            dispatch(clearErrors())
        }
        
    }, [dispatch, userDetail, error])

    return(
        <>
            <MetaData
                title={"Driver Now"}
            />
            <section className="business-dashboard sales-pages">
                {/* Top banner */}
                <div className={`hidden sm:flex justify-between items-center top-banner max-w-screen-2xl mx-auto text-center bg-[#15c701] text-white rounded-bl rounded-br overflow-hidden ${topBar && 'open'}`}>
                    <p className="m-0 p-2.5 w-full">Do you know another Business that can benefit from our solution, refer them <a href='/refer' className="bg-[#f3b167] px-2.5 py-0.5 text-white no-underline rounded-2xl">HERE</a>  and earn $1000.</p>
                    <FontAwesomeIcon icon={faClose} className="p-2.5 cursor-pointer" onClick={() => setTopBar(!topBar)} />
                </div>

                {/* header */}
                <DashboardHeader />

                {/* main section */}
                <div className="max-w-screen-2xl mx-auto px-12px">
                    <div className="main-inner p-20px">
                        <TabProvider>
                            {/* Driver Now Section */}
                            <DriverNowSection />

                            {/* Delay Driver */}
                            <DelayDriver />

                            {/* Stat Section */}
                            <StatSection />

                            {/* Delivered Orders */}
                            <DeliveredOrders />

                            {/* Footer Section */}
                            <FooterButtons />
                        </TabProvider>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Dashboard