// Get phone count
export const GET_PHONE_COUNT_REQUEST = "GET_PHONE_COUNT_REQUEST";
export const GET_PHONE_COUNT_SUCCESS = "GET_PHONE_COUNT_SUCCESS";
export const GET_PHONE_COUNT_FAIL = "GET_PHONE_COUNT_FAIL";

// Upload Ads Image
export const ADD_ADS_IMAGE_REQUEST = "ADD_ADS_IMAGE_REQUEST";
export const ADD_ADS_IMAGE_SUCCESS = "ADD_ADS_IMAGE_SUCCESS";
export const ADD_ADS_IMAGE_FAIL = "ADD_ADS_IMAGE_FAIL";

// Get Image List
export const GET_IMAGE_LIST_REQUEST = "GET_IMAGE_LIST_REQUEST";
export const GET_IMAGE_LIST_SUCCESS = "GET_IMAGE_LIST_SUCCESS";
export const GET_IMAGE_LIST_FAIL = "GET_IMAGE_LIST_FAIL";

// Delete Ads Image
export const DELETE_ADS_IMAGE_REQUEST = "DELETE_ADS_IMAGE_REQUEST";
export const DELETE_ADS_IMAGE_SUCCESS = "DELETE_ADS_IMAGE_SUCCESS";
export const DELETE_ADS_IMAGE_RESET = "DELETE_ADS_IMAGE_RESET";
export const DELETE_ADS_IMAGE_FAIL = "DELETE_ADS_IMAGE_FAIL";

// Get SMS Queue List
export const GET_QUEUE_LIST_REQUEST = "GET_QUEUE_LIST_REQUEST";
export const GET_QUEUE_LIST_SUCCESS = "GET_QUEUE_LIST_SUCCESS";
export const GET_QUEUE_LIST_FAIL = "GET_QUEUE_LIST_FAIL";

// Create Ads
export const CREATE_ADS_REQUEST = "CREATE_ADS_REQUEST";
export const CREATE_ADS_SUCCESS = "CREATE_ADS_SUCCESS";
export const CREATE_ADS_FAIL = "CREATE_ADS_FAIL";

// Send Text Ads SMS
export const SEND_ADS_SMS_REQUEST = "SEND_ADS_SMS_REQUEST";
export const SEND_ADS_SMS_SUCCESS = "SEND_ADS_SMS_SUCCESS";
export const SEND_ADS_SMS_FAIL = "SEND_ADS_SMS_FAIL";
export const SEND_ADS_SMS_RESET = "SEND_ADS_SMS_RESET";

// Delete Ads Image
export const DELETE_QUEUE_LIST_REQUEST = "DELETE_QUEUE_LIST_REQUEST";
export const DELETE_QUEUE_LIST_SUCCESS = "DELETE_QUEUE_LIST_SUCCESS";
export const DELETE_QUEUE_LIST_RESET = "DELETE_QUEUE_LIST_RESET";
export const DELETE_QUEUE_LIST_FAIL = "DELETE_QUEUE_LIST_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";