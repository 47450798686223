import { Link } from "react-router-dom"
import MetaData from "../../layout/MetaData"
import circleCheck from '../../assests/images/check-circle.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Pricing = () => {

    const listItems = [
        {
            title: "10 mile radius",
            description: "We increase delivery radius up to 10 mile.",
        },
        {
            title: "On demand drivers",
            description: "On demand drivers when you need them.",
        },
        {
            title: "24/7 driver availability",
            description: "Our drivers are available 24/7 every day in USA and Canada.",
        },
        {
            title: "Flat delivery fee",
            description: "Flat fee for deliveries making your costs simple.",
        }
    ]
    return(
        <>
            <MetaData 
                title={"Pricing – ODDS – On Demand Delivery Solution"}
            />

            <section className="pricing-section relative overflow-hidden bg-[#f2f4fc]">
                <div className="max-w-screen-1xl mx-auto py-50 text-primary-text33 flex flex-col md:flex-row">
                    <div className="w-full md:w-[45%] p-2.5">
                        <h3 className="text-[32px] text-[#1d2023] font-semibold mb-20px">Simple pricing that won't break the bank. Cancel any time.</h3>
                        <div className="py-2.5">
                            <a href="#pricing" className="inline-block font-semibold uppercase text-primary-white text-[15px] bg-[#48a9a6] border-[3px] border-[#48a9a6] px-30px py-14px no-underline rounded-50 leading-none tracking-[.7px] hover:bg-white hover:text-[#48a9a6]">Get Started</a>
                        </div>
                        
                    </div>
                    <div className="w-full md:w-[55%] p-2.5">
                        <div className="flex flex-col md:flex-row flex-wrap lg:px-30px">
                            {listItems.map((item,i) => (
                                <div key={i} className="w-full md:w-1/2 flex items-start p-2.5">
                                    <img src={circleCheck} alt="Circle Check" className="mr-20px w-full max-w-9" />
                                    <div className="box-content">
                                        <h4 className="text-[#1d2023] text-lg mb-2.5 font-semibold">{item.title}</h4>
                                        <p className="mb-2.5">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-details-section relative overflow-hidden my-30px" id="pricing">
                <div className="max-w-screen-1xl pt-50 pb-100px mx-auto text-primary-text33">
                    <div className="w-full p-2.5">
                        <h2 className="uppercase text-primary-text text-[56px]">Pricing</h2>
                    </div>
                    <div className="w-full p-2.5 flex flex-col md:flex-row gap-[20px]">
                        <div className="w-full md:w-1/3 py-2.5 px-[5px] bg-primary-bgForms rounded-[50px] flex flex-col gap-2.5 border border-[#d1d0d0]">
                            <div className="flex flex-col gap-[20px] p-2.5">
                                <h5 className="text-center text-black font-semibold">Food Aggregator Orders</h5>
                                <ul className="p-0">
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>Up to 10 mile delivery range</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>Drivers available in all ZIP codes in the US and Canada</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>On demand delivery drivers when you need them</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>24/7 tech support</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-2.5 p-2.5 text-center">
                                <h4 className="uppercase text-primary-blue font-semibold m-0">Standard</h4>
                                <h4 className="text-primary-blue font-semibold m-0">$ 7.89</h4>
                                <p className="mb-0">Delivery Fee</p>
                                <div className="mt-2.5">
                                    <Link reloadDocument={true} to="/register" className="inline-block uppercase text-primary-white text-[15px] bg-primary-blue border-[3px] border-primary-blue px-6 py-12px no-underline rounded-50 leading-none hover:bg-white hover:text-primary-blue">Get Started</Link>
                                </div>
                            </div>
                        </div>

                        {/* internal orders */}
                        <div className="w-full md:w-1/3 py-2.5 px-[5px] bg-primary-bgForms rounded-[50px] flex flex-col gap-2.5 border border-[#d1d0d0]">
                            <div className="flex flex-col gap-[20px] p-2.5">
                                <h5 className="text-center text-black font-semibold">Internal Orders</h5>
                                <ul className="p-0">
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>Up to 10 mile delivery range</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>Drivers available in all ZIP codes in the US and Canada</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>On demand delivery drivers when you need them</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>24/7 tech support</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-2.5 p-2.5 text-center">
                                <h4 className="text-primary-blue font-semibold m-0">$ 7.89</h4>
                                <p className="mb-0">Delivery Fee</p>
                                <p className="mb-0">+</p>
                                <h4 className="text-primary-blue font-semibold m-0">$ 4.50</h4>
                                <p className="mb-0">Automatic driver tip</p>
                                <div className="mt-2.5">
                                    <Link reloadDocument={true} to="/register" className="inline-block uppercase text-primary-white text-[15px] bg-primary-blue border-[3px] border-primary-blue px-6 py-12px no-underline rounded-50 leading-none hover:bg-white hover:text-primary-blue">Get Started</Link>
                                </div>
                            </div>
                        </div>

                        {/* Catering Orders */}
                        <div className="w-full md:w-1/3 py-2.5 px-[5px] bg-primary-bgForms rounded-[50px] flex flex-col gap-2.5 border border-[#d1d0d0]">
                            <div className="flex flex-col gap-[20px] p-2.5">
                                <h5 className="text-center text-black font-semibold">Catering Orders</h5>
                                <ul className="p-0">
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>10 mile radius</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>24/7 driver availability</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>On demand drivers when you need them</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>Flat delivery fee</span>
                                    </li>
                                    <li className="flex items-center">
                                        <FontAwesomeIcon icon={faCheck} className="mr-20px "/>
                                        <span>24/7 tech support</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col gap-2.5 p-2.5 text-center">
                                <h4 className="text-primary-blue font-semibold m-0">$ 24.99</h4>
                                <p className="mb-0">Delivery Fee</p>
                                <p className="mb-0">+</p>
                                <h4 className="text-primary-blue font-semibold m-0">$ 9.00</h4>
                                <p className="mb-0">Automatic driver tip</p>
                                <div className="mt-2.5">
                                    <Link reloadDocument={true} to="/register" className="inline-block uppercase text-primary-white text-[15px] bg-primary-blue border-[3px] border-primary-blue px-6 py-12px no-underline rounded-50 leading-none hover:bg-white hover:text-primary-blue">Get Started</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="pricing-contact-section bg-gradient-to-l from-primary-blue to-[#6ec1e4] text-white mb-1.5">
                <div className="max-w-screen-1xl mx-auto py-[60px]">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-[70%] p-2.5">
                            <h2 className="font-semibold text-[40px]">Ready to grow your business?</h2>
                        </div>
                        <div className="w-full md:w-[30%] p-2.5 md:text-right">
                            <a href="#pricing" className="inline-block font-semibold text-primary-white text-[15px] bg-inherit border-2 border-white px-10 py-20px no-underline rounded-50 leading-none tracking-[.7px] hover:bg-white hover:text-black">Get Started</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing