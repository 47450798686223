import { useState } from "react";
import { Button, Form, Table } from "react-bootstrap"

const AddSalesReport = () =>{

    const [salesReport, setSalesReport] = useState('');

    const handleReportSubmit = (e) => {
        e.preventDefault();
    }

    return(
        <div className="px-15px py-6 w-full xl:w-3/4 mx-auto">
            <Form className="sales-add-report-form business_profile_form" method="post" onSubmit={handleReportSubmit} encType="multipart/form-data">
                <Form.Group className="mb-20px" controlId="salesperson_report">
                    <Form.Control type="file" name="salesperson_report" required value={salesReport} onChange={(e) => setSalesReport(e.target.value)} accept=".xlsx, .xls, .csv" className="report_filter_input" />
                </Form.Group>

                <Form.Group className="text-left">
                    <Button type="submit" className="btn-profile-submit">Add Report</Button>
                </Form.Group>

            </Form>

            <Table className="report_table" id="reports_excel_table">
                <thead>
                    <tr>
                        <th className="text-center" style={{width: '10%'}}>S.No.</th>
                        <th className="text-center" style={{width: '30%'}}>Date Added</th>
                        <th style={{width: '60%'}}>Report File</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="grey">
                        <td data-label="S.No." className="text-center">1</td>
                        <td data-label="Date Added" className="text-center">05/21/2024</td>
                        <td data-label="Report File">file</td>
                    </tr> 
                </tbody>
            </Table>
        </div>
    )
}

export default AddSalesReport