const ContactContent = () => {
    return(
        <div className="p-2.5">
            At ODDS, we are dedicated to providing the best customer service in the industry. We are committed to ensuring that all orders meet your expectations.
            Approximately 99% of driving issues can be addressed and resolved through our convenient Support Chat window located at the top right of the page.
            However, in the rare event of a chat error or an order that has not been picked up as scheduled, please don't hesitate to contact our support line: 833-928-9464.
            While chat is usually the quickest way to get assistance, we understand that every business has unique needs. We are here to ensure that all your business needs are met promptly.
        </div>
    )
}

export default ContactContent