import { Accordion } from "react-bootstrap"
import './FAQSection.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"

const FAQSection = () => {
    return(
        <section className="home-faq-section bg-[#f0f0f0]">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 ">
                <div className="w-full p-2.5 flex flex-col items-center gap-[20px]">
                    <h6 className='text-primary-green text-center uppercase font-semibold tracking-[1px] mb-0'>Find out more</h6>
                    <h3 className='text-4xl md:text-5xl text-center font-semibold'>Frequently asked questions</h3>

                    <Accordion defaultActiveKey="0" className="w-full faq_accordation">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <FontAwesomeIcon icon={faPlus} className="plus-accordation-icon" />
                                <FontAwesomeIcon icon={faMinus} className="minus-accordation-icon" />
                                Are there any upfront costs?
                            </Accordion.Header>
                            <Accordion.Body>No, there are no upfront costs, our solution is absolutely free.</Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <FontAwesomeIcon icon={faPlus} className="plus-accordation-icon" />
                                <FontAwesomeIcon icon={faMinus} className="minus-accordation-icon" />
                                Do I have to sign a contract to be able to use the platform?
                            </Accordion.Header>
                            <Accordion.Body>There is no contract to be signed. We are here to partner and help you save money.</Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <FontAwesomeIcon icon={faPlus} className="plus-accordation-icon" />
                                <FontAwesomeIcon icon={faMinus} className="minus-accordation-icon" />
                                Can I use my own hardware?
                            </Accordion.Header>
                            <Accordion.Body>Yes, our solution is web based so you can use it from any computer system that has an internet connection.</Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <FontAwesomeIcon icon={faPlus} className="plus-accordation-icon" />
                                <FontAwesomeIcon icon={faMinus} className="minus-accordation-icon" />
                                How Long Does It Take To Integrate ODDS?
                            </Accordion.Header>
                            <Accordion.Body>Activation takes about 24 - 72 hours.</Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </div>
            </div>
        </section>
    )
}

export default FAQSection