import homeSignup from '../../../assests/images/home/home-signup.webp';

const SignUpSection = () => {
    return(
        <section className="home-signup-section bg-primary-bgBlue">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50">

                <div className='w-full flex flex-col md:flex-row items-center md:gap-[30px]'>
                    <div className="w-full md:w-2/5 p-2.5 flex flex-col gap-[20px]">
                        <h3 className='text-[46px] font-semibold'>Sign up to get your drivers today!</h3>
                        <h6 className="text-xl">Opt for the clear-cut route by avoiding exorbitant commissions. <br/>Join us today and begin your savings journey!</h6>
                    </div>
                    <div className="w-full md:w-3/5 p-2.5">
                        <img
                            src={homeSignup}
                            alt='Sign Up'
                            className='w-full'
                        />
                    </div>
                </div>

                <div className='w-full p-2.5 text-center'>
                    <a href="/register" className="inline-block uppercase text-primary-white text-base font-semibold bg-primary-red border-primary-red border-2 px-[38px] py-20px no-underline rounded-50 leading-none tracking-wider hover:text-primary-red hover:bg-white mt-20px">Get Started</a>
                </div>
            </div>
        </section>
    )
}

export default SignUpSection