import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import eliminateImg from '../../../assests/images/home/eliminate-drivers.webp'

const EliminateDrivers = () => {

    const listItem = [
        "No longer worry about drivers being unreliable or not coming into work when you really need them.",
        "No longer worry about high costs of payroll tax, insurance and liability issues.",
        "Don't struggle with having too many drivers when business is slow and not enough drivers when you get busy."
    ]

    return(
        <section className="home-eliminate-drivers bg-primary-bgBlue">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50 flex flex-col md:flex-row items-center md:gap-[30px]">

                <div className="w-full md:w-[65%] py-30px px-2.5 flex flex-col gap-[20px]">
                    <h6 className='text-primary-green uppercase font-semibold tracking-[1px] mb-0'>No more drivers issues</h6>
                    <h3 className='text-4xl font-semibold'>Eliminate driver issues!</h3>
                    <ul className='p-0 m-0'>
                        {listItem.map((item,i) => (
                            <li key={i} className='flex flex-row items-center pb-2.5'>
                                <FontAwesomeIcon icon={faCheck} className='text-2xl text-primary-blue pr-12px mr-20px' />
                                <span className='text-xl'>{item}</span>
                            </li>
                        ))}
                    </ul>
                    <Link to="#" className="uppercase text-white text-base font-semibold bg-primary-blue mr-auto px-30px py-15px no-underline rounded-50 leading-none tracking-wider border-2 border-primary-blue hover:bg-transparent hover:text-primary-blue">Get Started</Link>
                </div>

                <div className="w-full md:w-2/6 pl-2.5 md:pl-20px py-15px md:py-0 pr-2.5 md:pr-0">
                    <img
                        src={eliminateImg}
                        alt='Eliminate driver'
                        className='w-full'
                    />
                </div>

            </div>
        </section>
    )
}

export default EliminateDrivers