import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Customers.css'

import saladWorks from '../../../assests/images/home/clients//salad-works.jpg';
import pizzeria from '../../../assests/images/home/clients/pizzeria.jpg';
import fresco from '../../../assests/images/home/clients/fresco.jpg';
import cicisPizza from '../../../assests/images/home/clients/cicis-pizza.jpg';
import stamford from '../../../assests/images/home/clients/stamford.jpg';
import homeDepot from '../../../assests/images/home/clients/home-depot.jpg';
import workMenu from '../../../assests/images/home/clients/work-menu.jpg';
import goodYear from '../../../assests/images/home/clients/good-year.jpg';
import dandino from '../../../assests/images/home/clients/dandino.jpg';
import shogun from '../../../assests/images/home/clients/shogun.jpg';
import piezanos from '../../../assests/images/home/clients/piezanos.jpg';
import mirage from '../../../assests/images/home/clients/mirage.jpg';
import edenWon from '../../../assests/images/home/clients/eden-won.jpg';
import mckenzie from '../../../assests/images/home/clients/mckenzie.jpg';
import blimpie from '../../../assests/images/home/clients/blimpie.jpg';
import sunfare from '../../../assests/images/home/clients/sunfare.jpg';
import jenniw from '../../../assests/images/home/clients/jennie.jpg';
import bull from '../../../assests/images/home/clients/bull.jpg';
import showbiz from '../../../assests/images/home/clients/showbiz.jpg';
import burgerChef from '../../../assests/images/home/clients/burger-chef.jpg';
import patsys from '../../../assests/images/home/clients/patsys.jpg';
import california from '../../../assests/images/home/clients/california.jpg';
import ddDiner from '../../../assests/images/home/clients/dddine.jpg';
import vende from '../../../assests/images/home/clients/vende.jpg';
import garden from '../../../assests/images/home/clients/garden.jpg';
import heesecake from '../../../assests/images/home/clients/heesecake.jpg';
import italianPie from '../../../assests/images/home/clients/italian-pie.jpg';
import ftd from '../../../assests/images/home/clients/ftd.jpg';
import lowes from '../../../assests/images/home/clients/lowes.jpg';
import sushi from '../../../assests/images/home/clients/sushi.jpg';
import roundTable from '../../../assests/images/home/clients/roundtable.jpg';
import razorback from '../../../assests/images/home/clients/razorback.jpg';
import steakhouse from '../../../assests/images/home/clients/steakhouse.jpg';
import cvshealth from '../../../assests/images/home/clients/cvshealth.jpg';
import mario from '../../../assests/images/home/clients/mario.jpg';
import redbarn from '../../../assests/images/home/clients/red-barn.jpg';
import joesFamous from '../../../assests/images/home/clients/joes-famous.jpg';
import primo from '../../../assests/images/home/clients/primo.jpg';
import outta from '../../../assests/images/home/clients/outta.jpg';
import kiko from '../../../assests/images/home/clients/kiko.jpg';
import tokyo from '../../../assests/images/home/clients/tokyo.jpg';
import paulie from '../../../assests/images/home/clients/paulie.jpg';
import papajohns from '../../../assests/images/home/clients/papajohns.jpg';
import stillery from '../../../assests/images/home/clients/stillery.jpg';
import american from '../../../assests/images/home/clients/american.jpg';
import sbarro from '../../../assests/images/home/clients/sbarro.jpg';
import hotGrill from '../../../assests/images/home/clients/hot-grill.jpg';
import pizza from '../../../assests/images/home/clients/pizza.jpg';
import walgreens from '../../../assests/images/home/clients/walgreens.jpg';
import don from '../../../assests/images/home/clients/don.jpg';

const Customers = () => {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </button>
  );

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const clients = [
    {
      title: "Salad Works",
      image : saladWorks,
    },
    {
      title: "Pizzeria",
      image : pizzeria,
    },
    {
      title: "Fresco",
      image : fresco,
    },
    {
      title: "Cicis Pizza",
      image : cicisPizza,
    },
    {
      title: "Stamford",
      image : stamford,
    },
    {
      title: "Home Depot",
      image : homeDepot,
    },
    {
      title: "Work Menu",
      image : workMenu,
    },
    {
      title: "Good Year",
      image : goodYear,
    },
    {
      title: "Dandino",
      image : dandino,
    },
    {
      title: "Shogun",
      image : shogun,
    },
    {
      title: "Piezanos",
      image : piezanos,
    },
    {
      title: "Mirage",
      image : mirage,
    },
    {
      title: "EdenWon",
      image : edenWon,
    },
    {
      title: "Mckenzie",
      image : mckenzie,
    },
    {
      title: "Blimpie",
      image : blimpie,
    },
    {
      title: "Sunfare",
      image : sunfare,
    },
    {
      title: "jenniw",
      image : jenniw,
    },
    {
      title: "bull",
      image : bull,
    },
    {
      title: "showbiz",
      image : showbiz,
    },
    {
      title: "burgerChef",
      image : burgerChef,
    },    
    {
      title: "patsys",
      image : patsys,
    },
    {
      title: "california",
      image : california,
    },
    {
      title: "DD Diner",
      image : ddDiner,
    },
    {
      title: "vende",
      image : vende,
    },
    {
      title: "garden",
      image : garden,
    },
    {
      title: "heesecake",
      image : heesecake,
    },
    {
      title: "Italian pie",
      image : italianPie,
    },
    {
      title: "FTD",
      image : ftd,
    },
    {
      title: "Lowes",
      image : lowes,
    },
    {
      title: "Sushi",
      image : sushi,
    },
    {
      title: "Round Table",
      image : roundTable,
    },
    {
      title: "Razor Back",
      image : razorback,
    },
        {
          title: "SteakHouse",
          image : steakhouse,
        },
        {
          title: "CVS Health",
          image : cvshealth,
        },
        {
          title: "Mario",
          image : mario,
        },
        {
          title: "Red Barn",
          image : redbarn,
        },
        {
          title: "Joe's Famous",
          image : joesFamous,
        },
        {
          title: "Primo",
          image : primo,
        },
        {
          title: "Outta",
          image : outta,
        },
        {
          title: "Kiko",
          image : kiko,
        },
        {
          title: "Tokyo",
          image : tokyo,
        },
        {
          title: "Paulie",
          image : paulie,
        },
        {
          title: "Papa Johns",
          image : papajohns,
        },
        {
          title: "Stillery",
          image : stillery,
        },
        {
          title: "American",
          image : american,
        },
        {
          title: "Sbarro",
          image : sbarro,
        },
        {
          title: "Hot Grill",
          image : hotGrill,
        },
        {
          title: "Pizza",
          image : pizza,
        },
        {
          title: "Walgreens",
          image : walgreens,
        },
        {
          title: "Don",
          image : don,
        }
  ];

    return(
        <section className="home-customers-section">
            <div className="max-w-screen-1xl 4xl:max-w-screen-2xl mx-auto py-50">
                <div className='w-full flex flex-col items-center md:gap-[30px]'>
                    <h3 className='text-[46px] font-semibold text-center'>Some of our customers</h3>

                    <Slider {...settings} className="w-full customer-slider">
                        {clients.map((el, i) => (
                            <div className='clients-slider' key={i}>
                                <img src={el.image} alt={el.title} className='h-150 mx-auto object-contain' />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Customers